import React, { useEffect, useState, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { CartContext } from './Context';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

const CoachingGallery = () => {
    const { cartData, setCartData } = useContext(CartContext);
    const [coachingItems, setCoachingItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const location = useLocation();
    const baseUrl = CONSTURL;

    useEffect(() => {
        fetchCoachingItems();
    }, [currentPage]);

    const fetchCoachingItems = () => {
        const queryParams = new URLSearchParams(location.search);
        const page = queryParams.get('page') || 1;
        setCurrentPage(Number(page));

        fetch(`${baseUrl}coaching/?page=${page}`)
            .then((response) => response.json())
            .then((data) => {
                setCoachingItems(Array.isArray(data) ? data : []);
                setTotalPages(Math.ceil(data.length / 10)); // Adjust based on your API's pagination logic
            })
            .catch((error) => console.error("Fetching error:", error));
    };

    const cartAddButtonHandler = (coaching) => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const cartData = {
            product: {
                id: coaching.id,
                title: coaching.title,
                price: coaching.price,
                image: coaching.image_url,
                color: coaching.color,
                size: coaching.size,
            },
            quantity: 1,
            user: { id: 1 },
        };
        const updatedCartJson = [...cartJson, cartData];
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <section
            className="container coaching-page"
            style={{
                marginTop: '150px',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '90%',
                maxWidth: '1200px',
            }}
        >
            <h1
                className="text-center text-black mb-5 fw-bold"
                style={{
                    fontFamily: 'Bebas Neue',
                    fontSize: '48px',
                    fontWeight: 'bolder',
                    marginTop: '250px',
                }}
            >
                Nos offres de Coaching
            </h1>

            <div className="row">
                {coachingItems.map((coaching) => {
                    const originalPrice = coaching.price;
                    const discountedPrice = (
                        originalPrice *
                        (1 - coaching.discount / 100)
                    ).toFixed(2);

                    return (
                        <div
                            className="col-lg-4 col-md-6 col-sm-12 mb-4"
                            key={coaching.id}
                        >
                            <div
                                className="card shadow-sm"
                                style={{
                                    height: '100%',
                                    borderRadius: '15px',
                                    overflow: 'hidden',
                                    border: '3px solid #009F6B', // Green border with color #009F6B
                                    borderRadius: '15px', 
                                }}
                            >
                                {coaching.discount > 0 && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '10px',
                                        left: '10px',
                                        backgroundColor: '#E80000', // A softer red
                                        color: '#fff',
                                        padding: '9px 12px', // Increased padding for better spacing
                                        borderRadius: '14px', // Rounded corners for a smoother look
                                        fontSize: '18px', // Slightly larger font for readability
                                        fontWeight: '600', // A slightly lighter font weight
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
                                        display: 'flex', // Ensures the content aligns properly
                                        alignItems: 'center', // Centers content vertically
                                    }}>
                                        <i className="fa-solid fa-tag" style={{
                                            marginRight: '6px', // Adds some space between the icon and text
                                            fontSize: '12px', // Slightly larger icon
                                        }}></i>
                                        -{coaching.discount}% 
                                    </div>
                                    )}
                                <img
                                    src={coaching.image_url}
                                    alt={coaching.title}
                                    style={{ marginTop: '-5px', objectFit: 'contain' }}
                                />
                    
                                <div
                                    className="card-body d-flex flex-column justify-content-between"
                                    style={{
                                        padding: '16px',
                                        fontFamily: 'Roboto',
                                    }}
                                >
                                    <h5 className="card-title mb-3">
                                        <Link
                                            to={`/product/${coaching.slug}/${coaching.id}`}
                                            style={{
                                                color: '#000',
                                                textDecoration: 'none',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {coaching.title}
                                        </Link>
                                    </h5>
                                    <ul
                                        style={{
                                            listStyleType: 'none',
                                            padding: '0',
                                            marginBottom: '16px',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {(coaching.description || '')
                                            .split('\n')
                                            .map((line, index) => (
                                                <li key={index}>
                                                    <span style={{ fontSize: '10px' }}>
                                                        ⚫
                                                    </span>{' '}
                                                    {line}
                                                </li>
                                            ))}
                                    </ul>
                    
                                    <div className="mt-auto">
                                        <h5  style={{ color: '#26619C',  marginBottom: '16px', textalign: 'center',  }}>
                                            Prix:
                                            {coaching.discount > 0 ? (
                                                <>
                                                    <span
                                                        style={{
                                                            textDecoration: 'line-through',
                                                            marginRight: '10px',
                                                            color: '#999',
                                                            textalign: 'center',
                                                        }}
                                                    >
                                                        {Number(originalPrice).toFixed(3)  } DT
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#E80000',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {discountedPrice.toFixed(3) } DT
                                                    </span>
                                                </>
                                            ) : (
                                                <span>{Number(originalPrice).toFixed(3)  } DT</span>
                                            )}
                                        </h5>
                                        <button
                                            onClick={() => cartAddButtonHandler(coaching)}
                                            className="btn btn-success w-100"
                                            style={{
                                                borderRadius: '28px',
                                                fontSize: '16px',
                                            }}
                                        >
                                            <i
                                                className="fa-solid fa-cart-plus"
                                                style={{ marginRight: '8px' }}
                                            ></i>
                                            Ajouter au panier
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

          {/*  <nav aria-label="Page navigation">
                <ul
                    className="pagination justify-content-center mt-4"
                    style={{ gap: '10px' }}
                >
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li
                            key={index}
                            className={`page-item ${
                                currentPage === index + 1 ? 'active' : ''
                            }`}
                        >
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(index + 1)}
                                style={{
                                    borderRadius: '50%',
                                    fontSize: '1rem',
                                    cursor: 'pointer',
                                    backgroundColor: currentPage === index + 1 ? '#000' : '#fff',
                                    color: currentPage === index + 1 ? '#fff' : '#000',
                                    border: '1px solid #009F6B', // Green border with color #009F6B
                                    borderRadius: '15px', 
                                }}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>*/}
         {/* Paragraph Section */}
         <div className="coaching-item">
            
                
               
                <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>1. PROGRAMME D'ENTRAÎNEMENT PERSONNALISÉ</span>
              
                <p className="description"style={{ fontSize: '18px', fontFamily: 'helvetica-w01-light, helvetica-w02-light, sans-serif'}}>
                    Nous ne sommes pas tous égaux face à la nature. C'est le fait de la génétique. La 1ère différence se situe déjà au niveau du genre.
                    Il serait donc incohérent qu'un même programme soit effectué par tous, ne serait-ce que du point de vue anatomique.
                </p>
                <p className="description"style={{ fontSize: '18px'}}>
                    Partant de ce postulat, il devient évident que chaque profil doit bénéficier d'un encadrement afin de mettre l'accent sur sa singularité physique
                    (taille, poids, sexe, longueur des membres, insertions musculaires, emploi du temps, etc...).
                </p>
                <p className="description"style={{ fontSize: '18px'}}>
                    Le but d'un plan d'entraînement personnalisé est de prendre en compte toutes ces spécificités pour magnifier vos résultats physiques.
                </p>
            </div>
            
            <div className="coaching-item">
            <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>2. PLAN DIÈTE PERSONNALISÉE</span>
              
                <p className="description"style={{ fontSize: '18px'}}>
                    La diète, dans toute activité sportive, se doit d'être en absolue cohérence avec la discipline pratiquée.
                </p>
                <p className="description"style={{ fontSize: '18px'}}>
                    Qu'est-ce qu'une diète adaptée ?
                </p>
                <p className="description"style={{ fontSize: '18px'}}>
                    C'est le fait de privilégier certains aliments par rapport à d'autres. L'intérêt est d'apporter à votre corps l'ensemble des nutriments nécessaires
                    à sa construction et dans les quantités exactes. Cela revient donc à fournir les macros les mieux adaptés au volume d'entraînement fourni et à l'objectif souhaité.
                </p>
            </div>

            <div className="coaching-item">
            <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>3. COMPLÉMENTS ALIMENTAIRES ADAPTÉS</span>
             
                <p className="description"style={{ fontSize: '18px'}}>
                    Avant tout, il est essentiel de préciser que la consommation de compléments alimentaires ne doit en aucun cas remplacer une bonne alimentation.
                    Le corps humain produit un certain nombre de minéraux, vitamines et nutriments nécessaires à son fonctionnement. La plupart de ces nutriments
                    sont également présents dans notre alimentation. Cependant, ils peuvent être produits ou disponibles dans des proportions insuffisantes en fonction du mode de vie que l'on adopte.
                </p>
                <p className="description"style={{ fontSize: '18px'}}>
                    En effet, un individu avec une forte ou intense activité physique va nécessiter un besoin en nutriments bien plus conséquent qu'une personne dont
                    l'activité physique se résume à 1 voire 2 séances par semaine. Il va donc devenir intéressant pour toute personne, à volume d'entraînement élevé, d'apporter une complémentarité à son alimentation.
                </p>
                <p className="description"style={{ fontSize: '18px'}}>
                    L'un des plus connus de ces compléments alimentaires est la protéine de lait mais il en existe bien d'autres. Ils viendront équilibrer la balance
                    de nos besoins qui peut être perturbée par nos différents rythmes de vie. Sur mon site, dans l'onglet F.A.Q, vous trouverez une collection de vidéos explicatives qui détaillent chaque complément alimentaire et son utilisation.
                </p>
            </div>

            <div className="coaching-item">

            <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>4. CHEATMEAL COMPRIS DANS LA DIÈTE</span>
                <p className="description"style={{ fontSize: '18px'}}>
                    La diète que vous suivez durant votre coaching est calculée et est en lien avec votre entraînement. Ce qui signifie que certains aliments en sont exclus.
                    Cependant, et afin de conserver votre motivation intacte, relancer le metabolisme pour certains cas, un "repas plaisir" vous est permis, en concertation avec moi afin d'en déterminer la qualité mais également le moment.
                </p>
            </div>

            <div className="coaching-item">
            <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>5. BILAN TOUTES LES 2 SEMAINES</span>
               
                <p className="description"style={{ fontSize: '18px'}}>
                    Chaque personne est différente. Que ce soit au niveau physique ou psychologique. En choisissant un pack avec suivi, vous vous assurez ma disponibilité,
                    à la fois pour répondre à vos questions mais aussi sur le plan du mental et de la motivation. N'importe quelle bonne résolution peut se trouver perturbée par un changement de situation personnelle.
                </p>
                <p className="description"style={{ fontSize: '18px'}}>
                    Nos échanges réguliers me permettront de mieux vous connaître, et de répondre de manière efficace à vos besoins ou attentes. Cela pourra concerner le choix
                    d'un exercice plutôt qu'un autre parce que le 1er n'est pas ou peu adapté à votre morphologie ou encore, apporter des modifications rapides à votre diète, en fonction de son impact sur vous.
                    J'accorde une grande importance à l'accompagnement car c'est la clé de votre accomplissement. Nous allons travailler de façon concrète, mettons toutes les chances de notre côté.
                </p>
            </div>

            <div className="coaching-item">
            <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>6. AJUSTEMENT SELON BILAN</span>
                <p className="description"style={{ fontSize: '18px'}}>
                    La diète et l'entraînement personnalisés que vous suivrez auront un impact sur votre corps. Les ajustements que j'apporterai ont pour but que cet impact évolue dans le sens souhaité.
                    Cela pourra concerner des méthodes de travail ou alors le fait de revoir à la hausse ou à la baisse les quantités de macros nutriments consommés.
                </p>
            </div>

            <div className="coaching-item">
            <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>7. RÉPONSE PAR E-MAIL SOUS 24H</span>
                <p className="description"style={{ fontSize: '18px'}}>
                    Je m'engage à répondre sous 24h à vos mails afin que vous puissiez poursuivre rapidement votre entraînement (hors weekends et jours fériés.)
                </p>
            </div>

            <div className="coaching-item">
            <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>8. CAS DE MALADIE-OPÉRATION MÉDICALE</span>
            
                <p className="description"style={{ fontSize: '18px'}}>
                   Pause en cas de maladie-opération médicale ✅
                ‎⛔️لا يمكن إيقاف البرنامج والرجوع لأي سبب كان ما عدا المرض
                </p>
            </div>
            <div className="coaching-item">
            <span className="title"style={{ fontSize: '30px' , color: '#009F6B'}}>9. PED</span>
                <p className="description"style={{ fontSize: '18px'}}>
                     PED pour les athletes ( +100dt )
                </p>
            </div>
          
        </section>
    );
};

export default CoachingGallery;

