import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { UserContext, CartContext } from './Context';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant
const baseUrl = CONSTURL; // Use the imported CONSTURL

function Navbar() {
    const userContext = useContext(UserContext);
    const { cartData } = useContext(CartContext);
    const [isScrolled, setIsScrolled] = useState(false);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [productWear, setProductWear] = useState([]); // Retain productWear state
    const [coachingProducts, setCoachingProducts] = useState([]); // State for coaching products
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [open, setOpen] = useState(false);
    const [openCategories, setOpenCategories] = useState(false); // Added state for categories dropdown
    const [openStores, setOpenStores] = useState(false);
    const [openMore, setOpenMore] = useState(false); // Added state for more dropdown
    const [isChatOpen, setIsChatOpen] = useState(false); // State for chat visibility
    const location = useLocation();
    const searchRef = useRef(null); // Create a ref for the search area

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 50);
        };

        const fetchCategories = async () => {
            try {
                const response = await fetch(`${baseUrl}Categories/`);
                const data = await response.json();
                setCategories(data.results);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const fetchProducts = async () => {
            try {
                const response = await fetch(`${baseUrl}Products/`);
                const data = await response.json();
                setProducts(data.results);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const fetchProductWear = async () => {
            try {
                const response = await fetch(`${baseUrl}product_wear/`);
                const data = await response.json();
                setProductWear(data); // API already returns an array
            } catch (error) {
                console.error('Error fetching product wear:', error);
            }
        };

        const fetchCoachingProducts = async () => {
            try {
                const response = await fetch(`${baseUrl}coaching/`);
                const data = await response.json();
                setCoachingProducts(data); // API already returns an array
            } catch (error) {
                console.error('Error fetching coaching products:', error);
            }
        };

        window.addEventListener('scroll', handleScroll);
        fetchCategories();
        fetchProducts();
        fetchProductWear();
        fetchCoachingProducts();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Combine products, productWear, and coachingProducts for search
    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
        } else {
            const allProducts = [...products, ...productWear, ...coachingProducts]; // Merge all product lists
            const filteredResults = allProducts.filter(product => 
                product.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(filteredResults);
        }
    }, [searchTerm, products, productWear, coachingProducts]);

    const cartItems = cartData ? cartData.length : 0;

    const categoryTimeoutRef = useRef(null);
    const storeTimeoutRef = useRef(null);
    const moreTimeoutRef = useRef(null);

    const handleMouseEnterCategories = () => {
        clearTimeout(categoryTimeoutRef.current); // Clear any pending close actions
        setOpenCategories(true);
    };

    const handleMouseLeaveCategories = () => {
        categoryTimeoutRef.current = setTimeout(() => {
            setOpenCategories(false);
        }, 1000); // 5 seconds delay
    };

    const handleMouseEnterStores = () => {
        clearTimeout(storeTimeoutRef.current);
        setOpenStores(true);
    };

    const handleMouseLeaveStores = () => {
        storeTimeoutRef.current = setTimeout(() => {
            setOpenStores(false);
        }, 1000);
    };

    const handleMouseEnterMore = () => {
        clearTimeout(moreTimeoutRef.current);
        setOpenMore(true);
    };

    const handleMouseLeaveMore = () => {
        moreTimeoutRef.current = setTimeout(() => {
            setOpenMore(false);
        }, 1000);
    };

    // Handle the link click to close search results
    const handleLinkClick = () => {
        setSearchResults([]); // Close search results when a link is clicked
    };

    // Chat toggle function
    const toggleChat = () => {
        setIsChatOpen(prevState => !prevState);
    };

    return (
        <nav className={`navbar navbar-expand-lg ${location.pathname === '/' ? 'navbar-home' : 'navbar-other'} ${isScrolled ? 'scrolled' : ''}`}>
            <div className="container" style={{ width: '180%', maxWidth: '1500px'}}>
                <div className='new'>
                    <Link className="navbar-brand" to="/">
                        <div className="logo-wrapper5"  > 
                            <img src="/CROCO-2.png" alt="Logo" className="navbar-logo" />
                        </div>
                    </Link>
                    <div ref={searchRef} className={`search-bar ${open ? 'show-search' : ''}`} style={{ position: 'relative', width: '240%' }}>
                    <form
                    onSubmit={(e) => {
                        e.preventDefault(); /* You may want to define handleSearch function */
                    }}
                    className="search-form"
                    style={{ display: 'flex', width: '180%' }}
                    >
                    <input
                        type="text"
                        placeholder="Recherche"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                        flex: 1,
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        fontFamily: 'Oswald',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        }}
                    />
                    </form>


                        {searchResults.length > 0 && (
                            <div className="search-results" style={{
                                position: 'absolute',
                                top: '100%',
                                left: '0',
                                fontFamily: 'Roboto',
                                width: '155%', // Adjusted width
                                backgroundColor: 'black',
                                fontSize: '17px',
                                borderRadius: '5px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                zIndex: '50',
                                overflowY: 'auto',
                                marginTop: '5px',
                                textTransform: 'uppercase'
                            }}>
                  <ul style={{ listStyle: 'none', padding: '20px', margin: '0' }}>
                  {searchResults.map(product => (
                    <li 
    key={product.id} 
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '10px 0', transition: 'background-color 0.3s' }}
    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#009F6B'}
    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
>   

    {/* Main Product Page */}
    {product.image && product.image.trim() !== '' && (
        <Link to={`/Product/undefined/${product.id}`} style={{ textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center', marginBottom: '10px' }}
          onClick={() => {
            handleLinkClick();
            setSearchTerm(''); // Clear the search input field
        }} >
            <img 
                src={product.image} 
                alt={product.title}
                style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '5px' }}
            />
            <span>{product.title}</span>
        </Link>
    )}

    {/* Product Wear */}
    {product.imageprod && product.imageprod.trim() !== '' && (
        <Link to={`/Stores/2`} style={{ textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center', marginBottom: '10px' }}onClick={() => {
            handleLinkClick();
            setSearchTerm(''); // Clear the search input field
        }} >
            <img 
                src={`${baseUrl}${product.imageprod}`} 
                alt={`${product.title} - product wear`}
                style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '5px' }}
            />
            <span>{`${product.title} product wear`}</span>
        </Link>
    )}

    {/* Coaching Page */}
    {product.image_url && product.image_url.trim() !== '' && (
        <Link to={`/Coaching`} style={{ textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center' }} onClick={() => {
            handleLinkClick();
            setSearchTerm(''); // Clear the search input field
        }} >
            <img 
                src={product.image_url} 
                alt={`${product.title} coaching`}
                style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '5px' }}
            />
            <span>{`${product.title} coaching`}</span>
        </Link>
    )}
</li>

))}


</ul>

                            </div>
                        )}
                    </div>

              {/* Navbar Toggler */}
                           <button 
    className="navbar-toggler"
    type="button"
    onClick={() => {
        setOpen(!open); // Toggle the menu open/close
        setTimeout(() => setOpen(false), 4000); // Auto-close after 5 seconds
    }}
    aria-expanded={open}
>
    <span className="fa fa-bars" style={{ color: 'white' }}></span>
</button>
              </div>

                <div className={`collapse navbar-collapse ${open ? 'show' : ''}`}>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '16px' }} onClick={() => setOpen(false)}>Accueil</Link>
                        </li>


                        <li className="nav-item dropdown"
                                onMouseEnter={handleMouseEnterCategories}
                                onMouseLeave={handleMouseLeaveCategories}
                            >
                            <li className="nav-item">
                            <Link className="nav-link" to="/Stores/1" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '16px' }} >Croco Nutrition</Link>
                            </li>
                            {openCategories && (
                        <ul
                        className="dropdown-menu"
                        style={{
                            display: 'flex',
                            width: window.innerWidth > 768 ? '1950px' : 'auto', // Full width for desktop, auto for mobile
                            height: window.innerWidth > 768 ? '240px' : 'auto', // Fixed height for desktop, auto for mobile
                            position: 'absolute',
                            top: '110%', // Aligns below the navbar
                            left: window.innerWidth > 768 ? '-1100px' : 'auto', // Centered for desktop, aligned for mobile
                            whiteSpace: window.innerWidth > 768 ? 'nowrap' : 'normal', // Prevent wrapping for desktop
                            flexDirection: window.innerWidth > 768 ? 'row' : 'column', // Horizontal for desktop, vertical for mobile
                            justifyContent: window.innerWidth > 768 ? 'center' : 'flex-start', // Centered for desktop
                            padding: '10px', // Adds spacing for touch devices
                            maxHeight: window.innerWidth <= 768 ? '300px' : 'none', // Adds max height for mobile
                            overflowY: window.innerWidth <= 768 ? 'auto' : 'none', // Enables scrolling on mobile if the dropdown exceeds maxHeight
                        
                        }}
                        onClick={() => setOpen(false)}
                        >
                        {categories.map((category) => (
                            <li key={category.id}>
                            <Link
                                className="dropdown-item"
                                
                               // to={`/Category/${category.title}/${category.id}`}
                                style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bolder', textTransform: 'uppercase', fontSize: '16px', marginLeft: '-25px',width:'110%',height:'35%',padding:'27px',borderRadius:'70px' }}
                            >
                                {category.title}
                            </Link>
                            <ul className="dropdown-submenu">
                                {category.subcategories.map((subcategory) => (
                                    <Link
                                    key={subcategory.id}
                                    className="dropdown-item"
                                    to={`/Subcategory/${subcategory.id}/products`}
                                    style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '13px', marginLeft: '-30px',width:'110%',borderRadius:'70px' }}
                                    >
                                    {subcategory.title}
                                    </Link>
                                ))}
                                </ul>
                            </li>
                        ))}
                        </ul>
  )}


                                    
                                    </li>



                     

                        <li className="nav-item dropdown" onMouseEnter={handleMouseEnterStores} onMouseLeave={handleMouseLeaveStores}>
                            
                        <ul className="navbar-nav" style={{ display: 'flex', gap: '22px' }}>
                       
                        <li className="nav-item">
                            <Link className="nav-link" to="/Stores/3" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '16px' }}>Croco Team</Link>
                        </li>

                        </ul>

                            {openStores && (
                            <ul className="dropdown-menu" style={{ display: 'block',width:'160%' ,marginTop: '33px'}} onClick={() => setOpen(false)}>
                                
                                {/* <Link className="dropdown-item" to="/Stores/3" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '17px' }}>Croco Wear</Link>
                                <Link 
                                    className="dropdown-item" to="/Stores/2"  style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '17px' }}>  </Link>*/}
                                 <Link 
                                    className="dropdown-item"  to="/Coaching"  style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '15px', marginLeft: '13px',width:'90%',borderRadius:'70px' }}>
                                  Coaching
                                    </Link> 

                                <Link className="dropdown-item" to="/Transformation" style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '15px', marginLeft: '13px',width:'90%' ,borderRadius:'70px' }}>
                                Transformation
                                </Link>
                                
                               {/*  <Link className="dropdown-item" to="/CrocoEducation" style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '15px', marginLeft: '13px',width:'90%' }}>
                                croco education</Link>*/}
                            </ul>
                            )}
                        </li>
                        <ul className="navbar-nav" style={{ display: 'flex'}}>
                            <li className="nav-item">
                            <Link className="nav-link" to="/Transformation" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '16px' }}onClick={() => setOpen(false)}>Transformation</Link>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/Stores/2" style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '16px' }}onClick={() => setOpen(false)}>Croco Wear</Link>
                            </li>
                        </ul>

                        <li className="nav-item dropdown" onMouseEnter={handleMouseEnterMore} onMouseLeave={handleMouseLeaveMore}>
                            
                            <ul className="navbar-nav" style={{ display: 'flex', gap: '20px' }}>
                            <li className="nav-item">
                                <Link className="nav-link"  style={{ fontWeight: 'bold', fontFamily: 'Oswald', textTransform: 'uppercase', fontSize: '16px' }}>Plus</Link>
                            </li>
                            </ul>
                                {openMore && (
                               <ul className="dropdown-menu" style={{ display: 'block',width:'120%',marginTop: '33px' }}onClick={() => setOpen(false)}>
                                  <Link 
                                        className="dropdown-item" 
                                        to="/video" 
                                        style={{ fontWeight: 'normal', fontFamily: 'Roboto',fontWeight:'bold', textTransform: 'uppercase', fontSize: '15px', marginLeft: '13px',width:'90%', borderRadius:'70px' }}>
                                           Video
                                        </Link>
                                </ul>
                                )}
                       </li>
            


                        <li className="nav-item">
                            <Link className="nav-link" to="/Cart"onClick={() => setOpen(false)}>
                                <span className="fa-stack has-badge" style={{ fontSize: '30px',fontFamily:'Oswald' }} data-count={cartItems}>
                                    <i className="fa fa-shopping-cart fa-stack-1x fa-inverse" style={{ fontSize: '25px' }}></i> 
                                    {/* Adjust the '30px' to the size you want */}
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;












