import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from './Context';
import Moving from './MovingBanner';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant


const baseUrl = CONSTURL; // Use the imported CONSTURL
function Cart() {
    const { cartData, setCartData } = useContext(CartContext);
    const [flavourData, setFlavourData] = useState({});
    const [sizeData, setSizeData] = useState({});
    const [colorData, setColorData] = useState({});
    const [imageData, setImageData] = useState({}); // Added this here


    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const productResponse = await fetch(baseUrl+'Products/');
                const productData = await productResponse.json();
                const products = productData.results;

                // Create a mapping of product id to flavour
                const flavourMap = {};
                products.forEach(product => {
                    flavourMap[product.id] = product.flavours; // Map product id to flavours
                });;

                setFlavourData(flavourMap);

            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };
        fetchProductDetails();
    }, []);
     // Fetch product wear details
     useEffect(() => {
        const fetchProductWearDetails = async () => {
            try {
                const response = await fetch(`${baseUrl}/product_wear/`);
                const data = await response.json();
                
                // Create a mapping of product id to size and color
               
                const sizeMap = {};
                const colorMap = {};
                const imageMap = {}; // Mapping des images

                data.forEach(item => {
                    sizeMap[item.id] = item.size; // Map product id to size
                    colorMap[item.id] = item.color; // Map product id to color
                    imageMap[item.id] = item.imageprod; // Ajout de l'image
                });

                setSizeData(sizeMap);
                setColorData(colorMap);
                setImageData(imageMap);

            } catch (error) {
                console.error('Error fetching product wear details:', error);
            }
        };

        
        fetchProductWearDetails();
    }, []);

    useEffect(() => {
        if (cartData) {
            const updatedCartData = cartData.map(item => ({
                ...item,
                qty: item.qty || 1 // Default quantity to 1 if not provided
            }));
            setCartData(updatedCartData);
        }
    }, [cartData, setCartData]);

    const cartRemoveButtonHandler = (product_id) => {
        const updatedCartData = cartData.filter(cart => cart.product.id !== product_id);
        localStorage.setItem('cartData', JSON.stringify(updatedCartData));
        setCartData(updatedCartData);
    };

    const incrementQuantity = (index) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index) {
                return { ...item, qty: item.qty + 1 };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    const decrementQuantity = (index) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index && item.qty > 1) {
                return { ...item, qty: item.qty - 1 };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    const handleFlavourChange = (index, selectedFlavour) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index) {
                return { ...item, selectedFlavour };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    const handleSizeChange = (index, selectedSize) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    selectedSize
                };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    const handleColorChange = (index, selectedColor) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    selectedColor
                };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

   return (
    <div style={{ fontFamily: 'Roboto', marginTop: '220px', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
        <section style={{ maxWidth: '1000px', margin: 'auto' }}>
            <Moving />
            <h3 style={{
                textAlign: 'center', color: '#333', fontSize: '43px', fontFamily: 'Oswald', color: 'black',
                marginBottom: '10px'
            }}>
                Panier 
                <span
                    style={{
                        backgroundColor: '#009F6B',
                        color: 'white',
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        fontFamily: 'Oswald',
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginLeft: '10px',
                    }}
                >
                    {cartData ? cartData.length : 0}
                </span>
            </h3>

            <div style={{ marginBottom: '50px' }}></div>
            {cartData && cartData.length !== 0 ? (
                <>
                    <div style={{
                        marginTop: '20px',
                        background: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 1px 8px rgba(0,0,0,0.1)',
                        padding: '20px',
                    }}>
                        {cartData.map((item, index) => {
                            const individualPrice = (parseFloat(item.product.price) * item.qty).toFixed(3);
                            return (
                                <div key={index} style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px',
                                    borderBottom: '1px solid #eee',
                                    flexWrap: 'wrap'
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                        {/* Handle the main product image */}
                                        {item.product.image && item.product.image.trim() !== '' && (
                                            <img
                                                src={item.product.image} // Main image URL
                                                alt={item.product.title}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    marginRight: '10px',
                                                    borderRadius: '5px',
                                                }}
                                            />
                                        )}
                                        {/* Handle the product image based on imageprod */}
                                        {item.product.imageprod && item.product.imageprod.trim() !== '' ? (
                                            <img
                                                src={`${baseUrl}${item.product.imageprod}`} // Image URL
                                                alt={item.product.title}
                                                style={{
                                                    width: '50px',
                                                    height: '50px',
                                                    marginRight: '10px',
                                                    borderRadius: '5px',
                                                }}
                                                onError={(e) => (e.target.style.display = 'none')} // Hide image if it fails to load
                                            />
                                        ) : (
                                            <span></span> // Fallback message if no image is available
                                        )}

                                        <div style={{ marginTop: '10px' }}>
                                            <h4 style={{ margin: '0', color: 'black', fontSize: '19px' }}>{item.product.title}</h4>
                                            <p style={{ margin: '0', color: 'red', fontSize: '19px' }}>{individualPrice} DT</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* Conditional Dropdowns */}
                                        {item.product.title.toLowerCase().startsWith('offre') ? null : (
                                            sizeData[item.product.id] && colorData[item.product.id] ? (
                                                <>
                                                    {/* Show size dropdown */}
                                                    <select
    value={item.selectedSize || ''}
    onChange={(e) => handleSizeChange(index, e.target.value)}
    style={{
        marginRight: '10px',
        padding: '5px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        border: '2px solid black',
        fontSize: '14px',
        width: '100%',        // Full width on all screen sizes
        maxWidth: '200px',    // Limit the width for larger screens
        boxSizing: 'border-box', // To include padding and border in the element's total width
    }}
>
    <option value="" disabled>Sélectionnez la taille</option>
    {Array.isArray(sizeData[item.product.id]) && sizeData[item.product.id].map((size, sizeIndex) => (
        <option key={sizeIndex} value={size}>{size}</option>
    ))}
</select>

<style jsx>{`
    @media (max-width: 768px) {
        select {
            font-size: 12px;  // Smaller font size on mobile devices
            padding: 4px;     // Adjust padding for mobile
        }
    }
`}</style>

{/* Show color dropdown */}
<select
    value={item.selectedColor || ''}
    onChange={(e) => handleColorChange(index, e.target.value)}
    style={{
        marginRight: '10px',
        padding: '5px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        border: '2px solid black',
        fontSize: '14px',
        maxWidth: '200px',  // Limit the width for mobile
        width: '100%',      // Full width on small screens
    }}
>
    <option value="" disabled>Sélectionnez la couleur</option>
    {Array.isArray(colorData[item.product.id]) && colorData[item.product.id].map((color, colorIndex) => (
        <option key={colorIndex} value={color}>{color}</option>
    ))}
</select>
                                                </>
                                            ) : (
                                                // Show flavour dropdown if size or color is not available
<select
    value={item.selectedFlavour || ''}
    onChange={(e) => handleFlavourChange(index, e.target.value)}
    style={{
        marginRight: '10px',
        padding: '5px',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        border: '2px solid black',
        width: '100%',  
    }}
>
                      <option value="" disabled>Sélectionnez la saveur</option>
                       {flavourData[item.product.id] && flavourData[item.product.id].map((flavour, flavourIndex) => (
                   <option key={flavourIndex} value={flavour.name}>
                          {flavour.name}
                        </option>
    ))}
</select>


                                            )
                                        )}

<div style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
    <button onClick={() => decrementQuantity(index)} style={{
        border: '2px solid black',
        backgroundColor: 'white',
        color: '#009F6B',
        padding: '5px 10px',
        borderRadius: '4px',
        margin: '0 5px',
        fontSize: '16px',  // Added font size for better mobile view
    }}>-</button>
    
    <span style={{
        fontWeight: 'bold',
        margin: '0 10px',
        fontSize: '18px',
        whiteSpace: 'nowrap',  // Prevents text wrapping for quantity
    }}>{item.qty}</span>
    
    <button onClick={() => incrementQuantity(index)} style={{
        border: '2px solid black',
        backgroundColor: 'white',
        color: '#009F6B',
        padding: '5px 10px',
        borderRadius: '4px',
        margin: '0 5px',
        fontSize: '16px',  // Added font size for better mobile view
    }}>+</button>
</div>

<div>
    <button onClick={() => cartRemoveButtonHandler(item.product.id)} style={{
        background: 'none',
        border: 'none',
        color: 'red',
        cursor: 'pointer',
        fontSize: '18px',  // Slightly larger icon for mobile view
    }}>
        <i className="fa fa-trash" style={{ fontSize: '22px' }}></i>
    </button>
</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px',
                        background: '#e9ecef',
                        borderRadius: '8px',
                        flexWrap: 'wrap'
                    }}>
                        <div style={{ fontWeight: 'bold', margin: '0', fontSize: '20px' }}>
                            <p style={{ fontWeight: 'bold', margin: '0', fontSize: '20px' }}>Article :</p>
                            <p style={{ margin: '0', fontSize: '20px', color: 'black' }}>{cartData.length}</p>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <p style={{ fontWeight: 'bold', margin: '0', fontSize: '20px' }}>Total TTC :</p>
                            <p style={{ margin: '0', fontSize: '20px', color: 'black' }}>{cartData.reduce((total, item) => total + parseFloat(item.product.price) * item.qty, 0).toFixed(3)} DT</p>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '15px',
                            marginTop: '10px'
                        }}>
                            <Link to="/Stores/1" style={{
                                fontFamily: 'Roboto',
                                fontWeight: 'bolder',
                                fontSize: '20px',
                                padding: '15px 15px',
                                background: '#000080',
                                color: '#fff',
                                textDecoration: 'none',
                                borderRadius: '5px',
                                textAlign: 'center'
                            }}>
                                Acheter plus
                            </Link>
                            <Link to="/OrderValidation" style={{
                                fontFamily: 'Roboto',
                                fontWeight: 'bolder',
                                fontSize: '20px',
                                padding: '15px 20px',
                                background: '#009F6B',
                                color: '#fff',
                                textDecoration: 'none',
                                borderRadius: '5px',
                                textAlign: 'center'
                            }}>
                                Commander
                            </Link>
                        </div>
                    </div>

                </>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Link to="/Stores/1" style={{
                        padding: '15px 25px',
                        background: '#000080',
                        color: '#fff',
                        textDecoration: 'none',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        display: 'inline-block',
                        textAlign: 'center'
                    }}>Continuer vos achats</Link>
                </div>
            )}
        </section>
        <div style={{ marginBottom: '200px' }}></div>
    </div>
);

    
}

export default Cart;