import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import OneProduct from './OneProduct';
import { FaFilter } from 'react-icons/fa';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

function Products(props) {
    const baseUrl = CONSTURL; // Use the imported CONSTURL
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [priceRange, setPriceRange] = useState({ min: '', max: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [showFilters, setShowFilters] = useState(false); // Toggle for mobile filters
    const location = useLocation();

    useEffect(() => {
        fetchCategories();
        const queryParams = new URLSearchParams(location.search);
        const page = parseInt(queryParams.get('page')) || 1;
        setCurrentPage(page);
        fetchData(page);
    }, [location.search, selectedCategory, priceRange]);

    // Fetch categories for filter
    function fetchCategories() {
        fetch(`${baseUrl}categories/`)
            .then((response) => response.json())
            .then((data) => setCategories(data));
    }

    // Fetch product data with category and price filters
    function fetchData(page) {
        const pageSize = 9; // Show 9 products per page

        let url = `${baseUrl}Products/?page=${page}&page_size=${pageSize}`;
        if (selectedCategory) url += `&category=${selectedCategory}`;
        if (priceRange.min) url += `&price_min=${priceRange.min}`;
        if (priceRange.max) url += `&price_max=${priceRange.max}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setProducts(data.results);
                setTotalPages(Math.ceil(data.count / pageSize)); // Calculate total pages
            })
            .catch((error) => console.error('Error fetching data:', error)); // Handle errors
    }

    // Handle price filter changes
    function handlePriceChange(event) {
        const { name, value } = event.target;
        setPriceRange((prevRange) => ({ ...prevRange, [name]: value }));
    }

    // Change the page and fetch data for that page
    function changePage(pageNumber) {
        setCurrentPage(pageNumber);
        const params = new URLSearchParams(location.search);
        params.set('page', pageNumber);
        window.history.pushState(null, '', `?${params.toString()}`);
        fetchData(pageNumber); // Fetch data for the new page
    }

    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
        <>
            <div style={{ marginTop: '130px', display: 'flex', justifyContent: 'center' }}>
                <section className="container category-product-container products-page" style={{ marginTop: '100px' }}>
                    <h3 className="mb-4" style={{ fontSize: '35px', color: 'black', textAlign: 'center' }}> Nos Produits </h3>

                    {/* Filter Icon for Mobile */}
                    <div className="text-center mb-3 hide-on-desktop">
                        <FaFilter
                            size={24}
                            onClick={() => setShowFilters(!showFilters)}
                            style={{ cursor: 'pointer', color: 'black' }}
                        />
                    </div>
                    {showFilters && (
                        <div className="mb-4 p-3 shadow-sm card">
                            <h5 style={{ fontFamily: 'Impact' }}>Filter Products</h5>

                            {/* Category Filter */}
                            <div className="mb-4">
                                <label htmlFor="category" className="form-label" style={{ fontFamily: 'Impact' }}>Category</label>
                                <select
                                    id="category"
                                    className="form-select"
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                >
                                    <option value="">All Categories</option>
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            

                            {/* Price Range Filter */}
                            <div className="mb-4">
                                <label htmlFor="price_min" className="form-label" style={{ fontFamily: 'Impact' }}>Price Range</label>
                                <div className="d-flex gap-2">
                                    <input
                                        type="number"
                                        id="price_min"
                                        name="min"
                                        className="form-control"
                                        placeholder="Min"
                                        value={priceRange.min}
                                        onChange={handlePriceChange}
                                    />
                                    <input
                                        type="number"
                                        id="price_max"
                                        name="max"
                                        className="form-control"
                                        placeholder="Max"
                                        value={priceRange.max}
                                        onChange={handlePriceChange}
                                    />
                                </div>
                            </div>

                            <button className="button-30" style={{ fontFamily: 'Impact', borderRadius: '0' }}>
                                Apply Filters
                            </button>
                        </div>
                    )}

                    {/* Filters Sidebar for Desktop */}
                    <div className="sideandpro"
                         style={{
                             width: '80%',
                             margin: '0 auto',
                             display: 'flex',
                             justifyContent: 'space-between',
                             alignItems: 'flex-start',
                             gap: '20px',
                         }}>

                        <aside className="col-md-3 d-none d-md-block">
                            <div className="card shadow-sm p-4 mb-5">
                                <h5 className="mb-4" style={{ fontFamily: 'Impact' }}>Filter Products</h5>

                                {/* Single Filter: Category Filter */}
                                <div className="mb-5">
                                    <label htmlFor="category" className="form-label" style={{ fontFamily: 'Impact',padding:'-27px' }}>Category</label>
                                    <select id="category" className="form-select" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                                        <option value="">All Categories</option>
                                        {categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Price Range Filter */}
                                <div className="mb-4">
                                    <label htmlFor="price_min" className="form-label" style={{ fontFamily: 'Impact' }}>Price Range</label>
                                    <div className="d-flex gap-2">
                                        <input
                                            type="number"
                                            id="price_min"
                                            name="min"
                                            className="form-control"
                                            placeholder="Min"
                                            value={priceRange.min}
                                            onChange={handlePriceChange}
                                        />
                                        <input
                                            type="number"
                                            id="price_max"
                                            name="max"
                                            className="form-control"
                                            placeholder="Max"
                                            value={priceRange.max}
                                            onChange={handlePriceChange}
                                        />
                                    </div>
                                </div>

                                <button className="button-30" style={{ fontFamily: 'Impact', borderRadius: '0' }}>
                                    Apply Filters
                                </button>
                            </div>
                        </aside>

                        {/* Product Listings (Show only products for the current page) */}
                        <div className="col-md-9">
                            <div className="row mb-4" style={{ fontFamily: 'Trebuchet MS', fontSize: '18px', color: 'black' }}>
                                {products.map((product) => (
                                    <OneProduct key={product.id} product={product} />
                                ))}
                            </div>

                            {/* Pagination */}
                            <div className="pagination" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="pagination__container">
                                    {pageNumbers.map((number) => (
                                        <button
                                            key={number}
                                            onClick={() => changePage(number)}
                                            className={`pagination__button ${currentPage === number ? 'active' : ''} button-30 me-2`}
                                        >
                                            {number}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Products;
