import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

export default function App() {
  return (
    <MDBFooter bgColor='black' className='text-center text-lg-start text-muted'>
      <section className='p-4' style={{ marginTop: '35px', marginLeft: '0', marginRight: '0', width: '100%' }}>
        <MDBContainer className='text-center text-md-start' style={{ maxWidth: '100%', color: 'white', padding: '0' }}>
          <MDBRow className='row' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginLeft: '0', marginRight: '0' }}>
            {/* Updated CROCO LAND Column */}
            <MDBCol md='2' lg='2' xl='2' className='mx-auto d-flex flex-column' style={{ marginTop: '-70px', flex: 1 }}>
              <h4 className='text-uppercase fw-bold mb-3' style={{ fontWeight: 'bolder', fontFamily: 'Oswald', color: '#009F6B', fontSize: '1.8rem' }}>
                <Link className="navbar-brandd" to="/">
                  <img src="/CROCO-2.png" alt="Logo" className="navbar-logo" style={{ width: "210px", height: "auto", marginTop: '10px' }} />
                </Link>
              </h4>
              <p style={{ marginTop: '-50px',  }} >
                Bienvenue à Croco Land ! Ici,<br />
                vous pouvez explorer les meilleurs <br />
                produits de nutrition,<br />
                vêtements et bien plus encore. <br />
                Nous vous proposons des articles de haute <br />
                qualité adaptés à vos besoins.
              </p>
            </MDBCol>

            {/* Products Column */}
            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4' style={{ flex: 1 }}>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: '#009F6B', fontWeight: 'bolder', fontSize: '1.2rem', fontFamily: 'Oswald' }}>
                Services
              </h6>
              <p>
                <i className="fa-solid fa-truck-fast"></i> Livraison rapide en 48H
              </p>
              <p>
                <i className="fa-solid fa-file-circle-check"></i> 100% produits authentiques
              </p>
              <p>
                <i className="fa-solid fa-credit-card"></i> Payement à la livraison
              </p>
            </MDBCol>

            {/* Store Column */}
            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4' style={{ flex: 1 }}>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: '#009F6B', fontWeight: 'bolder', fontSize: '1.2rem', fontFamily: 'Oswald' }}>
                Boutiques
              </h6>
              <p>
                <Link className="text-reset" to="/Stores/1" style={{ textDecoration: "none" }}>Croco Nutrition</Link>
              </p>
              <p>
                <Link className="text-reset" to="/Stores/2" style={{ textDecoration: "none" }}>Croco Wear</Link>
              </p>
              <p>
                <Link className="text-reset" to="/Stores/3" style={{ textDecoration: "none" }}>Croco Team</Link>
              </p>
            </MDBCol>

            {/* Contact Column */}
            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4' style={{ flex: 1 }}>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: '#009F6B', fontWeight: 'bolder', fontSize: '1.2rem', fontFamily: 'Oswald' }}>
                Contacts
              </h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />Charguia I , 2035
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-2' />croconutrition@gmail.com
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-2' />+ 216 51 004 774
              </p>
              <p>
  <MDBIcon color='secondary' icon='map-marker-alt' className='me-2' />
  <a href="https://maps.app.goo.gl/d7YG8znw7ts5Dnsg8" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
    Boutique Rades
  </a>
</p>
<p>
  <MDBIcon color='secondary' icon='map-marker-alt' className='me-2' />
  <a href="https://maps.app.goo.gl/xbWpWxZwvhjxzFhX9" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
    Boutique Charguia 1
  </a>
</p>

            </MDBCol>

            {/* Localisation Column */}
            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-1' style={{ flex: 1 }}>
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: '#009F6B', fontWeight: 'bolder', fontSize: '1.2rem', fontFamily: 'Oswald' }}>
                Localisation
              </h6>
              <MDBCol color='secondary' icon='phone' className='me-7'>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12771.902051529169!2d10.2045625!3d36.8430625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd350f0e159295%3A0x353c15e6617a8438!2sCroco%20Nutrition!5e0!3m2!1sen!2stn!4v1726158170539!5m2!1sen!2stn"
                  width="290"
                  height="200"
                  style={{ border: '0' }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Map Location"
                ></iframe>
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: '#000000', fontWeight: 'bold', fontFamily: 'Oswald', color: '#009F6B' }}>
        © 2024 Copyright: CROCO LAND
      </div>

      <style jsx>{`
        @media (max-width: 768px) {
          .row {
            flex-direction: column;
            align-items: center;
            margin-left: 0;
            margin-right: 0;
          }
          .col {
            flex: 1;
            text-align: center;
            margin-bottom: 15px;
          }
          .text-uppercase {
            font-size: 1.2rem;
          }
          iframe {
            width: 100%;
            max-width: 300px;
          }
          /* Ensuring no extra margin or padding */
          .p-4 {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
      `}</style>
    </MDBFooter>
  );
}
