import { Link, useLocation, useParams } from 'react-router-dom';
import OneProduct from './OneProduct';
import { useState, useEffect } from 'react';
import CrocoTeamStore from './CrocoWear'; 
import MovingBanner from './MovingBanner';
import { FaFilter } from 'react-icons/fa';
import CrocoWearProducts from './CrocoTeam';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

function StoreProducts() {

    const baseUrl = CONSTURL; // Use the imported CONSTURL

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [storeName, setStoreName] = useState('');

    // Price range state
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(1000);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 9; // Number of products per page

    const { storeId } = useParams();
    const location = useLocation();

    useEffect(() => {
        fetchCategories();
        fetchData();
    }, [location.search, storeId, selectedCategory, selectedSubcategory, minPrice, maxPrice, currentPage]);

    function fetchData() {
        const queryParams = new URLSearchParams(location.search);
        const page = queryParams.get('page') || 1;

        let url = `${baseUrl}Products/?storeId=${storeId}`;
    
        if (selectedCategory) url += `&category=${selectedCategory}`;
        if (selectedSubcategory) url += `&subcategory=${selectedSubcategory}`;

        let allProducts = []; // This will store all the products fetched across pages

        const fetchPageData = (page) => {
            fetch(`${url}&page=${page}`)
                .then((response) => response.json())
                .then((data) => {
                    allProducts = [...allProducts, ...data.results]; // Append results from each page
                    if (data.next) {
                        // If there is a next page, fetch the next one
                        const nextPage = new URL(data.next).searchParams.get('page');
                        fetchPageData(nextPage);
                    } else {
                        // Once all pages are fetched, filter and set the products
                        const filteredProducts = allProducts.filter(product => product.store.id === parseInt(storeId));

                        const finalProducts = filteredProducts.filter(product => {
                            return product.price >= minPrice && product.price <= maxPrice;
                        }).filter(product => {
                            const productCategory = product.subcategory;
                            if (selectedSubcategory) {
                                return productCategory === parseInt(selectedSubcategory);
                            } else if (selectedCategory) {
                                return categories.some(category => {
                                    return category.id === parseInt(selectedCategory) && category.subcategories.some(sub => sub.id === productCategory);
                                });
                            }
                            return true;
                        });

                        if (finalProducts.length > 0) {
                            setStoreName(finalProducts[0].store.name);
                        } else {
                            setStoreName('');
                        }

                        setProducts(finalProducts);
                    }
                })
                .catch((error) => console.error("Error fetching products:", error));
        };

        fetchPageData(page); // Start fetching from the first page
    }

    function fetchCategories() {
        fetch(`${baseUrl}Categories/`)
            .then((response) => response.json())
            .then((data) => {
                setCategories(data.results);
                if (selectedCategory) {
                    const category = data.results.find(cat => cat.id === parseInt(selectedCategory));
                    setSubcategories(category ? category.subcategories : []);
                }
            });
    }

    // Condition to render Croco Team Store component
    if (storeId === '2') {
        return <CrocoTeamStore />;
    } else if (storeId === '3') {
        return <CrocoWearProducts />;
    }

    // Pagination Logic
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
    const totalPages = Math.ceil(products.length / productsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <section className="container products-page" style={{ marginTop: '250px', marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
            <MovingBanner />
            <div className="titl">
                <h1 className="text-center text-black mb-5 fw-bold tit" style={{ fontFamily: 'Bebas Neue', fontSize: '50px', fontWeight: 'bolder' }}>
                    {storeName ? `Produits ${storeName}` : 'Products'}
                </h1>
            </div>

            <div className="text-center mb-3 hide-on-desktop">
                <FaFilter
                    size={24}
                    style={{ cursor: 'pointer', color: 'black' }}
                />
            </div>

            <div className="row">
                <aside className="col-md-3" style={{ height: '900px' }}>
                    <div className="card shadow-sm p-5 mb-5" style={{
                        border: '3px solid #009F6B',
                        borderRadius: '15px',
                        
                    }}>
                        <h5 className="mb-4" style={{ fontFamily: 'Roboto', fontWeight: 'bolder', textTransform: 'uppercase' }}>Filtre des produits</h5>

                        {/* Category Filter */}
                        <div className="mb-4">
                            <label className="form-label" style={{ fontFamily: 'Roboto', textTransform: 'uppercase', fontWeight: 'bolder' }}>Catégories</label>
                            {categories.map((category) => (
                                <div key={category.id} className="form-check" style={{ fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`category-${category.id}`}
                                        value={category.id}
                                        checked={selectedCategory === category.id}
                                        onChange={(e) => {
                                            const selected = e.target.checked ? category.id : '';
                                            setSelectedCategory(selected);
                                            const selectedCategories = categories.filter(cat => selected === cat.id);
                                            const subcategories = selectedCategories.flatMap(cat => cat.subcategories);
                                            setSubcategories(subcategories);
                                        }}
                                    />
                                    <label htmlFor={`category-${category.id}`} className="form-check-label">
                                        {category.title}
                                    </label>
                                </div>
                            ))}
                        </div>

                        {/* Subcategory Filter */}
                        <div className="mb-4">
                            <label className="form-label" style={{ fontFamily: 'Roboto', textTransform: 'uppercase', fontWeight: 'bolder' }}>Subcatégories</label>
                            {subcategories.map((subcategory) => (
                                <div key={subcategory.id} className="form-check" style={{ fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`subcategory-${subcategory.id}`}
                                        value={subcategory.id}
                                        checked={selectedSubcategory === subcategory.id}
                                        onChange={(e) => {
                                            const selected = e.target.checked ? subcategory.id : '';
                                            setSelectedSubcategory(selected);
                                        }}
                                    />
                                    <label htmlFor={`subcategory-${subcategory.id}`} className="form-check-label">
                                        {subcategory.title}
                                    </label>
                                </div>
                            ))}
                        </div>

                        {/* Price Range Filter */}
                        <div className="mb-4">
                            <label htmlFor="price-range" className="form-label" style={{ fontFamily: 'Roboto', textTransform: 'uppercase', fontWeight: 'bolder' }}>
                                Plage de prix (DT)
                            </label>
                            <div className="d-flex justify-content-between align-items-center">
                                <span style={{ fontFamily: 'Roboto', textTransform: 'uppercase', fontWeight: 'bolder' }} >{minPrice} DT</span>
                                <div>
                                    <style>
                                        {`
                                        #price-range::-webkit-slider-thumb,
                                        #price-range-max::-webkit-slider-thumb {
                                            -webkit-appearance: none;
                                            appearance: none;
                                            width: 15px; /* Set thumb width to match default size */
                                            height: 15px; /* Set thumb height to match default size */
                                            border-radius: 50%;
                                            background: green;
                                            cursor: pointer;
                                        }

                                        #price-range::-moz-range-thumb,
                                        #price-range-max::-moz-range-thumb {
                                            width: 16px; /* Set thumb width to match default size */
                                            height: 16px; /* Set thumb height to match default size */
                                            border-radius: 50%;
                                            background: green;
                                            cursor: pointer;
                                        }

                                        #price-range,
                                        #price-range-max {
                                            -webkit-appearance: none;
                                            appearance: none;
                                            height: 8px; /* Keep default track height */
                                            background: #ddd; /* Track color */
                                            border-radius: 5px;
                                            outline: none;
                                            cursor: pointer;
                                        }
                                        `}
                                    </style>
                                    <input
                                        type="range"
                                        id="price-range"
                                        className="form-range"
                                        min={0}
                                        max={1000}
                                        value={minPrice}
                                        onChange={(e) => setMinPrice(Number(e.target.value))}
                                        style={{ margin: '0 5px' }}
                                    />
                                </div>
                                <span style={{ fontFamily: 'Roboto', textTransform: 'uppercase', fontWeight: 'bolder' }} >{maxPrice} DT</span>
                            </div>
                        </div>

                    </div>
                </aside>

                <div className="col-md-9">
                    <div className="row">
                        {currentProducts.map((product) => (
                            <OneProduct key={product.id} product={product} />
                        ))}
                    </div>

                    {/* Pagination Controls */}
                   
                </div>
                <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center mt-4" style={{ gap: '10px' }}>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(index + 1)}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            border: '2px solid #000', // Adjust the border color as needed
                                            backgroundColor: currentPage === index + 1 ? '#000' : '#fff',
                                            color: currentPage === index + 1 ? '#fff' : '#000',
                                            display: 'flex',
                                            fontFamily: 'Oswald',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: '1.2rem',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s ease',
                                        }}
                                    >
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
            </div>
        </section>
    );
}

export default StoreProducts;
