import React, { useEffect, useState } from 'react';
import './BackgroundAnimation.css';

const VideoGallery = () => {
    const [videos, setVideos] = useState([]);
    const itemsPerPage = 8; // Show 8 videos per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetch('http://localhost:8000/videos/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                setVideos(data);
                setTotalPages(Math.ceil(data.length / itemsPerPage)); // Update the total number of pages
            })
            .catch((error) => console.error("Fetching error:", error));

        // Set the page background color to black when the component mounts
        document.body.style.backgroundColor = "black";

        // Cleanup when component unmounts to reset background color
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Slice the video data based on the current page and items per page
    const currentVideos = videos.slice(
        (currentPage - 1) * itemsPerPage, 
        currentPage * itemsPerPage
    );

    return (
        <section className="container products-page" style={{ marginTop: '250px', marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
              <div
                    style={{
                       
                 
                        backgroundSize: 'cover',
                        minHeight: '100vh',
                        color: 'white',
                        
                    }}
                >
                    <h1 style={{ color: '#009F6B', fontSize: '40px' }} >MA CHAÎNE YOUTUBE</h1>
                    <p style={{  fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '14px' }}>
                          Sur YouTube, je laisse à votre disposition un panel de vidéos d’entraînements réalisées en compagnie de mes athlètes.
                        Ce sera pour vous l'occasion d'y découvrir des conseils avisés et des exercices ciblés mais plus que tout, l'énergie qui nous anime. 
                        Laissez-la vous imprégner afin qu'elle vous accompagne dans votre transformation physique. <br/>  Chaque vidéo offre une perspective différente pour répondre à vos attentes  dans cette quête de : "LA MEILLEURE VERSION DE VOUS MÊME".
                    </p>
                    
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
    <div style={{ width: '100%', maxWidth: '1200px', height: '0', paddingBottom: '56.25%', position: 'relative', overflow: 'hidden', borderRadius: '8px', border: '1px solid #ddd' }}>
        <iframe
            src="https://www.youtube.com/embed/jwi0zoe6Rxs?start=168"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
        ></iframe>
    </div>
</div>


        <a 
            href="https://www.youtube.com/@azermhamdi3670"  // Lien vers la chaîne YouTube
            target="_blank" 
            rel="noopener noreferrer"
            style={{ 
                display: 'inline-block', 
                padding: '10px 20px', 
                backgroundColor: '#009F6B', 
                color: '#fff', 
                borderRadius: '5px', 
                textDecoration: 'none',
                fontWeight: 'bold' 
            }}
        >
            DÉCOUVRIR MA CHAÎNE YOUTUBE 
        </a>
    </div>

    </section>
    );
};

export default VideoGallery;
