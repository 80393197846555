import React, { useEffect, useState } from 'react';
import './BackgroundAnimation.css';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

const baseUrl = CONSTURL; // Use the imported CONSTURL

const TransformationGallery = () => {
    const [transformations, setTransformations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 8; // Set to 8 items per page for mobile

    useEffect(() => {
        fetch(baseUrl + 'transformations/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                setTransformations(data);
                setTotalPages(Math.ceil(data.length / itemsPerPage)); // Recalculate total pages based on items per page
            })
            .catch((error) => console.error("Fetching error:", error));

        // Set the page background color to black when the component mounts
        document.body.style.backgroundColor = "black";

        // Cleanup when component unmounts to reset background color
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Slice the data based on the current page and items per page
    const currentTransformations = transformations.slice(
        (currentPage - 1) * itemsPerPage, 
        currentPage * itemsPerPage
    );

    return (
        <section className="container products-page"  style={{
            marginTop: '250px',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Spread content and pagination
      
        }}>

<h1 className="text-center text-white mb-5 fw-bold" style={{  
                fontFamily: 'Bebas Neue', 
                fontSize: '50px', 
                
                fontWeight: 'bolder' ,
                
            }}>
                Transformation
            </h1>
          
            <div
                style={{
                
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
                    minHeight: '850px',
                    padding: '80px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                
                }}
            >
          
                <div
                     style={{
                        marginTop: '-90px',
                        maxWidth: '1500px',
                        width: '100%',
                        display: 'grid',
                        gap: '60px',
                        padding: '10px',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // Responsive grid
                        justifyContent: 'center', // Center horizontally
                    }}
                >
                    {currentTransformations.map((transformation, index) => (
                        <div
                            key={index}
                            style={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                overflow: 'hidden',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                border: '3px solid #009F6B',
                                borderRadius: '15px',
                               
                        
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        >
                            <img
                                src={transformation.image_url}
                                alt={transformation.title}
                                style={{
                                    width: '100%',
                                    height: '350px',
                                    objectFit: 'cover',
                                }}
                                onError={(e) => (e.target.style.display = 'none')}
                            />
                            <div style={{ padding: '15px', textAlign: 'center' }}>
                                <h3 style={{ fontSize: '1.2rem', margin: '10px 0', color: '#333' }}>
                                    {transformation.title}
                                </h3>
                                <p style={{ fontSize: '1rem', color: '#555', lineHeight: '1.5' }}>
                                    {transformation.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Pagination Controls */}
            <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center mt-4" style={{ gap: '10px' }}>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(index + 1)}
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: '50%',
                                    border: '2px solid #000',
                                    backgroundColor: currentPage === index + 1 ? '#000' : '#fff',
                                    color: currentPage === index + 1 ? '#fff' : '#000',
                                    display: 'flex',
                                    fontFamily: 'Oswald',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '1.2rem',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease',
                                    border: '1px solid #009F6B',
                                    borderRadius: '15px',
                                }}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </section>
    );
};

export default TransformationGallery;
