import React, { useState, useEffect, useContext } from 'react';
import './ProductDetail.css';
import { Link, useParams, useLocation } from 'react-router-dom';
import { UserContext, CartContext } from './Context';
import axios from 'axios';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

function ProductDet() {


    const baseUrl = CONSTURL; // Use the imported CONSTURL
    const [productData, setProductData] = useState(null); // Initial state is null to check if data is fetched
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [productImgs, setProductImgs] = useState([]);
    const { Product_id } = useParams();
    const [cartButtonClickStatus, setCartButtonClickStatus] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const { cartData, setCartData } = useContext(CartContext);
    const userContext = useContext(UserContext);
    const [wishlistMessage, setWishlistMessage] = useState('');
    const location = useLocation();

    // Zoom state for product image
    const [isZoomedProduct, setIsZoomedProduct] = useState(false);
    const [zoomPositionProduct, setZoomPositionProduct] = useState({ x: 50, y: 50 });

    useEffect(() => {
        fetchData(baseUrl + 'product_wear/');
    }, [Product_id]);

    async function fetchData(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setProductData(data[0]); // The response is an array, so we set the first product
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }

    const cartAddButtonHandler = () => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const cartData = {
            product: {
                id: productData.id,
                title: productData.title,
                price: productData.price,
                image: `${baseUrl}${productData.image}`,
            },
            quantity: selectedQuantity,
            user: {
                id: 1, // Assuming user ID is hardcoded or fetched from context
            },
        };
        const updatedCartJson = [...cartJson, cartData];
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
        setCartButtonClickStatus(true);
    };

    const cartRemoveButtonHandler = () => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const updatedCartJson = cartJson.filter(cart => cart.product.id !== productData.id);
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
        setCartButtonClickStatus(false);
    };

    // Mouse move handler for product image
    const handleMouseMoveProduct = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setZoomPositionProduct({ x, y });
        setIsZoomedProduct(true);
    };

    // Mouse leave handler for product image
    const handleMouseLeaveProduct = () => {
        setIsZoomedProduct(false);
    };

    const { discount } = productData || {};

    // Displaying the product details dynamically from the API response
    if (!productData) {
        return <p>Loading product data...</p>;
    }

    return (
        <section className="container mt-5" style={{ marginLeft: 'auto', marginRight: 'auto', width: '70%' }}>
            <div className="row" style={{ marginTop: '250px' }}>
                {/* Product Thumbnail Slider */}
                <div className="col-12 col-md-4 d-flex justify-content-center mb-4">
                    <div id="productThumbnailSlider" className="carousel carousel-dark slide carousel-fade" data-bs-ride="true" style={{ maxWidth: "100%", width: "400px" }}>
                        <div className="carousel-inner">
                            <div className="carousel-item active" style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="img-container" onMouseMove={handleMouseMoveProduct} onMouseLeave={handleMouseLeaveProduct} style={{ position: 'relative' }}>
                                    <img src={`${baseUrl}${productData.image}`} className="img-thumbnail mb-5" alt="Product" style={{ maxWidth: "110%", height: "auto" }} />
                                    {isZoomedProduct && (
                                        <div className="zoom-overlay" style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: '100%',
                                            height: '100%',
                                            backgroundImage: `url(${baseUrl}${productData.image})`,
                                            backgroundPosition: `${zoomPositionProduct.x}% ${zoomPositionProduct.y}%`,
                                            backgroundSize: '300% 300%',
                                            zIndex: 100,
                                            pointerEvents: 'none',
                                            borderRadius: '10px',
                                            border: '1px solid #fff'
                                        }}></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                                {/* Product Details */}
                                <div className="col-12 col-md-8">
                    <div style={{ marginLeft: '20px', padding: '0 15px' }}>
                        <h3 style={{ textAlign: 'center', fontFamily: 'Oswald', fontSize: '45px', color: 'black', letterSpacing: '1.5px', fontWeight: 'bolder', textTransform: 'uppercase' }}>
                            {productData.title}
                        </h3>
                        <div style={{ marginBottom: '40px' }}></div>
                        <p style={{ fontFamily: 'Oswald', fontSize: '22px', color: '#555555', fontWeight: 'bolder' }}>{productData.detail}</p>
                        <div style={{ marginBottom: '30px' }}></div>
                        <h3 style={{ fontFamily: 'Oswald', fontSize: '33px', color: '#26619C', letterSpacing: '1.5px', fontWeight: 'bolder', display: 'inline-block' }}>
                            Prix:
                            {discount > 0 ? (
                                <>
                                    <span style={{ textDecoration: 'line-through', marginRight: '10px', color: '#888' }}>
                                        {productData.price.toFixed(3)}DT
                                    </span>
                                    <span style={{ color: '#cf000f' }}>
                                        {(productData.price - (productData.price * discount / 100)).toFixed(3)}DT
                                    </span>
                                </>
                            ) : (
                                <span>{productData.price}DT</span>
                            )}
                        </h3>
                        <div className="d-flex flex-column flex-md-row align-items-center gap-3 mt-4">
                            <button className="btn" style={{ fontFamily: 'Oswald', fontSize: '20px', padding: '15px 35px', fontWeight: '500', borderRadius: '5px', backgroundColor: '#2ecc71', textTransform: 'uppercase' }} onClick={cartAddButtonHandler}>
                                <i className="fa-solid fa-cart-plus"></i> Ajouter au panier
                            </button>
                            {cartButtonClickStatus && (
                                <button className="btn" style={{ fontFamily: 'Oswald', fontSize: '20px', padding: '15px 35px', borderRadius: '5px', backgroundColor: '#DC343B' }} onClick={cartRemoveButtonHandler}>
                                    <i className="fa-solid fa-circle-minus"></i> Supprimer du panier
                                </button>
                            )}
                        </div>
                        <div className="d-flex flex-column flex-md-row mt-3 gap-3">
                            <p style={{ backgroundColor: '#e4f1fe', padding: '10px 15px', borderRadius: '5px', fontSize: '16px', fontFamily: 'Oswald', fontWeight: 'normal' }}>
                                Quantité: {productData.quantity}
                            </p>
                            <p style={{ backgroundColor: '#e4f1fe', padding: '10px 15px', borderRadius: '5px', fontSize: '16px', fontFamily: 'Oswald', fontWeight: 'normal' }}>
                                Colors: {productData.color && productData.color.join(', ')}
                            </p>
                            <p style={{ backgroundColor: '#e4f1fe', padding: '10px 15px', borderRadius: '5px', fontSize: '16px', fontFamily: 'Oswald', fontWeight: 'normal' }}>
                                Sizes: {productData.size && productData.size.join(', ')}
                            </p>

                        </div>
                        

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductDet;
