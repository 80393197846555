import React, { useContext, useState } from 'react';
import { CartContext } from './Context';
import { Link,useNavigate  } from 'react-router-dom';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant
import './OrderValidation.css';


const baseUrl = CONSTURL; // Use the imported CONSTURL
function OrderValidation() {
    const { cartData, setCartData } = useContext(CartContext);
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate(); 
    

    // Liste des villes de Tunisie avec leurs délégations
    const tunisiaCities = [



        {name: 'Beja-Teboursouk',
            delegations : ['Ain El Melliti','Teboursouk','Ain Jemmala','Bir Ettouta','Bouzaida','Cite Ain El Karma','Cite Ain Mrad','Cite De La Republique','Cite Des Martyrs','Cite Du Stade','Cite El Bassatine','Cite El Glai','Cite El Gourjani','Cite El Hadj Abdallah','Cite El Ksar','Cite Ennacim','Cite Ennacim 1','Cite Ennacim 2','Cite Ezzayatine 1','Cite Ezzayatine 2','Cite Ibn Khaldoun','Cite Ibn Sina','Cite Industrielle','Cite Jardins','Cite Meska','Cite Oued Essah','Cite Sidi Bagga','Dougga Rouines','El Ayedh','El Faouar','El Matria','Naffet','Ouled Marai','Rihana','Teboursouk','Fadden Essouk','Borj Brahim','Douga','Khalled','Ain El Hammam','Ain El Kehoul']
            },
          
            {
            name: 'Beja-Mejez El Bab',
            delegations: [
              'Argoub Ezzaatar','Mejez El Bab','Cite Baccar','Cite De La Sante','Cite Des Proffesseurs','Cite Dhamene','Cite Du Gouvernorat','Cite El Azima 1','Cite El Azima 2','Cite El Azima 3','Cite El Bahrine','Cite El Behi','Cite El Fateh','Cite El Ferdaous 1','Cite El Hana','El Ksar','Cite El Manar','Cite El Menchari','Cite Erriadh','Cite Essedk','Cite Ettadhamen','Cite Ezzouhour','Cite Jallazia','Cite Majerda','Gueriche El Oued','Hidous','Iden','Mejez El Bab','Sidi Ahmed Jedidi','Cite 02 Mars','El Griaat','Toukabeur','Chaouech','El Herri','Sidi Nasser','Cite De La Poste','Cite Education','Cite Enfance','Louatia','Oued Ezzitoun','Oued Zarga','Sidi Mediene'
            ]
          },
          {name: 'Beja-Beja Nord',
           delegations: [
             'Cite Taieb Mhiri','Beja Nord' ,'Cite Usine Du Sucre1', 'Ain Ksar Hadid', 'Cite Usine Du Sucre2','Beja', 'El Hamrounia', 'Bou Keslane', 'El Mounchar', 'Charchara', 'El Yousri','Cite Afh', 'Fatnassa', 'Cite Ain Bazaz', 'Hajar Amor', 'Cite Ain Chemes', 'Kasseb','Cite Ain El Ghoula', 'Lafarke', 'Cite Beja El Jadida', 'Nagachia', 'Cite Belle Vue', 'Tarhouni', 'Cite Bir Hofra', 'Cite Boutraa', 'Cite Cnrps', 'Cite De La Police','Cite De La Sante 1', 'Cite De La Sante 2', 'Cite Des Mimosas', 'Cite Des Proffesseurs', 'Cite Des Roses', 'Cite Dhamene', 'Cite Du Gouvernorat', 'Cite Echifa','Cite Economie Du Nord', 'Cite Edhahbia 1', 'Cite Edhahbia 2', 'Cite El Baraka',  'Cite El Biadha', 'Cite El Boukhari', 'Cite El Boustene', 'Cite El Habib',  'Cite El Habibia', 'Cite El Haouari', 'Cite El Khadhra','Cite El Khadhra Superieur', 'Cite El Manar 1', 'Cite El Manar 2','Cite El Manar 3', 'Cite El Manar 4', 'Cite El Manar 5', 'Cite El Mekmda','Cite El Mhella', 'Cite El Moustakbel', 'Cite El Mzara', 'Cite El Olfa','Cite Ennouzha', 'Cite Erriadh 2', 'Cite Erriadhi 1', 'Cite Essalama','Cite Essalem', 'Cite Essanaouber', 'Cite Ezzouhour', 'Cite Garde Nationale','Cite Ibn Khaldoun', 'Cite Jardins', 'Cite Jebel El Akhdhar', 'El Mzara','Cite Ksar Bardo', 'Cite Pont Trageon', 'Cite S.R.Transport', 'Cite Salah El Maghraoui', 'Cite Salambo', 'Cite Sidi Frej', 'Cite Snit'
           ]
          },
           {name: 'Beja-Testour',
            delegations : ['Cite Erraoudha','Testour','Cite Essayadine','Cite Fedden Jemaa','Cite Jabbess','Cite Kichtilou','Cite Ouled Slimane','Cite Sidi Khouili','Cite Tafercha','Cite Tijani','El Mellah','Esslouguia','Jebel Fellous','Ouljet Doukhane','Ain Younes','Cite 20 Mars','Cite 26 Fevrier','Cite Des Andalous','Cite El Bassatine','Cite El Folla','Cite El Izdihar','Cite El Malouf','Cite Ennacim','Cite Gharnata 1','Cite Gharnata 2','Cite Ibn Zeidoun','Cite Spiniair','El Glaa','Jlass Bou Issa','Mezougha','Oued Jedra','Bab Bled','Ouled Slama','Cite Bahrnia','Rhiba','Cite Beharnia','Testour','Cite Ben Karouia','Zeldou','Cite Ben Othman','Esskhira','Cite Dar Echabeb','Henchir Bou Sofra','Cite El Gadhoum','Ain Tounga','Cite El Mendra']},
          
          {name: 'Beja-Nefza',
           delegations: [
            'Cite De La Liberte','Nefza', 'Cite De La Poste', 'Cite Des Carrieres', 'Cite Des Roses','Cite El Mhiri', 'Cite Erriadh', 'Cite Essaada', 'Cite Ezzouhour','Cite Independance', 'Cite Mixte', 'Cite Mohsen Limam', 'Cite Rim','Cite Sidi Saad', 'Dar El Hit', 'El Amamria', 'El Ayayada Jamila','El Mazdour', 'Fatnassa', 'Jbel Diss', 'Jemil','Nefza', 'Oued Bouzenna', 'Oued Damous', 'Ouled Houimel','Ouled Kacem', 'Oum Laabid', 'Tababa', 'Ain Zakkar','Bou Garnouna', 'El Hemaidia', 'El Mecherga', 'El Oulija','Ettaref', 'Ezzouaraa', 'Ouechtata', 'Ouled Salem','Zaga'
           ]},
          
           {name: 'Beja-Beja Sud',
           delegations: [
            'Mastouta', 'Mastouta Gare', 'Sidi Smail', 'Cite El Amel','El Maagoula','Beja Sud', 'Sidi Frej', 'Hammam Sayala'
           ]},
           {name: 'Beja-Thibar',
           delegations: [
            'Cite 2 Mars', 'Cite Commerciale', 'Cite Des Ouvriers', 'Cite El Olfa','Cite Ennour', 'Cite Essalama', 'Cite Hached', 'Cite Ibn Khaldoun','Ennechima', 'Thibar', 'Ain Eddefali', 'Djebba', 'El Henancha'
           ]},
          
           {name: 'Beja-Amdoun',
           delegations: [
            'Adailia', 'Ain El Goussa', 'Ain Ghenem', 'Beni Melek', 'Bouris','Cite De La Poste', 'Cite Erraoudha', 'Cite Hached', 'Cite Taieb Mhiri','Eddghabjia', 'Edhhirat', 'El Fraijia', 'El Ghorfa', 'El Graia','El Hlikat', 'El Jouza', 'El Mjales', 'Gaat El Mouhdi', 'Ghouzia','Maghraoua', 'Menzel Hammed', 'Remadhnia', 'Sidi Mbarek', 'El Ayayda','Sidi Saadoun', 'El Homr', 'Sobah', 'Menzel El Gourchi', 'Zahret Mediene'
           ]},
          {name: 'Beja-Goubellat',
           delegations: [
            'Bir El Euch', 'Briouig', 'Cheik El Ouediane El Guebli', 'Cite Des Fonctionnaires','Cite El Hana', 'Cite El Izdihar', 'Cite El Kamh', 'Cite Essaada','Cite Essanabel', 'Cite Ezzouhour', 'Cite Populaire', 'Dour Ismail','El Fedhailia', 'El Gammarti', 'El Ogla', 'Ettraifa', 'Goubellat','Khacheb', 'Khenguet Eddihenne', 'Tella', 'Zone Industrielle', 'Grame'
           ]},
          
          
          
             { name: 'BIZERTE-Sud', 
             delegations: ['Bizerte Hached', 'Cite El Mourouj', 'Cite Militaire', 'Teskraya', 'La Pecherie', 'Methline', 'Ain El Berda', 'Ain Jenane', 'Borj Challouf', 'El Mrazig', 'Louata', 'Bou Zaria']
              },
          
            { name: 'BIZERTE-Sejnane',
             delegations: ['Essmayria', 'Haddada', 'Sejnane', 'Sidi Machreg', 'Touajnia', 'El Guetma', 'Cite El Bassatine', 'Cite El Ghabette', 'Cite El Mouasker', 'Cite Essaada', 'Cite Ezzouhour', 'Cite Ezzouhour 2', 'Cite Ibn Khaldoun', 'Cite Khaled Ibn El Walid', 'Cite Mougaad', 'Cite Sidi Mansour', 'Cite Taieb Mhiri', 'Dhouahria', 'El Abebsa', 'El Hania', 'El Mrifeg']
             },
          
            { name: 'BIZERTE-Ghar El Melh', 
            delegations: ['Zouaouine', 'Cite 7 Novembre', 'Cite 9 Avril', 'Cite El Amen', 'Cite El Mourouj', 'Cite Khaled Ibn El Walid', 'Cite Nouvelle', 'El Aousja', 'Bajou', 'Ghar El Melh', 'Touibia']
             },
          
            { name: 'BIZERTE-Menzel Jemil',
             delegations: ['Cite El Makarem', 'Cite Ettahrir', 'Cite 7 Novembre', 'Cite Ettakaddoum', 'Cite Bir Remel', 'Cite Des Oranges', 'Cite Independance', 'Cite El Ahlem', 'Cite El Habib (El Azib)', 'El Ain El Kebira', 'Cite El Baali', 'Cite Rurale 1', 'Menzel Abderrahman', 'Cite El Batan', 'Cite Rurale 2', 'Cite El Habib (Menzel Jemil)', 'El Azib', 'Cite El Haddadia', 'Henchir El Moukaouimine', 'Cite El Hbess', 'Maghraoua', 'Cite Ennouzha', 'Cite Ettaffala', 'Cite Habib Arifa', 'Cite Nouvelle', 'Menzel Jemil', 'Cite Bir Hmem', 'Cite Chamelle', 'Cite Driss Ben Frej'] 
            },
          
            { name: 'BIZERTE-Menzel Bourguiba',
             delegations: ['Menzel Bourguiba Ennajah', 'Jouaouda', 'Cite Ben Aleya', 'Cite De La Douane', 'Cite La Ruche', 'Cite Nouvelle', 'Cite Sidi Yahya', 'Menzel Bourguiba']
             },
          
              {
              name: 'BIZERTE-Utique',
              delegations: [
                'Ain Ghelal', 'Ain Smara', 'El Besbassia', 'El Bhalil Chargui', 'El Brij', 'El Fejja','Bach Hamba', 'Cite De La Poste', 'Cite Ejjamaa', 'Cite Ezzayatine', 'Cite Houmet Souk','Ghornata', 'Lahouidh', 'Utique', 'Utique Nouvelle', 'Degma', 'El Mabtouh', 'Essakak','Sidi Othman'
              ]
            },
          
            {
              name: 'BIZERTE-Ras Jebel ',
              delegations: [
                'Sounine', 'Beni Atta', 'El Garia Ras Jebal', 'Cite El Amel', 'Cite Taieb Mhiri', 'Rafraf','Rafraf Plage', 'Cite Ain Charchara', 'Cap Zbib', 'Cite Bir Ezzaarour', 'Metline', 'Cite El Benzarti','Cite Ezzayatine', 'Cite Kaa El Ballout', 'Ras Jebel'
              ]
            },
          
            {
              name: 'BIZERTE-Ghezala',
              delegations: [
                'Ain Rekoub', 'Beni Brahim', 'Bou Jrir', 'Cite Afh', 'Cite Borj Deheb', 'Cite Rurale', 'Cite Snit','El Atiba', 'El Garia', 'El Jafna', 'Ghezala', 'Henchir Ettouila', 'Laouilia', 'Oued Ezzitoun','Ouled El May'
              ]
            },
          
            {
              name: 'BIZERTE-Nord',
              delegations: [
                'Ennadhour', 'Fejjet Errih', 'Ksar Lahmar', 'Marnissa', 'Sidi Ahmed', 'Bizerte', 'Cite Ain Mariem','Cite Belvedere', 'Cite Bougatfa', 'Cite De La Municipalite', 'Cite Erriadh', 'Cite Hassen Nouri','Cite Nouvelle Plage', 'Bizerte Bougatfa', 'Ksiba', 'Cite Afh', 'Cite Militaire', 'Cite Ain Meriem','Cite Chourouk Sidi Salem', 'Cite Bougatfa 2', 'Cite De La Sante', 'Cite De La Police', 'Cite De Paris','Cite El Korniche 1', 'Cite El Hana', 'Cite El Korniche 2', 'Cite Ennacim', 'Cite El Korniche 3','Cite Erraouabi', 'Cite El Morjene 3', 'Oued El Marj', 'Bizerte Bab Mateur'
              ]
            },
          
            {
            name: 'BIZERTE-El Alia',
            delegations: ['Ezzitoune', 'Khetmine', 'Sidi Ali Chebab', 'Cite Independance', 'El Alia', 'El Hariza', 'Impasse Ezzouhour']
          },
          {
            name: 'BIZERTE-Mateur',
            delegations: ['Cite De La Fraternite', 'Cite De La Gare', 'Cite El Manar', 'Cite El Omrane 1', 'Cite El Omrane 2', 'Cite El Omrane 3', 'Cite El Omrane 4', 'Cite Ennahdha', 'Henchir El Berna', 'Cite Ennasr', 'Cite Erraja 1', 'Cite Erraja 2', 'Cite Erraja 3', 'Cite Essadaka', 'Cite Ezzouhour', 'Cite Khemouma', 'Cite Montazah', 'Cite Snit', 'Cite Zarrouk', 'El Aouana', 'Jebel Ichkeul', 'Mateur', 'Sidi Mansour', 'Sidi Nsir', 'Borj El Adouani', 'Cite Hached', 'Ettaref', 'Henchir El Kahla', 'Jabouz', 'Mateur Hached', 'Oum Jenna', 'Sidi Salem']
          },
          
          {
            name: 'BIZERTE-Jarzouna',
            delegations: ['Cite Ben Arous', 'Cite Bouchoucha 2', 'Cite Calyptus', 'Cite Du Soleil', 'Cite Ennacim', 'Cite Erraja 1', 'Cite Erraja 2', 'Cite Erraja 3', 'Cite Errimel', 'Cite Errsass', 'Cite Mohamed Chehimi', 'Cite Nouvelle', 'Jarzouna']
          },
          {
            name: 'BIZERTE-Tinja',
            delegations: ['Cite El Fateh', 'Cite El Fouladh', 'Cite El Khadhraoui', 'Cite Ikbal', 'Cite Sidi Rezig', 'Cite Sprols', 'Cite Zghaba', 'Kharrata', 'Tinja']
          },
          
          
          {
            name: 'Gabes-Nouvelle Matmat',
            delegations: [
              'Douiouira', 'El Amra', 'Nouvelle Matmata', 'Ezzahra', 'Mzaten', 'Laffam','Oued El Harika', 'Zaten Beni Zelten', 'Zaten Haddej', 'Bou Sbah','Chobet Chemlali', 'Cite Edechaicha', 'Cite El Izdihar', 'Cite Guedouala','Ancienne Zraoua', 'Nouvelle Zraoua', 'Sidi Gnaou', 'Cite Ouled Slama','Dhokara', 'Beni Zelten'
            ]
          },
          {
            name: 'Gabes-Mareth',
            delegations: [
              'Mareth', 'Mazraa Ben Slama', 'Toujane', 'Mejni', 'Sidi Touati', 'Oued El Ghirane','Zarat', 'Ourifen', 'Zmorten', 'Betahra', 'Dekhilet Toujane', 'Ezzerkine', 'Ain Tounine','Cite Populaire', 'Kettana', 'Oued El Hajel', 'Sidi Slam', 'Zrig El Barrania', 'Zrig El Ghandri','Ancien Bled', 'Azeiza', 'Betahra Sud', 'Braouka', 'Cite Commerciale', 'Cite Debaba', 'Cite El Amel','Cite El Argoub', 'Cite El Charfia', 'Cite El Ghardek1', 'Cite El Ghardek2', 'Ayoune Ezzerkine','Cite El Ghardek3', 'Arram', 'Cite El Hafaya', 'Cite El Kribia', 'Cite El Masla', 'Cite El Melayha','Cite Ennouzha', 'Cite Garde Nationale', 'Cite Snit', 'El Alaya', 'El Akhmes', 'El Frada', 'El Kaouakba','Graa Beiri'
            ]
          },
          
          {
            name: 'Gabes',
            delegations: [
              'Gabes Medina', 'Cite Des Oasis', 'Cite El Hana', 'Cite El Khalij', 'Cite El Korniche','Cite El Mansoura', 'Cite El Ouns', 'Cite Mohamed Ali', 'El Mouazir', 'Gabes', 'Gabes El Hidaya','Limaoua', 'Oued Khelifa Mahmoud', 'Remathi', 'Gabes Hached', 'Chatt Essalem', 'Cite 26-26','Cite El Bled', 'Cite El Ftarcha', 'Gabes Port', 'Gabes Republique', 'Cite Ben Kilani'
            ]
          },
          {
            name: 'Gabes-Ouest',
            delegations: [
              'Nahal', 'Bou Chemma', 'Cite 2 Mars', 'Cite Ettahrir', 'Barguia', 'Chenini Gabes', 'Maita'
            ]
          },
          
          {
            name: 'Gabes-El Hamma',
            delegations: [
              'Bou Attouche', 'Bechima', 'Chenchou', 'El Hamma Sud', 'Beni Ghilouf', 'Sombat', 'El Hamma'
            ]
          },
          {
            name: 'Gabes-El Metouia ',
            delegations: [
              'Cite Ennacim', 'Cite Essalem', 'Cite Kheireddine', 'Metouia El Maya', 'El Mida', 'Ouali','Oudhref', 'Cite El Fankar', 'Cite El Maya', 'Cite El Mrachda', 'Cite Ezzouhour', 'Cite Nehila Farhat','El Aouinette', 'El Imarat', 'El Metouia', 'Oued El Ghram', 'El Akarit', 'El Hicha'
            ]
          },
          {
            name: 'Gabes-Menzel Habib',
            delegations: [
              'El Fejij', 'El Mida', 'Hejri', 'Mehamla', 'Menzel Habib', 'Ouali', 'Oued Ezzitoun', 'Ouled Dhaou', 'Zograta'
            ]
          },
          
          {
            name: 'Gabes-Matmata',
            delegations: [
              'Tamezrat', 'Bou Dhafer', 'Chouabet Esmaala', 'Cite El Azezna', 'Cite Hafi Rassa', 'Cite Hedi Chaker','Cite Independance', 'Cite Mohamed Ali', 'Cite Ras El Oued', 'Haddej', 'Ksar Beni Aissa', 'Matmata','Taoujout', 'Tijma', 'Techine', 'Zriba'
            ]
          },
          {
            name: 'Gabes-Sud',
            delegations: [
              'Cite 7 Novembre', 'Cite Bedis', 'Cite El Wafa', 'Cite Hached', 'Teboulbou', 'Cite El Amel','Cite El Amel 1', 'Cite El Izdihar', 'Cite El Amel 2', 'Cite El Medina', 'Cite El Amel 3', 'Cite Medenine','Cite El Amel 4', 'Gabes El Menara', 'Cite El Amel 5', 'Cite Afh', 'Cite El Amel 6', 'Cite Ancien Militaire','Cite El Maarifa', 'Cite Bidani', 'Cite Ennakhil', 'Cite El Hana Boudoura', 'Cite Ennour', 'Cite El Misk','Cite Militaire Dimassi', 'Cite Rhouma', 'Sidi Boulbaba', 'Cite El Ahrach', 'Cite El Guefercha', 'Cite Azaiez','Cite Snit', 'Cite De La Famille', 'Cite Sprols', 'Cite Des Enseignants', 'Zrig', 'Cite Des Infirmiers','Cite El Mzahid', 'Cite El Yasmine', 'Cite Ennozh', 'Cite Esseptia', 'Cite Ezzouhour 1', 'Cite Ezzouhour 2','Cite Hached 1', 'Cite Hached 2', 'Cite Ibn Khaldoun', 'Mtorrech', 'El Mdou'
            ]
          },
          
          {
            name: 'Gabes-Ghannouche',
            delegations: [
              'Cite El Izdihar', 'Cite Errimel', 'Cite Ettahrir', 'Ghannouche'
            ]
          },
          
          
          
          
          {
            name: 'Gafsa-El Guettar ',
            delegations: [
              'Cite Orbata Est', 'Cite Orbata Ouest', 'Cite Populaire','Cite Populaire Route Gabes', 'Cite Sprols', 'El Amaiem','El Guettar', 'Majni', 'Nchiou', 'Lortes', 'Ouled Bou Saad','Bir Saad', 'Cite Okba', 'Bou Omrane'
            ]
          },
          
          {
            name: 'Gafsa-Moulares',
            delegations: [
              'Cite Des Jeunes', 'Cite Des Ouvriers', 'Cite El Amel','Cite Essoualmia', 'Cite Kouceila', 'Cite Marocain','Cite Moderne', 'Cite Okba Ibn Nafaa', 'Cite Sprols', 'Moulares','Tabedit', 'Ouglet Ahmed', 'Sidi Boubaker', 'Moulares Gare','Cite De La Fraternite', 'Cite De La Gare', 'Cite De Le Liberte'
            ]
          },
          {
            name: 'Gafsa-Metlaoui',
            delegations: [
              'Cite Aouled Radhouane', 'Cite Centrale', 'Cite Du Laboratoire','Cite El Amel', 'Cite Ennacim', 'Cite Ennasr', 'Cite Essaada 1','Cite Essaada 2', 'Cite Essaada Ouest', 'Cite Europeen','Cite Ezzouhour', 'Cite Ezzouhour 1', 'Cite Ezzouhour 2','Cite Ezzouhour 3', 'Cite Ezzouhour 4', 'Cite Ibn Khaldoun','Cite Moderne', 'Cite Presidentielle', 'Cite Thelja', 'Metlaoui','Richet Ennaam', 'Cite El Magroun', 'El Kaina', 'Metlaoui Mines','Metlaoui Gare', 'El Mziraa', 'Metlaoui Thalja'
            ]
          },
          {
            name: 'Gafsa-Sned',
            delegations: [
              'Bahloula', 'Cite De La Gout', 'Cite Des Martyrs','Cite Ennajah', 'Cite Ennasr 1', 'Cite Ennasr 2','Cite Ennour', 'Cite Erriadh', 'Cite Ezzitouna 1','Cite Ezzitouna 2', 'Cite Ezzouhour 1', 'Cite Ezzouhour 2','Cite Taieb Mhiri', 'Dhoukara', 'El Goussa', 'Essmayria','Jebel Sned', 'Menzel Chihaoui', 'Sned', 'Majoura','Majourat Eddakhla', 'Abdessadok', 'El Biadha','Henchir El Afrah', 'Alim', 'El Araria', 'Jedida','Oum El Aleg', 'Zannouche'
            ]
          },
          
          {
            name: 'Gafsa',
            delegations: [
              'Cite Bab Ettoub', 'Cite Batiments', 'Cite Ben Doula','Cite Ben Issaoui', 'Cite Ben Slimene', 'Cite Bouranene','Cite De La Gare', 'Cite Des Cadres', 'Cite Ecole','Cite El Ajama', 'Cite El Kaouafel', 'Cite El Khil','Cite El Mancher', 'Cite Essouani', 'Cite Ragouba','El Ksar', 'Gafsa Gare', 'Ouled Chrait', 'Sbat','Gafsa Aeroport', 'Erragouba', 'Lala'
            ]
          },
          {
            name: 'Gafsa-El Ksar',
            delegations: [
              'Cite Dhebebnia 1', 'Cite Dhebebnia 2', 'Cite Du Lycee','Cite Ecole', 'Cite Logement Populaire', 'El Garia','Sidi Aich', 'Sidi Aich Est'
            ]
          },
          
          {
            name: 'Gafsa-Sud',
            delegations: [
              'Sidi Ahmed Zarrouk', 'Gafsa Cite Des Jeunes', 'Cite Bayech','Ennadhour', 'Cite Cherif', 'Kef Derbi', 'Cite Des Jeunes','Doualy Gafsa', 'Cite Des Jeunes 1', 'Cite Des Jeunes 2','Cite El Afrane', 'Cite El Kaouafel 1', 'Cite El Kaouafel 2','Cite El Kitna', 'Cite El Moula', 'Cite Ennouhoudh','Cite Garde Nationale', 'Cite Industrielle', 'El Garia','Gafsa', 'Gafsa Cite Ennour', 'Cite Essourour'
            ]
          },
          
          {
            name: 'Gafsa-Belkhir',
            delegations: [
              'Belkhir', 'Baten Zammour', 'Borj El Haffey', 'Bou Blel','Cite Ouled Bel Hassen', 'Boukou', 'Cite Ouled Ben Jeddou','Haouel El Oued', 'Cite Ouled Haj Ali', 'Jebilet El Oust','Cite Ouled Hmida', 'Ouled Zaied', 'Cite Ouled Saad','El Akteb', 'Ouled El Haj', 'El Ayaicha', 'Ezzitouna','Jebel Ayaicha'
            ]
          },
          
          {
            name: 'Gafsa-Nord',
            delegations: [
              'Echabiba', 'Ouled Zid', 'Menzel Mimoun'
            ]
          },
          {
            name: 'Gafsa-Redeyef',
            delegations: [
              'Redeyef Gare', 'Cite De La Gare', 'Cite De La Poste','Cite Du Souk', 'Cite Essouafa', 'Cite Ettahrir','Cite Hsouna Ben Tahar', 'Cite Ouled Bou Oune','Cite Ouled Majed', 'Cite Ouvriere', 'Cite Sidi Abdelkader','Cite Sprols', 'Cite Trabelsia', 'Redeyef', 'Zomra Redeyef'
            ]
          },
          {
            name: 'Gafsa-El Mdhilla',
            delegations: [
              'Cite Des Jeunes', 'Cite Ennacim', 'Cite Essaada','Cite Ouest', 'Cite Ouvriere 2', 'Cite Stade Ouvriere Est','Jebel Mdhilla', 'Borj El Mdhilla'
            ]
          },
          
          {
            name: 'Jendouba',
            delegations: [
              'El Melga', 'Mitil', 'Cite Ettataouer', 'Souk Essebt','Ain El Khararib', 'Ain El Ksir', 'Cite Administrative','Cite Ennour 1', 'Cite Ennour 2', 'Cite Snit','El Ganara', 'Jendouba', 'Cite Ezzouhour', 'Cite Cnrps','Essanabel', 'El Azima', 'Essaada'
            ]
          },
          
          {
            name: 'Jendouba-Balta Bou Aouene',
            delegations: [
              'Balta', 'Bou Aouene', 'Cite Jebil', 'Ouled Hassen'
            ]
          } ,
          {
            name: 'Jendouba-Ghardimaou',
            delegations: [
              'Ain Soltane', 'Cite Des Combattants', 'Cite Des Jardins','Cite El Mayara', 'Cite Ennouhoudh 1', 'Cite Ennouhoudh 2','Cite Ennouhoudh 3', 'Cite Ennouhoudh 4', 'Cite Ennouhoudh 5','Cite Ennouhoudh 6', 'Cite Ennouhoudh 7', 'Cite Erraja','Cite Erraoudha', 'Cite Essaada 1', 'Cite Essaada 2','Cite Ezzouhour', 'Cite Ines', 'El Biadha', 'El Feija','Esraya', 'Ezzraibia', 'Ghardimaou', 'Ouechtata','Zitounet Oum Lahneche', 'Ouerguech', 'Oued El Maaden'
            ]
          },
          
          {name: 'Jendouba-Tabarka',
            delegations : ['Brirem','El Khedhairia','Jaballah','Ain Essnoussi','Cite Chaaouania','Cite Des Arts','Cite Du Soleil','Cite Eddachra','Cite El Mourjene','Cite Erriadh','Cite Kraimia','Cite Taieb Mhiri','El Farech','Sidi Rouine','Tabarka','Ain Essobh','Bou Terfes','Cite Populaire 1','Cite Populaire 2','Cite Populaire 3','Cooperative Centrale Des Grandes','Ouled Yahya','Ras Errajel','Sidi Asker','Touajnia','Tabarka Aeroport','El Haouamdia']
          },
          
          {
            name: 'Jendouba-Ain Draham',
            delegations: [
              'Ain Draham', 'El Atatfa', 'Sidi Abdallah', 'Sidi Mhamed','Ain El Hamraya', 'El Houmrane', 'Oued Ezzene', 'Tebainia','Esseloul', 'Ouled Helal', 'Adissa', 'Hammam Bourguiba','Rouai', 'Tegma', 'Babouch'
            ]
          },
          
          {
            name: 'Jendouba-Nord',
            delegations: [
              'Souk Jemaa', 'Jerif', 'Ben Bechir', 'Cite El Ouns','Cite Ezzahoua', 'Cite Hedi Ben Hassine', 'Jendouba Nord','Bellarigia'
            ]
          },
          {
            name: 'Jendouba-Fernana',
            delegations: [
              'Argoub Rihane', 'Betaha', 'Cite Beni Mazen', 'Cite De La Rnta','Cite El Frajnia', 'Cite El Intilaka', 'Cite El Mouftarek','Cite El Mourouj', 'Cite Ennacim', 'Cite Essaada', 'Cite Essanaouber','Cite Ezzouhour', 'Cite Oum Kethir', 'Cite Sprols', 'Echtaibia','Ejouablia', 'El Alayeg', 'El Fazzen', 'El Fejouj', 'El Haouamd','El Houjjaj', 'El Magroun', 'Eroouaba', 'Essmaibia', 'Fernana','Grioua', 'Halima', 'Oued Gherib', 'Ouled Mfedda', 'Rabia','Rmila', 'Sidi Ammar', 'Sidi Said', 'Beni Mtir', 'Zaaroura','Cite Essalah', 'Bou Hertma', 'Jentoura', 'Ain Charchara','Ain El Beya', 'Bou Halleb', 'Cite El Marja', 'Jouaouda', 'Hedhil'
            ]
          },
          
          {
            name: 'Jendouba-Bou Salem',
            delegations: [
              'Beni Mhamed', 'Bou Laaba', 'Bou Salem', 'Cite 7 Novembre 1','Cite 7 Novembre 2', 'Cite 7 Novembre 3', 'Cite De La Municipalite','Cite Diamonta', 'Cite El Bhairia', 'Cite El Hafsia', 'Cite El Hana','Cite El Khalij', 'Cite Ennour', 'Cite Erriadh', 'Cite Essourour','Cite Fatouma Bourguiba', 'Cite Hached', 'Cite Hedi Khelil','Cite Hopital', 'Cite Khzama 1', 'Cite Khzama 2', 'Cite Lamine','Cite Marche Municipal', 'Cite Sprols', 'Cite Zone Industrielle','El Aouaoudha', 'Essoumrane'
            ]
          },
          
          {
            name: 'Jendouba-Oued Mliz',
            delegations: [
              'Cite El Ayachi', 'Edkhailia', 'Oued Mliz', 'Sidi Meskine', 'Hakim'
            ]
          },
          
          {
            name: 'Kairouan-Nord',
            delegations: [
              'Cite Ichbilia', 'Cite Okba', 'Cite Ouled Manaa', 'Cite Sahbi 3','Cite Sahbi 4', 'Cite Sahbi 5', 'Cite Sahnoun', 'El Moutbasta','Cite Snit', 'Dhraa Ettammar', 'Kairouan Okba', 'Sidi Dhameni','El Baten', 'Cite Ennasr', 'El Ghabette', 'Cite El Hajjam','Cite Dar El Amen', 'Cite El Baloui 1', 'Cite El Baloui 2','Cite El Baloui 3'
            ]
          },
          
          {
            name: 'Kairouan-Bou Hajla',
            delegations: [
              'Cite El Moez', 'Cite Ennasr', 'Cite Ennour', 'Cite Essaada','Cite Ezzouhour', 'Cite Nouvelle', 'Cite Okba', 'El Fateh','Ennasr', 'Ouled Achour', 'Bir Eddaoula', 'Bir Ahmed','Jehina', 'Bir Lahjar', 'Bou Hajla', 'Cite 7 Novembre','Cite De La Republique'
            ]
          },
          
          {name: 'Kairouan',
            delegations : ['Eddikhila','El Friouette','El Gatifa','El Gfey','El Khodher','Oued Nebhana','Sbikha','Dhriaat','Sidi Messaoud','Hendi Zitouna','El Alem','Dar Ben Aicha','Dar Jamiya', 'El Aouitha',  'Sisseb',  'Ain Boumourra',  'Chorfa',  'Chougafia', 'Daloussi']
          },
          
          {
            name: 'Kairouan-Chebika',
            delegations: [
              'Ben Salem', 'Gragaya', 'Hammed', 'Abida Cherguia','Chebika', 'Cite Sabrine', 'Rouissette', 'Sidi Abdallah Belhaj','Ain El Beydha', 'El Karma', 'El Aouamria', 'Ouled Khalfallah','Ouled Khelif', 'Ouled Nasser'
            ]
          },
          {
            name: 'Kairouan-Oueslatia',
            delegations: [
              'Ain Jelloula', 'Ain Mastour', 'Cite Bou Assida', 'Cite Chebbi','Cite Commerciale', 'Cite Des Ouvriers', 'Cite Du Stade', 'Cite Ejjamaa','Cite Essanaouber', 'Cite Ettahrir', 'Oued El Guessab', 'Oueslatia','Ksar Lamsa'
            ]
          },
          {
            name: 'Kairouan-Nasrallah',
            delegations: [
              'El Hamidette', 'Menzel Mhiri', 'Touila', 'Sidi Saad','El Ajabna', 'El Ksour', 'Nasrallah', 'El Kabbara','Erragouba El Hamra'
            ]
          },
          {
            name: 'Kairouan-Cherarda',
            delegations: [
              'Ouled Farjallah', 'Cheraitia', 'Cherarda', 'Cite El Mached','Cite El Koucha', 'El Aouyed', 'El Ksour'
            ]
          },
          {
            name: 'Kairouan-El Ala',
            delegations: [
              'Ain Sayada', 'Cite Essanaouber', 'Cite Nouvelle', 'Cite Ouled Ahmed','Cite Populaire', 'Cite Rezagnia', 'Cite Rurale', 'Ecole Ettoual','Ecole Jouamia', 'Ecole Trazza', 'El Ala', 'El Fjejria','Ghidhifette', 'Oued El Jabbes', 'Ouled Ali', 'Ouled Ennaguez','Ouled Mehrez', 'Sayada Nord', 'Sayada Sud', 'El Msaid', 'Messiouta'
            ]
          },
          
          {
            name: 'Kairouan-Hajeb El Ayoun',
            delegations: [
              'Ain Majouna', 'Cite 7 Novembre', 'Cite Ennasr', 'Cite Ennouhoudh','Cite Errouki', 'Cite Essaada', 'Cite Ezzayatine', 'Cite Ezzouhour','Cite Independance', 'El Ghouiba', 'El Manar', 'El Menassa','Hajeb El Ayoun', 'Hajeb El Ayoun Gare', 'Ouled Abbes', 'Ouled Ameur','Thallaja'
            ]
          },
          
          {
            name: 'Kairouan-Haffouz',
            delegations: [
              'Ain El Ghrab', 'Cherichira', 'Cite Le Mourouj', 'El Alia','El Aoudid', 'Ezzorg', 'Haffouz', 'Khit El Oued'
            ]
          },
          
          {
            name: 'Kasserine-Foussana',
            delegations: [
              'Essatour', 'Foussana', 'Ouled Boughanem', 'Ouled Mansour','Ouled Zid', 'Oum Lahouadh', 'Sahraoui', 'Bou Deryes','Khemouda', 'Ain Janan', 'Cite Bou Lila', 'Cite El Bassatine','Cite El Fateh', 'Cite El Intilaka', 'Cite Erriadh', 'El Adhira','El Brika'
            ]
          },
          {
            name: 'Kasserine-Sbeitla',
            delegations: [
              'El Gounna', 'El Hammar', 'Mghila', 'Sbeitla','Echraya', 'Errahayet', 'Zaouiet Ben Ammar', 'Oued Miou','Henchir El Assal', 'Cite Essourour', 'Cite Des Enseignants','Cite Dhraa Bou Ouaj', 'Cite Du Lycee', 'Cite Du Stade','Cite El Fateh', 'Cite El Ghabette', 'Cite El Khadhra','Cite El Oulja', 'Cite Errahba', 'Cite Essourour Echergui','Cite Essourour El Gharbi', 'Cite Ezzayatine', 'Cite Hopital','Cite Huillerie', 'Cite Industrielle', 'Cite Militaire 1','Cite Militaire 2', 'Cite Militaire 3', 'El Garaa', 'El Gharadek'
            ]
          },
          {
            name: 'Kasserine-Haidra',
            delegations: [
              'Cite El Bassatine', 'Cite El Fateh', 'Cite El Intilaka','Cite Ennasr', 'Cite Ettadhamen', 'Cite Ibn Charef','Cite Ouled Ounallah', 'El Mrira', 'Errmila', 'Essri','Gargara', 'Haidra', 'Tebaga', 'El Ajred', 'Ain Eddefla'
            ]
          }, 
          {
            name: 'Kasserine-Nord',
            delegations: [
              'Bou Laaba', 'Doughra', 'Kasserine', 'Magdoudech','Kasserine Nour', 'Cite Ouvriere'
            ]
          },
          {
            name: 'Kasserine-Thala',
            delegations: [
              'Thala Sud', 'Eddachra', 'Chafai', 'Cite Ain Ahmed','Cite Bain Maure Ben Azouz', 'Cite Ben Cherif', 'Sidi Shil','Cite Des Ouvriers', 'Cite Du Battoire', 'Cite El Borni','Cite El Mhiri', 'Zelfane', 'Cite Ennadhour', 'Cite Errahba','Cite Garde Nationale', 'Cite Khazna Jedida', 'Cite Mongi Slim','Cite Snit', 'Henchir Goumria', 'Ouled Ghida', 'Thala','Bou Lahnech'
            ]
          },
          
          
          {
            name: 'Kasserine-Sbiba',
            delegations: [
              'Cite Air Nouvelle', 'Cite Des Rouines Romaines', 'Cite Du Lycee','Cite Eddamous', 'Cite El Fateh', 'Cite El Khadhra 1','Cite El Khadhra 2', 'Cite Ennakhla', 'Cite Erriadh','Cite Essalama', 'Cite Ezzouhour', 'Dhraa', 'El Hasnaoui','Kounbitra', 'Ouljet Aguil', 'Sbiba', 'Themed', 'Ain Khemaissia','Sidi Brahim Ezzaher'
            ]
          },
          {
            name: 'Kasserine-Feriana',
            delegations: [
              'Cekhirate', 'Cite Cheab', 'Cite Du Battoire', 'Cite El Izdihar','Cite Hached', 'Cite Hardoub', 'Cite Pierre Noire', 'Feriana','Oum Ali', 'Lahouache', 'Bou Chebka', 'Garaat Ennaam', 'Thelepte'
            ]
          },
           {
            name: 'Kasserine-Mejel Bel Abbes',
            delegations: [
              'Barrouka', 'Garaat Ennadhour', 'Cite Commerciale', 'El Fekka','Cite Des Martyrs', 'Cite Du Lycee', 'Cite Eddachra 1','Cite Eddachra 2', 'Cite El Guerayria', 'Cite Nouvelle 1','Cite Nouvelle 2', 'Cite Populaire', 'Henchir Oum El Khir','Mejel Bel Abbes', 'Ouled Marzoug'
            ]
          },
          {
            name: 'Kasserine-Sud',
            delegations: [
              'Bou Zguem'
            ]
          },
          {
            name: 'Kasserine-El Ayoun',
            delegations: [
              'El Grine', 'El Ayoun'
            ]
          },
          {
            name: 'Kasserine',
            delegations: [
              'Kasse', 'Kassernie Ezzouhour'
            ]
          },
          {
            name: 'Kasserine-Ezzouhour',
            delegations: [
              'Ain Oum Jdour', 'Cite 7 Novembre', 'Cite El Intilaka','Cite Ettaamir', 'Cite Ezzouhour', 'Frej Terbah', 'Jediliane','Ain El Hmadna'
            ]
          },
          {
            name: 'Kasserine-Hassi El Frid',
            delegations: [
              'Hassi El Frid', 'El Kamour', 'Khanguet El Jazia'
            ]
          },
          
          
          
           { name: 'Kebili-Douz', 
              delegations: [
                'Ghelissia', 'Ksar Ghilene', 'Zaafrane', 'Cite Douz Chargui', 'Cite El Abadla','Cite Populaire', 'Douz Chargui', 'Cite Ben Hamroun', 'Cite El Athemna','Cite Ouled Abdallah', 'Cite Ouled Amor', 'Douz Aouina', 'El Aouina', 'Bechni','Eddorgine', 'Nouaiel', 'Cite 7 Novembre', 'Cite Commerciale', 'Cite Des Oasis','Cite El Izdihar', 'Cite El Ouroud', 'Cite Ennour', 'Cite Essaada', 'Cite Ezzouhour','Cite Ibn Khaldoun', 'Golaa', 'Cite Jelaila', 'Cite Ouled Oun', 'Douz'
              ]
            },
            { name: 'Kebili-Sud', 
              delegations: [
                'Jersine', 'Beni Mhamed', 'Cite Afh', 'Cite Du Lycee', 'Cite Militaire','El Gataya', 'El Msaid', 'Kebili', 'Mazraa Neji', 'Oum El Farth', 'Radhouane','Rahmet', 'Jemna', 'Bazma', 'Janaoura', 'Blidette', 'Barghouthia', 'Bechelli','Kelouemen'
              ]
            },
            { name: 'Kebili-El Faouar ', 
              delegations: [
                'Cite El Houda', 'Cite El Moustakbel', 'Cite Ennour', 'Cite Essalem','El Faouar', 'Gherib', 'Ghidma', 'Sabria', 'Rejime Maatoug'
              ]
            },
            { name: 'Kebili-Nord', 
              delegations: [
                'Staftimi', 'Limaguez', 'Kebili Beyez', 'El Mansoura', 'Jedida','Toura Mansoura', 'Saidane', 'Tenbib', 'Rabta', 'Tombar', 'Telmine'
              ]
            },
            { name: 'Kebili-Souk El Ahad', 
              delegations: [
                'Chouchet Negga', 'Oum Somaa', 'Negga', 'Zaouiet El Anes', 'Fatnassa','Gliaa', 'Jazira', 'Jazira El Baiida', 'Menchia', 'Souk El Ahad','Zaouiet El Harth', 'Bechri', 'Bou Abdallah'
              ]
            },
          
          
             {
                  name: 'Mahdia-Bou Merdes',
                  delegations: [
                      'Bou Helal Sud', 'Bou Merdes', 'Chouaria', 'El Hous', 'Ennaima', 'Errouadhi', 'Menzel Hamza Est', 'Menzel Hamza Ouest', 'Zerata',
                      'Bou Helal El Ali Nord', 'Cite Administrative', 'Cite Ghrissine', 'Cite Jebel', 'Cite Rurale', 'Cite Snit', 'El Aouadhbia', 'El Melahma',
                      'Errebibette', 'Kerker'
                  ]
              },
          
          
              {
                  name: 'Mahdia-Ksour Essaf',
                  delegations: [
                      'Cite Douira', 'Cite El Hajeb', 'Rechercha', 'Cite El Imarat', 'Cite El Menagua', 'Cite Populaire', 'Salakta',
                      'Cite Commerciale', 'Cite Dhamene', 'Cite El Bassatine', 'Cite El Fatimi', 'Cite El Malaji', 'Cite El Wafa', 'Cite Ezzouhour',
                      'Cite Salah Ben Ali', 'Ksour Essaf', 'Ksour Essaf Hached', 'El Ghedhabna', 'El Khmara', 'El Bradaa', 'El Hessinette', 'Ouled Salah',
                      'Sidi Assaker'
                  ]
              },
              {
              name: 'Mahdia-Souassi',
               delegations: [
                'Cite Industrielle','Cite Populaire','Essetoute','Ethouabtia','Ezzeirate','Gdarat','Jemiaat','Ouled Amor','Ouled Bou Helal','Ouled Khelifa','Ouled Moulahoum','Ouled Moulahoum Sud','Rejibet','Sidi Bou Helal','Sidi Naceur Nord','Souassi','Jemamla','El Mansoura Souassi','Ksesba','Chaara','Sidi Zid','Cite 9 Avril','Cite Afh','Cite Commerciale','Cite Ettaamir','Cite Ezzouhour','Cite Ibn Khaldoun'
              ]
            },
            {
                name: 'Mahdia-Ouled Chamakh',
                 delegations: [
                'Bou Slim','El Ajilette','El Manaa','El Mharza Est','Ouled Bouzid','Ouled Chamakh','Somra','Chehimet','Ouled Amor'
              ]
            },
            {
                name: 'Mahdia',
              delegations: [
                'Cite Nouvelle','Cite Populaire','Rejiche','Chiba','Cite Afh','Cite Bourguiba','Cite El Moez','Mahdia Republique','Cite Ettaamir','Cite Hached','Cite Taher Sfar','Mahdia','Essaad','Mahdia Essouk','Cite Des Infirmiers','Cite Ennour','Cite Sidi Messaoud','Eddikhila 3','Mahdia Hiboun','El Hekaima','Jouaouda','Merkez Ouled Dhaouadi','Zouaouine','Cite Essaada','Cite Essalem','Mahdia Ezzahra'
              ]
            },
          
              {name: 'Mahdia-Hbira', 
             delegations: [
               'Menzel Hached', 'Meharza 18', 'Agba', 'Chahda Ouest', 'Chatt Ouled Ncib', 'El Hajjara','Hbira', 'Regaiga'
             ]
            },
            {name: 'Mahdia-El Jem', 
             delegations: [
               'El Achaba', 'Tlelsa', 'Touahra', 'El Aitha', 'Ouled El Haj', 'Riadh Bou Helal','Beni Thabet', 'Cite Ibn Sina', 'Cite Nouvelle', 'El Jem', 'El Mechelette', 'Ouled Farhat','Sidi Bou Helal', 'Zeghabna'
             ]
            },
          
         
            
              {
                  name: 'Mahdia-Sidi Alouene',
                  delegations: [
                      'El Braghtia', 'Zorda', 'El Bassatine', 'Essaada', 'Merkez Ouled Haj Khelifa', 'Oued Guelat', 'Baajla',
                      'Sakiet El Khadem', 'Cite Dar El Hadad', 'Sidi Ali Ghedir', 'Sidi Alouene', 'Ennouzha', 'Ouled Aicha', 'Zelba',
                      'Zelba El Henchir', 'El Henichette', 'El Mejria', 'Oued Beja'
                  ]
              },
              {
                  name: 'Mahdia-Chorbane',
                  delegations: [
                      'Charaf', 'Chorbane', 'Cite Nouvelle', 'El Bassora', 'El Gouassem', 'El Gradha Est', 'El Gradha Ouest', 'El Khiour',
                      'Henchir Bouaziz', 'Kheradna', 'Maati Cheraf', 'Maati Henchir Bou Aziz', 'Ouled Abdennebi', 'Ouled Ahmed',
                      'Ouled Cherifa', 'Ouled El Hannachi', 'Ouled Sghaier', 'Chahda', 'Neffatia'
                  ]
              },
              {
                  name: 'Mahdia-La Chebba',
                  delegations: [
                      'Cite De La Plage', 'Ferahta', 'La Chebba', 'Merkez Chaabna'
                  ]
              },
              {
                  name: 'Mahdia-Melloulech',
                  delegations: [
                      'Beni Tourch', 'Melloulech', 'Ouled Abdallah', 'Ouled Mabrouk', 'El Houd', 'El Mansoura', 'Ouled Jaballah'
                  ]
              },
          
          
          
               {name: 'Medenine-Sud', 
             delegations: [
               'Medenine Perseverance', 'Hassi Amor', 'Amra Nouvelle', 'Beni Ghezeyel', 'Cite Cheraiha','Cite Des Abricots', 'Cite El Ahras', 'Cite Ennacim', 'Cite Erraja', 'Bou Ghrara','Cite Gammoudi Cheref', 'Cite Touta', 'El Maghraouia', 'El Amra', 'Essaadane', 'Medenine','Oued Esseder', 'Souitir', 'Tajerjimet', 'Touicha'
             ]
            },
            {name: 'Medenine-Ajim', 
             delegations: [
               'Oued Zbib', 'Mezrane', 'Ajim', 'Bou Smail', 'Chaouch', 'Cite Populaire', 'Ghandri','Houmet Ben Harzallh', 'Houmet Bou Hastine', 'Houmet Issa', 'Khenensa', 'Methania','Tlet', 'El Guebline', 'Guellala', 'Houmet El Fahmine', 'El Groo', 'Jabira'
             ]
            },
            {name: 'Medenine-Ben Guerdane', 
             delegations: [
               'Jemila', 'Ras Jedir', 'Ben Guerdane', 'Bou Hamed', 'Chareb Errajel', 'Ouersnia','Cite El Baath', 'Cite Ennour', 'Cite Essourour', 'Cite Populaire', 'El Maamrate','Neffatia', 'Oued Errabaya', 'Sayah', 'Tabai', 'Chehbania', 'Jalel'
             ]
            },
            {name: 'Medenine-Nord', 
             delegations: [
               'Medenine El Jedida', 'Cite El Khrachfa', 'Cite Ennour', 'Cite Ezzouhour', 'Cite Haddada','Cite Jouamaa', 'Cite Nouvelle', 'Cite Sidi Ezzitouni', 'Medenine El Maarifa', 'Oum Ettamar','Koutine'
             ]
            },
          
            {name: 'Medenine-Zarzis', 
             delegations: [
               'Beni Fetaiel', 'Hamadi El Guebli', 'Bou Jlida', 'Bou Teffaha', 'Cite Chokrbane','Chammakh', 'Cite Darb El Bab', 'Cite El Kriba', 'Cite Ksar Ouled Mhamed', 'Cite Populaire','Cite Ksar Ouled Said', 'Cite Zrig', 'Cite Sidi El Kebir', 'El Khriba', 'El Marathia','Kaoui El Kadir', 'Khaoui El Ghedir', 'Ksar Ezzaouia', 'Ouglet Souihel', 'Ras Edhahra','Sahbi', 'Sanchou', 'Zarzis', 'Jedaria', 'Cite Beni Fetaiel 1', 'Cite Beni Fetaiel 2','Zarzis Zone Franche', 'Cite El Mansoura', 'Cite Sangho', 'Cite Sidi Saad', 'Lella Mariem','El Mouensa', 'Ouled Abdennebi', 'Souihel', 'Hassi Jerbi', 'El Ghrabate', 'Erraja','El Hichem', 'Gribis'
             ]
            },
            {name: 'Medenine-Houmet Essouk', 
             delegations: [
               'Guechiine', 'Sedghiane', 'Cite Snit', 'Sidi Mehrez', 'Erriadh', 'Bazim', 'Houmet Larbeh','Cite Ben Issa', 'Cite Bou Okkazine', 'Cite De La Police', 'Cite Des Enseignants', 'Cite Erriadh','Cite Jouamaa', 'Cite Ouvriere 1', 'Cite Ouvriere 2', 'Cite Populaire', 'Cite Sidi El Bahri','Fatou', 'Hachene', 'Jerba', 'Mellita Jerba', 'Ghizen', 'Sarandi', 'Cite De La Douane','Cite Sidi Zaied', 'Souani', 'Mezraya', 'Sidi Zaied', 'Jerba Aeroport', 'Oualegh'
             ]
            },
          
             {name: 'Medenine-Beni Khedache ', 
             delegations: [
               'Halg Jemal', 'Beni Khedache', 'Cite Ennour', 'Cite Erriadh', 'Cite Ettahrir', 'Battouma','Cite Ezzouhour', 'Eddikhila', 'Cite Ibn Khaldoun', 'El Behira', 'Cite Sidi Mansour','Foum Ennagueb', 'Ksar El Hallouf', 'Ksar Jedid', 'Ksar El Kherachfa', 'Ksar Krikria','Ksar Jouamaa', 'Zammour', 'Ouerjijene', 'Chouamekh', 'Ksar El Jira'
             ]
            },
            {name: 'Medenine-Midoun', 
             delegations: [
               'Dar Jerba', 'Robbana', 'Cedouikeche', 'El Krachoua', 'Ouersighen', 'El May', 'Tafartast','Houmet Hazem', 'Tomogret', 'Arkou', 'Ouled Amor', 'Aghir', 'El Hadadda', 'Jamaa El Gaied','Khazroun', 'Midoun', 'Sayagh', 'Sidi Yati', 'Zone Hoteliere', 'Mahboubine', 'Beni Maaguel'
             ]
            },
            {name: 'Medenine-Sidi Makhlouf ', 
             delegations: [
               'El Bayaz', 'El Ghabbay', 'Erragouba', 'Erragouba El Gharbia', 'Jorf', 'Bedoui','Cite 9 Avril', 'El Gosba', 'El Grine', 'El Morra', 'Sidi Makhlouf'
             ]
            },
          
          
          
          
          
            {name: 'Monastir-Jemmal', 
             delegations: [
               'Cite Independance', 'Bir Taieb', 'Cite Jaouhara', 'Cite Jardins', 'Cite Operations Du Sahel','Cite Sidi Messaoud', 'Jemmal', 'Ettiayra', 'Cite El Khadhra', 'Cite Ettahrir','Zaouiet Kontech', 'Jemmal Kheireddine', 'Menzel Kamel', 'El Hedadra', 'Cite Commerciale 1','Cite Commerciale 2', 'Cite El Amen', 'Cite El Bassatine1', 'Cite El Bassatine2', 'Cite El Fateh','Cite El Intilaka', 'Cite El Izdihar', 'Cite El Manar 1', 'Cite El Manar 2', 'Cite El Wifak','Cite El Yasmine', 'Cite Ennasr', 'Cite Ennour', 'Cite Ennouzha', 'Cite Erriadh', 'Cite Essalem','Cite Ettaamir', 'Cite Ettadhamen', 'Cite Ezzahra', 'Cite Ezzouhour 1', 'Cite Ezzouhour 2'
             ]
            },
            {name: 'Monastir-Teboulba', 
             delegations: [
               'Teboulba', 'Soukrine', 'Cite Bou Drisse', 'Cite El Fadhline'
             ]
            },
            {name: 'Monastir-Moknine', 
             delegations: [
               'Sidi Bannour', 'Cite 7 Novembre', 'Cite Chahed 1', 'Cite Chahed 2', 'Cite Chahed 3','Cite Erriadh', 'Cite Gribaa', 'Cite Hmada Bacha', 'Cite Mesada', 'Cite Ras El Oued','Cite Souassi', 'Moknine', 'Moknine El Jadida', 'Menzel Fersi', 'Amirat El Fehoul','Amirat Touazra', 'Cherahil', 'Cite El Bassatine', 'Cite El Omrane', 'Cite Ennasr','Cite Ezzayatine', 'Cite Populaire', 'Cite Trabelsia', 'Cite Zraraa', 'Amirat El Hojjej'
             ]
            },
            {name: 'Monastir', 
             delegations: [
               'Cite El Bassatine', 'Cite Bir Hlou', 'Cite Cheraka', 'Cite Cnrps', 'Monastir', 'Cite El Amel','Cite El Faouz', 'Cite El Farik', 'Cite El Mzali', 'Cite Erriadh', 'Cite Essaada', 'Cite Essalem','Cite Sidi Nasser', 'Khenis', 'Cite Trabelsia', 'Monastir Republique', 'Zone Skanes','Monastir Aeroport', 'Zone Hoteliere', 'Monastir Gare'
             ]
            },
          
          {name: 'Monastir-Zeramdine', 
             delegations: [
               'Mzaougha','Mlichette','Menzel Hayet','Cite El Kods','Cite El Manar','Zeramdine'
             ]},
            {name: 'Monastir-Bekalta', 
             delegations: [
               'Baghdadi','Bekalta','Cite El Bassatine','Cite El Izdihar','Cite Nouvelle','Guealla','Ouled Hedda','Ouled Issa','Ouled Touibi','Zebid','Charaf','El Behira'
             ]},
            {name: 'Monastir-Bembla', 
             delegations: [
               'Bembla','Cite El Khadhra','Cite El Mandra','Cite Ennabka','Cite Populaire','Cite 2 Mars','Cite El Jazia','Cite Souihria','Menzel Ennour','Mazdour','Menzel Harb','Menara'
             ]},
            {name: 'Monastir-Sayada Lamta Bou ', 
             delegations: [
               'Cite Bir El Ayeb','Cite Ennadhour','Lamta','Bou Hajar','Cite Choobet Romman','Cite Nouvelle','Cite Gourraia','Cite Sidi Ammar','Sayada','Sidi Abdessalem'
             ]},
          
               {name: 'Monastir-Ksibet El Medioun', 
             delegations: [
               'Touza','Bennane','Cite Air Nouvelle','Cite El Bassatine','Cite El Intilaka','Cite 18 Janvier','Cite Commerciale','Cite De La Liberte','Cite Ennadhour 1','Cite Ennadhour 2','Cite Ennadhour 3','Cite Essakia','Cite Ezzayatine','Cite Oued Sekkal','Bou Dher','Cite Populaire','Cite Rabbah','Cite Sidi El Mediouni','Cite Snit','Cite Tarek Ibn Zied','Ksibet El Mediouni'
             ]},
            {name: 'Monastir-Ouerdanine', 
             delegations: [
               'Bou Othmane','Cite 22 Janvier','Cite 7 Novembre','Cite Abdelaziz Thaalbi','Cite Ennouhoudh','Cite Ennouzha','Cite Mohamed Ali','Ouerdanine','Menzel Khir'
             ]},
            {name: 'Monastir-Beni Hassen', 
             delegations: [
               'Ghenada','Beni Hassen','Amirat Hatem','Cite Ettahrir'
             ]},
            {name: 'Monastir-Sahline', 
             delegations: [
               'Cite Echaabia Nlle','Mootmar','Sahline','Cite Nouvelle','Sidi Ameur','Mesjed Issa'
             ]},
            {name: 'Monastir-Ksar Helal', 
             delegations: [
               'Ksar Helal Riadh','Cite Bir Ali Hellal','Cite Bir Soukra','Cite Bit Mekka','Cite Chougar','Cite Commerciale 1','Cite Commerciale 2','Cite Dar Bumbla','Cite De La Municipalite','Cite Ejjebsa','Cite El Gariaa','Cite El Maglouba','Cite Erriadh 1','Cite Erriadh 2','Cite Erriadh 3','Cite Industrielle','Cite Moueheddine','Cite Oued Kacem Et El Ourami','Cite Sanit Zaag Et Charef','Ksar Helal'
             ]},
          
             {name: 'Nabeul-El Mida', 
             delegations: [
               'Oum Dhouil Village','Oum Dhouil Zaouia','Sidi Bou Ali','Tefeloun','Ain El Ghrab','Fartouna','Cite Dhahret Ezzaouia','Cite Essoualmia','El Khelaifa','El Maisra','El Mida','Erreghine','Errouiguette','Menzel Touhami'
             ]},
            {name: 'Nabeul-Menzel Bouzelfa', 
             delegations: [
               'Damous El Hajja','Dar Joundi','Dar Nader','Dar Takelsa','Errahma','El Arima','Kalbousi','Ksirat','Menzel Bouzelfa','Oued Sidi Said','Zengou','Zenich','Bedar','Beni Ghanem','Beni Jannet','Bouchrik','Charfine','Cite Ahmed Othman','Cite Azaiez','Cite Bahroun','Cite Ben Hjel','Cite Chaafouri','Cite Cheikh','Cite Cherif','Cite Daghbagi','Cite Ennouzha','Cite Essahli','Cite Ettahrir','Cite Jemmali','Cite Laroui','Cite Municipale Barket','Cite Neguira','Cite Souihli','Cite Souissi'
             ]},
            {name: 'Nabeul-Nabeul', 
             delegations: [
               'Henchir El Haouaria','Nabeul Thameur','Residence Denene','Residence Oasis Bleu','Ain Kemicha','Cite Abdessalem El Behi','Cite Bahroun','Cite Cnrps','Cite Cote Or 1','Cite Cote Or 2','Cite Des Oranges','Cite El Bassatine','Cite El Borj 1','Cite El Borj 2','Cite Ennacim','Cite Ennajeh','Cite Ettaamir','Cite Hali','Cite Industrielle','Cite Jasmins Simpar','Cite Mohsen Limam','Cite Sidi Amor','Cite Sidi Moussa','Cite Universitaire','Henchir Gort','Mimosas Villas Et Bungalows','Nabeul','Cite El Mahrsi 1','Cite El Mahrsi 2','Cite El Mahrsi 3'
             ]},
            {name: 'Nabeul-Beni Khiar', 
             delegations: [
               'El Halfa','Somaa','Ghardaya','El Maamoura','Cite Bou Jaafar','Cite Chafrada 1','Beni Khiar','Cite Chafrada 2','Cite Erriadh','Cite De La Liberte','Diar Ben Salem','Cite Des Jeunes','Cite Du Jardin','Cite El Manar','Cite Ezzouhour','Cite Mansour','Cite Populaire 1'
             ]},
            {name: 'Nabeul-Kelibia', 
             delegations: [
               'Kelibia','Kerkouane','Sidi Madhkour','Oued El Khatef','Kelibia Charguia','Melloul','Dar Allouche','Abene','Charaf','Cite Afh','Cite Assemmer','Cite Ksiba','Cite Riadh','Dar Chichou','Azmour','Dar Dabous','Beni Ayech'
             ]},
          
             {
            name: 'Nabeul-El Haouaria',
            delegations: [
              'Bou Krim', 'Charaf', 'Cite Des Jeunes', 'Cite Des Rouines', 'Cite El Amel','Beni Khira', 'Cite El Intilaka', 'El Gorfa', 'Cite El Izdihar', 'Saheb Jebel','Cite El Khadhra', 'Cite Ennour', 'Cite Essalama', 'Cite Ezzouhour', 'Cite Sprols','Cite Touristique', 'El Haouaria', 'El Kedoua', 'Ferjoun', 'Menzel Salem','Sidi Hassoun', 'Bir El Jedey', 'El Kermania', 'Zaouiet El Mgaies', 'Sidi Daoud','Bou Hbib', 'Tazougrane', 'Zougag'
            ]
          },
          {
            name: 'Nabeul-Bou Argoub',
            delegations: [
              'Borj Hafaiedh', 'Bou Ali', 'Bou Argoub', 'Bou Rbii', 'Bousehem','Chagleb', 'Cite Cnel', 'Cite Commerciale', 'Cite De La Carriere', 'Cite El Henchir','Cite El Houani', 'Cite El Hourria', 'Cite Ezzaouia', 'Cite Jadida', 'Cite Mongi Slim','Cite Sprols', 'Douali', 'Doukhania', 'El Grabcha', 'El Kharrouba','El Machrouha', 'El Mhedhba', 'Harithine', 'Ouled Abdallah', 'Zone Industrielle','Belli', 'Borj El Grais', 'Sidi Dhaher'
            ]
          },
          {
            name: 'Nabeul-Korba',
            delegations: [
              'Cite Sidi Amor', 'Tazarka', 'Beni Aichoun', 'Beni Mechkel', 'Bou Lazhar','Bou Lidine', 'Cite Bir Ezzitoun', 'Cite Commerciale', 'Cite El Khiri', 'Cite El Majredi','Cite Ettaamir', 'Cite Jamaa Errahma', 'Cite Jerad', 'Cite Jerbi', 'Cite Sadok Jerbi','Cite Sidi Salem', 'Jebel Haddad', 'Korba', 'Korchine', 'Ksar Saad','Lathleth', 'Tebak', 'Korba Hached', 'Garaat Sassi', 'Bou Jerida','Diar El Hojjej'
            ]
          },
          {
            name: 'Nabeul-Soliman',
            delegations: [
              'Bou Charray', 'Cite Gharnata', 'Cite Hotel Des Andalous', 'Cite Nouvelle', 'Cite Sprols','Dhahri', 'Meraissa', 'El Abebsa', 'El Marja', 'Soliman', 'Korbous','Cherifate'
            ]
          },
          {
            name: 'Nabeul-Hammam El Ghez',
            delegations: [
              'Cite Jardins', 'Hammam El Ghezaz', 'Ezzahra', 'Hammam Jebli', 'Tamazrat'
            ]
          },
          {
            name: 'Nabeul-Grombalia',
            delegations: [
              'Grombalia Ezzouhour', 'Ain Tebournok', 'Jebel Trif', 'Zemnit', 'Batrou','Belli Halte', 'Beni Ayech', 'Cite 7 Novembre', 'Cite Afh', 'Cite Antar','Cite Ben Attaya', 'Cite Ben Zaied', 'Cite Cebala', 'Cite De La Police', 'Fondouk Jedid','Cite Des Juges', 'Seltene', 'Cite Dhamene', 'Cite Eddamous', 'Cite El Mandra','Khous', 'Cite El Ouerdia', 'Cite Erriadh', 'Cite Essomboula', 'Cite Ezzouhour','Cite Ibn Khaldoun', 'Cite Jardins', 'Cite Mohamed Ali', 'Cite Mongi Slim', 'Cite Snit','Cite Taieb Mhiri', 'Cite Tarek Ibn Zied', 'Cite Valenza', 'El Argoub Jedid', 'El Karmia','El Khouine', 'El Kobba El Kebira', 'El Mhedhba', 'Nianou', 'Grombalia', 'Henchir Ettouta','Khanguet Gare', 'Nouel', 'Sammach', 'Khanguet El Hojjaj', 'Turki'
            ]
          },
          
          {
            name: 'Nabeul-Menzel Temime',
            delegations: [
              'Skalba', 'Menzel Horr', 'Beni Abdelaziz', 'Chatt Ezzouhour', 'Cite Ettaamir','Cite Populaire', 'Cite Sidi Salem', 'Damous', 'El Ouediane', 'Echaibni','El Gheris', 'El Gobba', 'Garsoline', 'Kef Chami', 'Lazidine','Menzel Issa', 'Menzel Temime', 'Sidi Abdelmonem', 'Sidi Jameleddine','Rainine', 'Lebna'
            ]
          },
          {
            name: 'Nabeul-Dar Chaabane Elfe',
            delegations: [
              'Bayoub', 'Cite Barnoussa', 'Cite El Ferdaous', 'Cite Ennouzha', 'Cite Ettakaddoum','Cite Oued El Ghoula', 'Dar Chaabane Elfehri', 'El Frinine', 'Dar Chaabane Plage'
            ]
          },
          {
            name: 'Nabeul-Beni Khalled',
            delegations: [
              'Cite El Houda', 'Cite Erriadh 1', 'Cite Erriadh 2', 'Cite Jardins 1', 'Cite Jardins 2','Cite Sidi Bou Said', 'Zaouiet Jedidi', 'Bir Drassen', 'Beni Khalled','Cite Achibet Amor', 'Cite Air Nouvelle', 'Cite Alaya Yakoubi', 'Cite Ben Romdhane','Cite Chelbia Oumaya', 'Cite Daghrour', 'Cite Des Jeunes', 'Cite Du Printemps','Cite El Gaied', 'Cite Essalem', 'Cite Ettaamir', 'Cite Ezzaouch', 'Cite Ezzouhour','Cite Latrech', 'Cite Mekki', 'Cite Mokhtar Rhouma', 'Cite Sidi Bou Yahia', 'Cite Souissi'
            ]
          },
          
          {
            name: 'Nabeul-Hammamet',
            delegations: [
              'Chaabet El Mrezga', 'Chatt El Khalij', 'Cite Ennadhour', 'Cite Erriadh', 'Cite Ibn Badis','Cite Kheireddine Pacha', 'Cite Militaire', 'Cite Moussa Ibn Noussaier', 'Cite Okba Ibn Naafa','Cite Sanit Sheikh', 'Echak', 'Hammamet', 'Residence Ellissa', 'Residence Ennacim', 'Sidi Hmed','Bir Bouregba', 'Zone Hoteliere', 'Cite Bir Chaaba', 'Cite Bouslama', 'Cite Chraf', 'Cite Ecole','Cite El Bekkey', 'Cite El Fawara', 'Barraket Essahel', 'Cite El Intilaka', 'Cite Afh','Cite El Mellassine', 'Cite Chatteles', 'Cite Ennaim', 'Cite El Izdihar', 'Cite Essafsaf','Cite El Kharroub', 'Cite Garde Nationale', 'Cite El Ksour', 'Cite Harboun 1', 'Cite El Yasmine','Cite Sidi Ali', 'Cite Estah', 'Cite Snit 1', 'Cite Jawaher1', 'Cite Snit 2', 'Cite Jawaher2','Cite Merzgui', 'Cite Snit', 'Sidi Jedidi'
            ]
          },
          {
            name: 'Nabeul-Takelsa',
            delegations: [
              'Bir Mroua', 'Cite Erriadh', 'Cite Populaire', 'Douala', 'El Bekekcha', 'El Brij','El Maisra', 'Ghars Mrad', 'Henchir Chedli', 'Oued El Abid', 'Ramel', 'Rtiba','Sidi Issa', 'Takelsa'
            ]
          },
          
          
          
          {
            name: 'Sfax-El Amra',
            delegations: [
              'Iffi', 'Essaadi', 'Merkez El Khemiri', 'El Mhara', 'Oued Laachech', 'Beliana','Essalem', 'Dhraa Ben Ziad', 'El Ketatna', 'Cite 7 Novembre', 'Cite El Hmerna','Cite Ezzayatine', 'Cite Houma Charguia', 'Cite Populaire 1', 'Cite Populaire 2','Cite Populaire 3', 'El Amra', 'El Fidh'
            ]
          },
          {name: 'Sfax-Est',
            delegations : ['Cite De La Sante','Cite Dounia','Cite El Habib','Oued Chaabouni','Cite El Menzah','Merkez Chaker','Cite El Messaadine','Cite El Yasmine','Cite Essaada','Oued Rmal','Cite El Bahri','Cite El Foll','Cite El Wafa','Cite Essalem','Cite Jaouhara']
          },
           {name: 'Sfax-Sud',
            delegations : ['El Aouabed','El Aouyet','Merkez Aloui','El Hajeb','El Khazzanette','Merkez Kamoun','El Ain','Merkez Aloulou','Merkez Jilani','Cite Badrani','Cite El Mghaier','Cite Ennasr','Sidi Abbes','Cite Moez 1','Cite Moez 2','Cite Moez 4','Cite Sidi Abid','Tyna','Cite Tyna 1','Cite Tyna 2','Tyna El Jadida','Merkez Sahnoun','Merkez Ouali','Cite 2000','Merkez Chaabouni','Merkez Dammak','Merkez Kassas']
          },
           {name: 'Sfax-Ville',
            delegations : ['El Achach','Habbana 2','Caid Mhamed','Majen Eddroj','Merkez Ben Ameur','Merkez Bermaki','Merkez Bou Khedir','Merkez Guirat','Merkez Jellouli','Merkez Zouayed','Oued Laachech','Rouadhi','Sfax','Hai El Khiri','Sidi Abdelkefi','Sidi Jilani','Sidi Liteyem','Soualmia','Sfax El Jadida','Sfax Port','Ain Charfi','Ain El Fellat','Ain El Mayel','Ain Tourkia','Ennigrou','Bab El Jebli','Ain Turki','Sfax Hached','Souk El Feriani','Bir Ben Ayed','Sfax Magreb Arabe','Bir El Mellouli','Bir Tebak','Merkez Derouiche','Cite Bourguiba 2','Cite Bouzid','Cite Cnrps','Cite Commerciale','El Bousten','Cite Compensation','Cite De Le Liberte','Cite Du Jardin 1','Cite El Amel','Cite El Maaref','Cite El Mourouj','Cite Essourour','Cite Ezzitouna','Cite Joua','Cite Nouvelle Kerkouan','Merkez El Alia','Cite Siape']
          },
          
          {
            name: 'Sfax-Kerkenah',
            delegations: [
              'Ouled Bou Ali', 'Ouled Yaneg', 'El Kraten', 'Ouled Kacem', 'Ennajet', 'Chergui','Cite Ancienne Kellaline', 'Cite Bouaid Kellaline', 'Cite Cnel', 'Cite Dar Oum Dlel','Cite Douirette', 'Cite El Azezba', 'Cite El Mahjar', 'Cite Groupe 1', 'El Attaya','Cite Houmet Glace', 'Cite Groupe 2', 'Cite Nouvelle Kellaline', 'Mellita', 'Cite Ourand','Cite Populaire', 'Cite Rakhani', 'El Abbassia', 'Jouaber', 'Kellabine', 'Kerkenah','Mellita Ouled Bou Ali'
            ]
          },
          {
            name: 'Sfax-Menzel Chaker',
            delegations: [
              'Cite Habib Bourguiba', 'Cite Habib Thameur', 'Cite Merkez Sellami', 'Cite Taieb Mhiri','El Aitha', 'Hai Kacem', 'Haj Kacem 1', 'Haj Kacem 2', 'Ksar Errih', 'Limaya','Menzel Hedi Chaker', 'Oued Lazrag', 'Tlil El Ajla', 'Zaibet', 'Bou Jarbou','Bou Thadi', 'Bechka', 'Chaaleb', 'Cite 7 Novembre', 'Cite De La Gare', 'Cite El Habib','Cite Erraoudha', 'Cite Ettahrir'
            ]
          },
          {
            name: 'Sfax-Tajerouine',
            delegations: [
              'Gargour', 'Merkez Sghar', 'Jeberna', 'Agareb', 'Ben Sahloun', 'Cite 2 Mars','Cite 2 Mars 1', 'Cite 2 Mars 2', 'Cite Cnel', 'Cite El Izdihar', 'Cite El Menzah','Cite Ennour', 'Cite Snit', 'El Mahrouga', 'El Maraania', 'El Msadga', 'Merkez Attia','Merkez Bou Ledhieb', 'Merkez Jouaouda', 'Merkez Tijani', 'Traka', 'Zeliana'
            ]
          },
          {
            name: 'Sfax-Bir Ali Ben Khelifa',
            delegations: [
              'Bir Ali Ben Khelifa', 'Bir Ouled Mahmoud', 'Cheraiet', 'Cite Des Roses 1','Cite Des Roses 2', 'Cite El Fateh', 'Cite El Menzel', 'El Aouyed', 'El Oudrane','Letaifa', 'Merkez El Hamrouni', 'Oued Rekham', 'Ras Fartout', 'Ras Souinia', 'Rebaya','Sidi Dhaher', 'Sidi Mansour', 'Souinia', 'Toulb', 'El Guendoul', 'Oum Choucha','Rebaya Sidi Dhaher'
            ]
          },
          
          {
            name: 'Sfax-Esskhira',
            delegations: [
              'Sbih', 'Bou Said', 'Cherafra', 'El Gherairia', 'El Hicha', 'El Maghdhia','El Melha', 'Esskhira', 'Fondouk Chibani', 'Frichette', 'Jerouala', 'Kenitra','Naoual', 'Ouled Haj Moussa', 'Sidi Mhamed Nouiguez', 'Souani'
            ]
          },
          
          {
            name: 'Sfax-El Hencha',
            delegations: [
              'Sidi Hassen', 'Bir Chaaba', 'Cite Algerie', 'Cite Cherif', 'Cite Commerciale 1','Cite Commerciale 2', 'Cite El Fateh', 'Cite El Fheil', 'Cite El Hancha Est','Cite El Hancha Ouest', 'Cite El Hancha Sud', 'Cite El Ifa', 'Cite El Kouifla','Cite El Ojna', 'Cite Environnement', 'Bir Lahmar', 'Cite Ouled Aicha', 'Cite Populaire','Cite Oum Salah', 'Doukhane', 'El Ghieb', 'El Ghraba', 'El Guetatsa', 'El Khecherma','El Hachana', 'Esseghara', 'El Hajjara', 'Henchir El Joub', 'El Helalfa','Merkez Choucha', 'El Hencha', 'Sidi Mhamed Ben Amor', 'Henchir El Mosbah','Soualmia', 'Jalaila', 'Ouled Amor', 'Ouled Tahar', 'Bir Salah'
            ]
          },
          {
            name: 'Sfax-Sakiet Eddaier',
            delegations: [
              'Cite El Afrane', 'Merkez Sebai', 'Cite El Mallek', 'Essaadi', 'Essaltania','Cite Ennadhour', 'Cite Erriadh', 'Cite Nouvelle', 'Sidi Mansour', 'El Khalij','Cite De Le Liberte', 'Cite El Amel', 'Merkez Lajmi', 'Cite El Haffara','Merkez Kaaniche', 'Sakiet Eddaier', 'Cite Bourguiba'
            ]
          },
          {
            name: 'Sfax-Jebeniana',
            delegations: [
              'Douar Louata', 'Ouled Bousmir', 'Ain El Ghezal', 'Blitech', 'Cite El Bassatine','Cite El Fateh', 'Cite Jardins', 'El Botria', 'El Gherasa', 'El Guelalja','Henchir El Euch', 'Jebeniana', 'Ouled Ahmed', 'Ouled Msallem Edkhal', 'Ouled Youssef','Aguegcha', 'Sidi Msarra', 'Hazeg', 'Ouled Mnasser', 'Ajenga', 'El Louza'
            ]
          },
          {
            name: 'Sfax-Sakiet Ezzit',
            delegations: [
              'Sakiet Ezzit', 'Cite 20 Mars', 'Cite Ennasr', 'Cite Ennouzha', 'El Mnasria','Merkez Chihya', 'Sidi Salah', 'Merkez Bouacida'
            ]
          },
          
          {
            name: 'Sfax-Mahras',
            delegations: [
              'Belhouchette', 'Chaal Gare', 'Chaffar', 'Cite Bourguiba', 'Cite El Bouhali','Cite El Guidira', 'Cite Ennajah', 'Cite Ennasr', 'Cite Ennour', 'Cite Flemech','Cite Huillerie', 'El Hachichinia', 'El Mouessette', 'Mahras', 'Smara', 'El Mourouj','Nekta'
            ]
          },
          
          {
            name: 'Sfax-Ghraiba',
            delegations: [
              'Belhouichette', 'Chaal', 'Ettakaddoum', 'Rabta', 'Skaina', 'Smara', 'Tarfaoui','El Hammam', 'Essoudane', 'Ghraiba'
            ]
          },
          
          
            {
            name: 'Sidi Bouzid-Ouest',
            delegations: [
                'Oum Ettouboul','El Hawajbia','Cite El Kaouafel','Essadaguia','Essandoug','Melikette','Ouithet Gazal','Sidi Bouzid','Sidi Salem','Touila','Zaafria','Bir El Bey','El Adhla','El Ghemamria','El Guetayfia','El Mlikette','Oued El Brij','Oum El Adham','Cite Ouled Bel Hedi','Ettouansia','Mefreg El Frayou','Bir Badra','Eddagher','El Fateh','El Hichria','Bir Bouzid','Henchir Esnab','Cite El Brahmia','Ouled Jalal','Cite El Fraijia'
              ]
            },
            {
            name: 'Sidi Bouzid-Regueb',
            delegations: [
                'Bir Gzaiel','El Ghaba Essouda','Bir Khelifa','Essfaya','Bir Nsib','Ouled El Hani','Bou Allegue','Saida','Bou Dinar','Cite De La Republique','Cite El Bassatine','Cite El Intilaka','Cite El Khadhra','Cite El Manar','Cite Ennasr','Cite Ennouzha','Cite Erriadh','Cite Ezzouhour','Cite Ibn Khaldoun','El Gouadria','El Houda','El Mhafdhia','Essakba','Ezzadam','Ezzitouna','Farch Guerib','Guenifida','Henchir Khdam','Ksar El Hammam','Ksar Sai','Matlag','Ouled Abdallah','Ouled Ayouni','Ouled Chabou','Regueb','Rihana Nord','Rihana Sud','Sabra','Sarayria','Sidi Ameur','Goubrar','El Khechem','Fatnassa','Erradhaa','Bir El Akerma','Bir Charef','Bou Chiha'
              ]
            },
          
              {
            name: 'Sidi Bouzid-Ouled Haffouz',
            delegations: [
                'Sidi Khlif','El Abbadette','Dhouibette','Boutique Issa','Chouachnia','Cite Ali Ben Hamid','Cite Commerciale','Cite El Maarifa','Cite El Omrane','Cite Erriadh','Cite Ezzayatine','Dhouibette 2','El Araoua','El Gouaibia','El Kharwaa','El Mbarkia','Ennajah','Erranzez','Hania 102','Horbit','Khebina','Ouled Haffouz','Sidi Ellafi 1','Sidi Ellafi 2'
              ]
            },
            {
            name: 'Sidi Bouzid-Est',
            delegations: [
                'El Haouamed','Remaidia','El Makarem','Ouled Farhan','Ain Bouzer','Ain Rabbaou','Bazid','El Ogla','Fayedh','Gatrana','El Hmaima','Hania Bazid','Graa Bennour','Jemel','Lessouda','Ouled Boudhiaf','Ouled Youssef','Cite Agricole','Dhayaa','Dhraa','Ezzitoune','Garet Hadid'
              ]
            },
            {
            name: 'Sidi Bouzid-Menzel Bouzaiene',
            delegations: [
                'El Guellel','Bir El Araria','Dakhlet Haddej','El Aatizez','Khorchef','El Garaa','El Maadher','El Maloussi','El Mhamdia','El Omrane','Essaada','Menzel Bouzaiene','Menzel Saada','Ouled Zouid','Rbeayia'
              ]
            },
          
            {
            name: 'Sidi Bouzid-Maknassy',
            delegations: [
                'Cite Des Abricots','Cite Des Martyrs','Cite El Fateh 1','Cite El Fateh 2','Cite El Fateh 3','Cite El Intilaka','Cite Ennajah 1','Cite Ennajah 2','Cite Ezzayatine','Cite Militaire','Cite Mohamed Ali','Cite Taieb Mhiri','Cite Unite Ennajah','Dakhlet El Alenda','El Ayoun','El Houayez','Ezzouaraa','Jabbes','Ksar Khelifa Zenati','Maknassy','Marche Municipal','Touahria 2','Borj El Karma','Mabrouka','Ennasr','El Meche'
              ]
            },
            {
            name: 'Sidi Bouzid-Ben Oun',
            delegations: [
                'Baten El Agaag','Ben Bechir','Ben Oun','Ben Oun Nord','Bir Idriss','Cite El Ilm','Cite Ennasr','Cite Ennour','Cite Erriadh','Cite Ettahrir','Cite Ezzouhour','Damdoum','El Graoua','El Khalij','El Ouaara','El Ouneysia','Markez Ben Bechir','Ouled Brahim','Ouled Neji','El Fej','El Gouedria','Erragba','Ouled El Achi','Ouled Mnasser'
              ]
            },
            {
            name: 'Sidi Bouzid-Jilma',
            delegations: [
                'Ain Jaffel','Cite Chargui','Cite Ennasr','Cite Ouled Zaied','Cite Populaire','El Fenideg','El Haouia','Guellel','Jilma','Labayedh','Sed El Yahoudi','Selta','Sidi Ali Ebn Jaballah','Zoghmar'
              ]
            },
            {
            name: 'Sidi Bouzid-Souk Jedid',
            delegations: [
                'Bou Attouch','Cegdel','Douadnia','El Azara','El Hessinette','El Kessayra','Bou Chmel','El Mesatria','El Bekakria','El Msabhia','El Hachana','Ennouhoudh','Gouleb','Essadlia','Ouled Dhaher','Ouled Alaya','Remila','Ouled Ammar','Zefzef','Ouled Amor','Ouled Khedher','Ouled Mhamed','Souk Jedid'
              ]
            },
          
            {
            name: 'Sidi Bouzid-Mezzouna',
            delegations: [
                'Cite De La Poste','Cite Des Martyrs','Cite El Amel','Cite Independance','El Gheris','El Guezgazia','El Khima','El Khobna','El Khoui','Ferjen','Ghedir Rebaia','Haddej','Mezzouna','Oued Eddam','Ouled Dlala','El Founi','El Bouaa'
              ]
            },
            {
            name: 'Sidi Bouzid-Cebbala',
            delegations: [
                'Cebbala','Charaa','Cite El Fateh 1','Cite El Fateh 2','Cite Ennajah','Cite Ennouzha','El Ghemamria'
              ]
            },
            {
            name: 'Sidi Bouzid-Bir El Haffey',
            delegations: [
                'Bir Amema','Bir Bousbi','Bir El Haffey','Chakhar','Eddachra','El Hanancha','El Mhamdia','Henchir Haffouz','Ouled Khelifa','Houch Ben Necib','Ourgha','Ksar El Mzara','Ouled Bouaziz','Rahal','El Adhla','Ennouaiel','Essalama'
              ]
            },
          
            {
            name: 'Siliana-Bou Arada',
            delegations: [
                'Cite Erriadh 2','Cite Erriadh 3','Cite Essaada','Cite Essafa','Cite Essalem','Cite Ezzayatine Est','Cite Ezzayatine Ouest','El Fetisse','El Mejenine','Henchir Roumene','Jelidi Gare','Khamsa Thenaya','Ksar Boukhris','Sidi Abdennour','Ain El Ghessil','Baten Zraieb','Bou Arada','Cite Ali Ben Mbarek 1','Cite Ali Ben Mbarek 2','Cite Ali Ben Mbarek 3','Cite El Bassatine','Cite El Mellassine','Cite Ennakhil','Cite Ennour 1','Cite Ennour 2','Cite Erriadh 1'
              ]
            },
            {
            name: 'Siliana-Kesra',
            delegations: [
                'Cite El Bassatine','Cite El Intilaka','El Garia Sud','Ezzaouia','Hammam Kesra','Kesra','Bou Abdallah','Cite Beni Sayour','Cite El Bhira','Cite El Ghrob','Cite El Ksar','Kesra Superieur','Beni Abdallah','El Mansoura','El Mansoura Nord','El Garia','Cite 20 Mars'
              ]
            },
          
           
            {
            name: 'Siliana-Makthar',
            delegations: [
                'Houch Sfaya','Khizrane','Makthar','Ras El Oued','Souk Jomaa','Zouakra','Sned Haddad','El Khalsa','Beni Hazam','Chouarnia','Cite Debbiche','Cite El Bassatine','Cite El Ouns 1','Cite El Ouns 2','Cite Ennahala','Cite Essiada','Cite Ksiba','Cite Mongi Slim 1','Cite Mongi Slim 2','Cite Nouvelle','El Garaa'
              ]
            },
            {
            name: 'Siliana-Bargou',
            delegations: [
                'Bou Saadia','Ain El Fourna','Ain Zakkar','Bargou','Cite Aboul Kacem Chebbi','Cite Air Nouvelle','Cite El Kadim','Cite Essaada','Cite Ezzouhour','Cite Ibn Khaldoun','Drija','Marj Aouam','Sidi Amara','Sidi Said','Sidi Zid','El Bhirine','Sodga'
              ]
            },
            {
            name: 'Siliana-El Aroussa',
            delegations: [
                'El Aroussa','El Hajjej','Mosrata','Oued El Araar','Bou Jlida'
              ]
            },
            {
            name: 'Siliana-Nord',
            delegations: [
                'Cite Cnrps','Cite El Bassatine','Cite El Yasmine','Cite Ennour 1','Cite Ennour 2','Cite Ennouzha','Cite Ezzouhour','Cite Miskia','Cite Office Cereale','Cite Taieb Mhiri','Jama','Sidi Jabeur','Cite Essalah','Cite Essalah 2'
              ]
            },
            {
            name: 'Siliana-Le Krib',
            delegations: [
                'Cite Abdrabbah 1','Cite Abdrabbah 2','Cite Commerciale','Cite De La Cellule','Cite Du Battoire','Cite Ejjamaa','Cite Ennasr','Cite Essanaouber','Cite Ettahrir','Cite Independance','Fej El Hadoum','Le Krib','Merkez Ben Njah','Oued Tessa El Morr','Ouled Bouzid','Doukhania','Borj Messaoudi','Hammam Biadha','Souk El Jomaa'
              ]
            },
            {
            name: 'Siliana-Sud',
            delegations: [
                'Essafina','Douar Ezzriba','El Ghemalia','Ghars Ezzriba','Ras El Ma','Sidi Ameur','Sidi Morched','Ain Dissa','Cite De La Republique','Cite Du Lycee','Cite El Aida','Cite Erriadh 1','Cite Erriadh 2','Cite Hached','Cite Mongi Slim','El Gabel','El Megarba','Ain El Jouza','Fidh Arous','El Kantra','Gabr El Ghoul','Zaouiet Sidi Abdelmalek','Ksar Hadid','El Ganara','Sidi Mansour','Sidi Hamada','Siliana'
              ]
            },
            {
            name: 'Siliana-Rohia',
            delegations: [
                'Cite El Ouns 1','Cite El Ouns 2','Cite Ennour','Cite Erriadh','Cite Essaada','Cite Ettahrir','Cite Hedi Khfacha','El Haria','El Hmaima','El Msahla','Fidh Hammed','Foundouk Debbich','Jemilette','Rohia','39100','Hebabsa Sud','Magrouna'
              ]
            },
            {
            name: 'Siliana-Gaafour',
            delegations: [
                'Bennouria','El Abarguia','El Aksab','El Amel','Ennajah','Cite Du Battoire','El Frachich','Cite Du Lycee','Sidi Ayed','Cite El Guebli','Cite El Wafa','Cite Ezzouhour 1','Cite Ezzouhour 2','Cite Ezzouhour 3','Cite Touensa','El Kharrouba','Ain Zrig','Gaafour','El Barrama','Gaafour Chergui','El Haouam','Gaafour Gharbi','Mediouna','Nouisser','Akhouat Mine','El Akhouat','Mellita'
              ]
            },
          
           {
            name: 'Siliana-Sidi Bou Rouis',
             delegations: [
              'Bou Rouis Filahi', 'Ouled Selit', 'Krib Gare', 'Ain Achour', 'Bou Rouis', 'Cite 7 Novembre', 'Cite Cheminots', 'Cite Du Battoire', 'Cite Du Lycee', 'Cite Ejjamaa', 'Nechem'
            ]
          },
          {
            name: 'Sousse-Hammam Sousse',
            delegations: [
              'Cite De La Plage 2', 'Cite Ennarjes 1', 'Cite Ezzitouna 1', 'Cite Ennarjes 2', 'Cite Ezzitouna 2', 'Cite Jaouhara', 'Cite Presidentielle', 'Hammam Sousse Gharbi', 'El Kantaoui', 'Hammam Sousse', 'Cite De La Plage 1'
            ]
          },
          {
            name: 'Sousse-Sousse Riadh',
            delegations: [
              'Sousse Ezzouhour', 'Cite 7 Novembre', 'Cite 7 Novembre 3', 'Cite Sinan Pacha', 'Ksibet Sousse', 'Thrayette', 'Sousse Riadh', 'Zaouiet Sousse'
            ]
          },
          {
            name: 'Sousse-Bou Ficha',
            delegations: [
              'Sidi Khelifa', 'Ain Errahma', 'Bou Ficha', 'Cite 20 Mars', 'Cite Erriadh', 'Cite Ettahrir', 'Cite Ezzouhour', 'Cite Hached', 'Cite Militaire', 'Cite Snit', 'Essalloum', 'Lenderia', 'Oued El Kharroub', 'Sidi Mtir', 'Sidi Said'
            ]
          },
          {
            name: 'Sousse-Enfidha',
            delegations: [
              'Menzel Bel Ouaer', 'Chegarnia', 'Ain Garci', 'Ain Medheker', 'Cite 7 Novembre', 'Cite Cimenterie', 'Cite El Bidhane', 'Cite El Yasmine', 'Cite Environnement', 'Cite Erriadh', 'Cite Essalem', 'Cite Ettahrir', 'Cite Ezzayatine', 'Cite Hached', 'Cite Mohamed Ali', 'Dhehaibia', 'El Frada', 'El Ghoualif', 'El Ghouilet', 'Enfidha', 'Essafha', 'Essmaidia', 'Grimit Est', 'Grimit Ouest', 'Hicher', 'Menzel Fateh', 'Methalithe', 'Mrabet Hached', 'Ouled Abdallah', 'Ouled Bellil', 'Ouled Mohamed', 'Sidi Saidane', 'Takrouna'
            ]
          },
          {
            name: 'Sousse-Msaken',
            delegations: [
              'Moureddine', 'Msaken El Gueblia', 'Msaken Hai Jedid', 'Messadine', 'Chihia', 'El Knaies', 'Beni Rabia', 'Cite Cimetiere', 'Cite Dar Esseghair', 'Cite Ecole Primaire', 'Cite El Mansour', 'Cite Hedi Gachacha', 'Cite Rached El Ouertani', 'Cite Slama', 'El Borjine', 'El Frada', 'Msaken', 'Beni Kalthoum'
            ]
          },
          {
            name: 'Sousse-Kondar',
            delegations: [
              'Bir Jedid', 'Blelma', 'El Bechachma', 'Kondar', 'Ouled Ameur', 'Ouled El Abed', 'Tarhouna'
            ]
          },
          {
            name: 'Sousse-Sidi Bou Ali',
            delegations: [
              'Cite El Bassatine', 'Cite Ibn Khaldoun', 'El Aitha', 'El Alouj', 'El Araydhia', 'El Mhedhba', 'Essad Sud', 'Kenana', 'Ouriemma', 'Sidi Bou Ali', 'Menzel Gare', 'Essad Nord'
            ]
          },
          {
            name: 'Sousse-Kalaa Essghira',
            delegations: [
              'Cite Chabbett Ezzaouia', 'Cite Chragui', 'Cite El Manezeh', 'Cite Khazzen El Ma', 'Ennagr', 'Kalaa Essghira', 'Oued Laya'
            ]
          },
          {
            name: 'Sousse-Akouda',
            delegations: [
              'Akouda', 'Chatt Meriem', 'Cite Du Dispensaire', 'Cite Ecole Superieure', 'Cite Ejjamaa', 'Halg El Menjel', 'Tantana'
            ]
          },
          {
            name: 'Sousse-Ville',
            delegations: [
              'Cite Boukhzar', 'Cite Cheminots', 'Cite Cnrps', 'Cite Garde Nationale', 'Cite Hached', 'Cite Hadrumette', 'Cite Jaouhara', 'Cite Khedher', 'Cite Militaire', 'Cite Rizzi', 'Cite Sprols', 'Sousse', 'Sousse Corniche'
            ]
          },
          {
            name: 'Sousse-Sidi El Heni',
            delegations: [
              'El Mouissette', 'Ghabghoub', 'Ouled Ali B Hani', 'Ouled Alouene', 'Ouled Amor', 'Ouled Boubaker', 'Ouled El Kbaier', 'Ouled El Khechine', 'Sidi El Heni', 'Zerdoub', 'Cherachir', 'Kroussia'
            ]
          },
          {
            name: 'Sousse-Jaouhara',
            delegations: [
              'Cite De La Steg', 'Cite Sidi Daher', 'Sousse Khezama', 'Cite Sahloul', 'Sahloul', 'Cite Aeroport', 'Cite Batiments', 'Cite Ben Aleya', 'Cite El Ghoudrane', 'Cite Sidi Abdelhamid', 'Sousse Ibn Khaldoun', 'Khezama Ouest'
            ]
          },
          {
            name: 'Sousse-Kalaa El Kebira',
            delegations: [
              'Ahel Jemiaa', 'Belaoum', 'Cite Bir Chemsi', 'Cite Bir Hlaoua', 'Cite Chargui', 'Cite Commerciale', 'Cite El Bassatine', 'Cite El Intilaka', 'Cite El Jebs', 'Cite El Mansoura', 'Cite Ennouzha 1', 'Cite Ennouzha 2', 'Cite Fadden Aoun', 'Cite Nouvelle', 'Cite Sidi Zaied', 'Cite Zone Des Metiers', 'El Kraria', 'Kalaa El Kebira', 'Ouled Lesseoud', 'Kalaa El Kebira Ksar', 'Chiab'
            ]
          },
          {
            name: 'Sousse-Hergla',
            delegations: [
              'El Aribat', 'Hergla', 'Souayeh'
            ]
          },
          
          {
            name: 'Tataouine-Ghomrassen',
            delegations: [
              'Cite Des Martyrs', 'Cite Des Oasis', 'Cite Du Stade', 'Cite El Ain', 'Cite El Anouar', 'Cite El Bicher', 'Cite El Maaraka', 'Cite Ennakhil', 'Cite Ennasr', 'Ksar El Hedada', 'Cite Ettahrir 1', 'Cite Ettahrir 2', 'Cite Founesse', 'Cite Ibn Arafa', 'Guermessa', 'Cite Mouzdelifa', 'Cite Nouvelle', 'Cite Romana', 'Cite Rosfa', 'Cite Sifri', 'Cite Taousse', 'Cite Tataouine', 'El Hencha', 'Ghomrassen', 'Mdhilla', 'El Horria', 'Oued El Khil', 'El Farech', 'Ksar El Mourabtine', 'Cite Bakhtit', 'Cite Bir Karma', 'Cite Commerciale'
            ]
          },
          {
            name: 'Tataouine-Dhehiba',
            delegations: [
              'Cite El Amel', 'Cite Ettadhamen', 'Cite Ettadhamen 1', 'Cite Haftali', 'Cite Ksar Guedim', 'Cite Olympique', 'Dhehiba', 'Oum Zoggar', 'Ouni', 'Cite 7 Novembre', 'Cite Du Bain Maure', 'Cite El Aidi'
            ]
          },
          {
            name: 'Tataouine-Smar',
            delegations: [
              'Beni Mehira', 'El Maouna', 'Cite Caserne', 'Cite Du Stade', 'El Ghariani', 'El Guetaya', 'El Morra', 'Gualeb Errakham', 'Guearaat Helal', 'Ksar El Gaa', 'Oudiete Abdelounis', 'Ras El Oued', 'Rehach', 'Sabeg', 'Smar', 'Kirchaou', 'Ksar Oun'
            ]
          },
          {
            name: 'Tataouine-Nord',
            delegations: [
              'Tataouine 7 Novembre', 'Ezzahra Tataouine', 'Ksar Ouled Soltan', 'Cite Abbes', 'Cite El Mahrajene', 'Cite Ennour', 'Cite Ennouzha', 'Khetma', 'Ksar Bhir', 'Ksar El Galaa', 'Remtha', 'Tamazout', 'Tamelest', 'Gattoufa', 'Oued El Ghar', 'Zmilet Saber', 'Tlelet', 'El Hachana', 'Essaada'
            ]
          },{
            name: 'Tataouine-Sud',
            delegations: [
              'Ras El Oued', 'Chenini Nouvelle', 'Bir Thlathine', 'Rogba', 'Ksar Mguebla', 'Chenini', 'Cite Commerciale', 'Cite Des Roses', 'Cite Du President', 'Cite El Hedadda', 'Cite Erriadh', 'Cite Nouvelle', 'Cite Senegal', 'Ghorghar', 'Sedra', 'Tataouine', 'Douiret', 'Tounket', 'Ksar Debab', 'Beni Barka', 'Maztouria'
            ]
          },
          {
            name: 'Tataouine-Remada',
            delegations: [
              'Nekrif', 'Borj Bourguiba', 'Borj El Khadra', 'El Maghni', 'El Morra', 'El Ouchouch', 'Oum Souigh', 'Remada', 'Bir Amir', 'Kambout'
            ]
          },
          {
            name: 'Tataouine-Bir Lahmar',
            delegations: [
              'Bir Lahmar', 'Cite El Bassatine', 'Cite El Grar', 'Cite El Menzah', 'Cite Esned', 'El Argoub', 'Gragar', 'Habhab', 'Ksar Ouled Boubaker', 'Oued El Arfej', 'Ouled Yahya'
            ]
          },
          
          {
            name: 'Tozeur-Tameghza',
            delegations: [
              'Tameghza', 'Chebika Du Jerid', 'Cite Bou Chagra', 'Cite El Bassatine', 'Cite El Frada', 'Dhafria', 'Ain El Karma', 'Cite Administrative', 'Cite El Amel', 'El Frid', 'Logement Populaire', 'Mides', 'Remitha', 'Sondes'
            ]
          },
          {
            name: 'Tozeur',
            delegations: [
              'Tozeur Aeroport', 'Abbes', 'Bled El Hadhar', 'Bou Lifa', 'Essoualmia', 'Jahim', 'Chetaoua Sahraoui', 'Cite El Izdihar', 'Ras Dhraa', 'Chabbia', 'Chorfa', 'Cite Commerciale', 'Tozeur', 'Cite Afh', 'Cite Cnrps', 'Cite De La Gare', 'Cite El Guitna', 'Cite El Mahrajene', 'Cite Helba', 'Cite Hopital', 'Cite Maison Populaire', 'Cite Route De Degueche', 'Cite Route El Hajij', 'Cite Route El Hamma', 'Cite Route Ennafliette', 'Cite Sidi El Hafnaoui', 'Cite Tebebsa', 'Tozeur Chokratsi', 'Zone Touristique'
            ]
          },
          {
            name: 'Tozeur-Degueche',
            delegations: [
              'Chakmou', 'El Hamma Du Jerid', 'El Mahassen', 'Cite De La Gare', 'Cite Du Stade', 'Cite El Bassatine', 'Cite El Hasnaoui', 'Cite Logement Presidentiel', 'Cite Nouveau Stade', 'Cite Ouled El Gharbi', 'Cite Ouled Hmida', 'Degueche', 'Ouled Majed', 'Sabaa Obbat', 'Zaouiet El Arab', 'Dghoumes', 'Bou Helal', 'Ceddada'
            ]
          },
          {
            name: 'Tozeur-Hezoua',
            delegations: ['Hezoua']
          },
          {
            name: 'Tozeur-Nefta',
            delegations: [
              'Cite Erriadh', 'Cite Khzama', 'Cite Nouvelle', 'Nefta', 'Ben Farjallah'
            ]
          },
          
          {
            name: 'Ariana-Sidi Thabet',
            delegations: [
              'Cebalet Ben Ammar', 'Chorfech 12', 'Cite Dridi', 'Cite El Bokri', 'Cite El Frachich', 'Cite El Ghribi', 'Cite El Methalithe', 'Cite Messouda', 'Cite Ouvriere', 'Cite Sidi Marouane', 'Ezzaouia', 'Bejaoua 2', 'Jabbes', 'Cite 18 Janvier', 'Jabbes El Borj', 'Cite Afh', 'Cite Des Agriculteurs', 'Cite Des Oranges', 'Cite El Gouazine', 'Cite El Mbarka', 'Cite El Mrezgua', 'Cite Essaada', 'Cite Ettbaieb', 'Cite Jardins', 'Cite Mongi Slim', 'Sidi Thabet', 'Chorfech', 'Chorfech 2', 'Chorfech 24', 'Chorfech 8', 'Borj El Khoukha', 'Borj Youssef'
            ]
          },
          {
            name: 'Ariana-La Soukra',
            delegations: [
              'Cite Ezzouaidia', 'Cite Farhat', 'Cite Fateh', 'Cite Fonciere', 'Cite Snit', 'Cite Touilia', 'Dar Fadhal', 'La Soukra', 'Mosque Erraoudha', 'Residence 26-26', 'Residence Ahlem', 'Residence Meriem', 'Sidi Frej', 'Sidi Salah', 'Sidi Soufiene', 'Village Essahli', 'Cite Ben Kilani', 'Cite De La Sante', 'Aeroport Tunis Carthage', 'Cite El Behi Ladghem', 'Cite El Feth', 'Cite El Hana', 'Cite El Mansoura', 'Cite El Ouroud', 'Chotrana 1', 'Chotrana 2', 'Cite Ennouzha 3', 'Chotrana 3', 'Cite Astree', 'Cite Ghouzaila', 'Cite Belabla', 'Cite Ben Hessine', 'Cite Jebira', 'Cite Bou Fares', 'Cite Chouachia', 'Cite Star', 'Cite Chouchene', 'Residence El Ouns', 'Residence Ennesrine', 'Cite De La Terre Libre', 'Cite El Azzef', 'Cite El Boustene 1', 'Cite El Boustene 2', 'Cite El Henaya', 'Cite El Maid', 'Cite Elyes', 'Cite Ettaamir', 'Cite Ezzitoun 1', 'Cite Ezzitoun 2'
            ]
          },
          {
            name: 'Ariana-Raoued',
            delegations: [
              'Cite El Mouaouiet', 'Cite El Mountazeh', 'Complexe Technologique', 'Cite Ennour Jaafar', 'Cite Erriadh', 'Cite Essaada', 'Cite Sidi Slimene', 'Cite Snit', 'Douar El Hendi', 'El Hessiene', 'Oued El Khayat', 'Oued El Makhzen', 'Residence El Ayech', 'Residence El Ouns', 'Residence Ennarjes', 'Residence Essalem', 'Residence Rim', 'Route Ezzaouia', 'Ariana Essoughra', 'Borj Touil', 'Cite Jaafar', 'Bou Hnech', 'Cite Chaker', 'Cite De La Mosque', 'Cite 7 Novembre', 'Cite Aeroport', 'Cite Chargui', 'Cite Des Juges', 'Cite El Yamama', 'Cite Sidi Salah', 'Cite Zghab', 'El Brarja', 'Cite De La Rtt 2', 'Raoued', 'Cite El Abadla', 'Cite El Amel', 'Cite El Ghazala 1', 'Cite El Ghazala 2', 'Cite El Wafa', 'Cite Ennasr', 'Cite Ennkhilet', 'Cite Essahafa', 'Cite Ibn Rachik', 'Cite Mehrzia 2', 'Jaafar 1', 'Jaafar 2'
            ]
          },
          {
            name: 'Ariana-Kalaat Landlous',
            delegations: [
              'Borj El Hadj', 'El Misra', 'Ferme Hababou', 'Ferme Mustapha', 'Henchir Touba', 'Henchir Toubias', 'Pont De Bizerte', 'Cite Bir El Araies', 'Cite Des Martyrs', 'Cite El Fejja', 'Cite El Mourouj', 'Cite El Oulja', 'Cite Essibous', 'Cite Tarek Ibn Zied', 'Kalaat Landlous centre'
            ]
          },
          {
            name: 'Ariana-Ville',
            delegations: [
              'Ariana', 'Cite Des Roses', 'Centre Commercial Ikram', 'Cite Du Jardin', 'Cite Du Printemps', 'Cite Ennouzha', 'Cite Essaada (Ariana)', 'Nouvelle Ariana', 'Residence Ennour ( Naser 2)', 'Residence Ennour (Ariana)', 'Cite Belvedere 2', 'Cite Du Soleil', 'Cite El Intissar 1', 'Cite Jamil', 'Cite Ettaamir 5', 'Cite Borj Turki 1', 'Cite Des Juges', 'Cite Borj Turki 2', 'Borj El Baccouche', 'Cite De La Sante', 'Cite Essaada (Riadh Andalous)', 'Cite Karim', 'Cite Mehrzia 1', 'Residence El Ouns', 'Residence Ibn Zeidoun', 'Residence Ichbilia', 'Residence Kortoba'
            ]
          },
          
          {
            name: 'Ariana-Cite Ennasr',
            delegations: [
              'Cite Ennasr 1', 'Cite Ennasr 2'
            ]
          },
          {
            name: 'Ariana-El Menzah',
            delegations: [
              'El Menzah 7', 'El Menzah 6', 'El Menzah 5', 'El Menzah 4', 'El Menzah 3', 'El Menzah 2', 'El Menzah 1', 'El Menzah 8', 'El Menzah 9'
            ]
          },
          {
            name: 'Ariana-Ettadhamen',
            delegations: [
              'Cite 18 Janvier', 'Cite Ben Yerfes', 'Cite El Houda', 'Cite Ennasser', 'Cite Ettadhamen', 'Cite Ettayarene', 'Cite Sfar', 'Cite Snit 2', 'Cite Snit Nagra'
            ]
          },
          {
            name: 'Ariana-Borj Louzir',
            delegations: [
              'Borj Louzir', 'Cite Ennacim', 'Cite Hedi Nouira'
            ]
          },
          {
            name: 'Ariana-Charguia',
            delegations: [
              'Charguia 1', 'Charguia 2', 'Station Marchandises Fret'
            ]
          },
          {
            name: 'Ariana-Mnihla',
            delegations: [
              'Cite Ali Bourguiba', 'Cite De La Republique', 'Cite El Bassatine1', 'Cite El Bassatine2', 'Cite El Bassatine3', 'Cite El Gouabsia', 'Cite El Ouard', 'Cite Ennasr', 'Cite Essaada', 'Cite Rafaha', 'Cite Rous El Harayek', 'Essanhaji', 'Mnihla', 'Residence Ennasr'
            ]
          },
          {
            name: 'Ariana-Riadh Landlous',
            delegations: [
              'Riadh Landlous'
            ]
          },
          
          {
            name: 'Ben Arous-El Mourouj',
            delegations: [
              'El Mourouj 1', 'El Mourouj 2', 'El Mourouj 3', 'El Mourouj 4', 'El Mourouj 5', 'El Mourouj 6'
            ]
          },
          {
            name: 'Ben Arous-Bou Mhel',
            delegations: [
              'Bou Mhel El Bassatine', 'Belle Air', 'Boumhel centre'
            ]
          },
          {
            name: 'Ben Arous-Naassen',
            delegations: [
              'Naassen'
            ]
          },
          {
            name: 'Ben Arous-Hammam Lif',
            delegations: [
              'Cite Ennouzha', 'Cite Essanaoubria', 'Cite Hached', 'Cite Kortoba', 'Cite Mongi Slim', 'Cite Office Cereale', 'Cite Thermale', 'Hammam Lif', 'Cite Boussouffara', 'Bou Kornine', 'Cite Bou Kornine 2', 'Cite 7 Novembre', 'Cite Casino', 'Cite Dar El Bey', 'Cite De La Municipalite', 'Cite Des Instituteurs', 'Cite Des Ouvriers', 'Cite Ennahdha'
            ]
          },
          {
            name: 'Ben Arous-Mohamadia',
            delegations: [
              'Cite Bou Akroucha', 'Cite Bourbai', 'Cite Cebbalet Chikh', 'Cite Chaabane', 'Cite Chebbi', 'Cite De La Carriere 1', 'Cite De La Carriere 2', 'Cite Du Lycee', 'Cite El Amen', 'Cite El Atiq', 'Cite El Baccouch', 'Cite El Bassatine', 'Cite El Elmi 1', 'Cite El Elmi 2', 'Cite El Haddad', 'Cite El Hana', 'Cite El Ksar', 'Cite El Omri 1', 'Cite El Omri 2', 'Cite Ellouata', 'Cite Ennacim 1', 'Cite Ennacim 2', 'Cite Ennasr', 'Cite Ennouzha', 'Cite Erriadh', 'Cite Essaada 1', 'Cite Essaada 2', 'Cite Essaada 3', 'Cite Essalem', 'Cite Ettayari', 'Cite Ettouahria', 'Cite Ezzitoun 2', 'Cite Fattouma Bourguiba', 'Cite Hached 1', 'Cite Hached 2', 'Cite Jaouhara', 'Cite Jardins', 'Cite Mongi Slim 1', 'Cite Mongi Slim 2', 'Cite Oudhna', 'Cite Taieb Mhiri', 'Cite Tnich', 'Mohamadia', 'Residence El Ferdaous', 'Residence El Moustakbel', 'Residence El Ouns', 'Residence Ettaoufik'
            ]
          },
          {
            name: 'Ben Arous-Rades',
            delegations: [
              'Cite Snit', 'Decouppage Haj Aleya', 'Rades 7 Novembre', 'Village Mediterranee', 'Ville Sportive', 'Cite 20 Mars', 'Cite Ennour', 'Rades Saline', 'Chouchet Rades', 'Zone Industrielle 1', 'Cite Mohamed Ali', 'Zone Industrielle 2', 'Cite Rades 2', 'Zone Petroliere', 'Cite Sfaxi', 'Cite Sncft', 'Cite Taieb Mhiri 1', 'Cite Taieb Mhiri 2', 'Cite Taieb Mhiri 3', 'Rades', 'Rades Plage', 'Cite El Ezz', 'Cite Nouvelle', 'Rades El Mrah', 'Rades Foret', 'Rades Medina', 'Rades Meliane', 'Rades Mongil'
            ]
          },
          {
            name: 'Ben Arous-Mornag',
            delegations: [
              'Ahmed Zaied', 'Ben Nouiji', 'Borj Essoufi', 'Bou Jerdga', 'Chala', 'Chamine', 'Cite Ennasr', 'Douar La Porte', 'Douar Salem', 'Cite Air Nouvelle', 'El Alelgua', 'Cite Ettayarane', 'El Araba', 'El Abebsa', 'El Haouamed', 'El Fendria', 'El Ksibi', 'El Kabouti', 'Ettiayra', 'Jebel Ersass', 'Ferme Marquee', 'Jourjou', 'Henchir Bouchta', 'Mirghna', 'Henchir El Ghobar', 'Sidi Salem El Garci', 'Henchir Khelil', 'Huilerie Bayrem', 'Mornag', 'Ouzra', 'Ain Regad', 'Sidi Saad', 'Barrouta', 'Tarhouna 1', 'Bir Jedid', 'Tarhouna 2', 'El Gounna', 'Zaouit Mornag', 'Henchir Kharrouba', 'Khelidia', 'Kouenjel', 'Sidi Messaoud', 'Sidi Othman Landari', 'Sondage', 'Errissala'
            ]
          },
          {
            name: 'Ben Arous-Megrine',
            delegations: [
              'Megrine', 'Megrine Coteau', 'Megrine Superieur', 'Sidi Rezig', 'Megrine Chaker', 'Megrine Jaouhara', 'Cite Founiz', 'Megrine Riadh', 'Saint Gobin', 'Zone Industrielle Saint Gobin'
            ]
          },
          {
            name: 'Ben Arous-Hammam Chatt',
            delegations: [
              'Hammam Chatt', 'Bir El Bey'
            ]
          },
          {
            name: 'Ben Arous-Nouvelle Medina',
            delegations: [
              'Nouvelle Medina'
            ]
          },
          {
            name: 'Ben Arous',
            delegations: [
              'Cite 18 Janvier', 'Cite Borj Louzir', 'Cite Du Port', 'Cite Du Wagon', 'Cite El Ahlem', 'Cite El Akacia', 'Cite El Amen', 'Cite El Ezz', 'Cite El Oulija', 'Cite El Ouns', 'Cite Ben Miled', 'Cite Ennakhil 1', 'Cite El Habib', 'Cite Ennakhil 2', 'Cite El Houl', 'Cite Errachid', 'Cite El Ward', 'Cite Erriadh', 'Cite El Yasmine', 'Cite Hedi Khfacha', 'Cite Ennasr', 'Cite Imen', 'Ezzahra El Habib', 'Cite Industrielle', 'Cite Mrad 1', 'Cite Mrad 2', 'Cite Panorama', 'Ezzahra'
            ]
          },
          {
            name: 'Ben Arous-Ezzahra',
            delegations: [
              'Cite 20 Mars', 'Cite Brim', 'Cite Chebbi', 'Cite El Amel', 'Cite El Amel 2', 'Cite El Banefsej', 'Cite El Fateh', 'Cite El Hana', 'Cite El Hidhab', 'Cite El Izdihar', 'Cite El Misk', 'Cite El Moustakbel', 'Cite El Ward', 'Cite El Wifak', 'Cite Ennasr', 'Cite Ennouzha', 'Cite Erriadh', 'Cite Ezzitoun 1', 'Cite Khouaja', 'Cite Snit', 'Cite Trabelsi', 'El Menaychia', 'Fouchana', 'Meghira Centre', 'Meghira Inzel', 'Zone Industrielle El Meghira', 'Bir Zendala', 'Chebedda', 'Douar El Houch'
            ]
          },
          {
            name: 'Ben Arous',
            delegations: [
              'Zone industrielle', 'Ben Arous'
            ]
          },
          {
            name: 'Ben Arous-Borj Cedria',
            delegations: [
              'Borj Cedria', 'cite Riadh'
            ]
          },
          {
            name: 'Ben Arous-El Yasminette',
            delegations: [
              'El Yasminette'
            ]
          },
          {
            name: 'Mannouba-Douar Hicher',
            delegations: [
              'Douar Hicher', 'Cite Khaled Ibn El Walid'
            ]
          },
          {
            name: 'Mannouba-Jedaida',
            delegations: [
              'Cite Ennour', 'Cite Erriadh', 'Cite Essaroula', 'Cite Guichba', 'Cite Habib El Ayari', 'Cite Hached', 'Cite Hamdi', 'Cite Hammed Nouvelle', 'Cite Jebel', 'Cite Mokhtar', 'Cite Najiba', 'Cite Rurale', 'Cite Sidi Salem', 'Cite Tarek Ibn Zied', 'Cite Touhami Nefzi', 'Cite Trabelsi', 'Cite Yougharta', 'El Henna', 'Ezzahra', 'Henchir Dheniba', 'Jedaida', 'El Mansoura', 'El Habibia', 'Cite Aboubaker Esseddik', 'Cite Bel Hay', 'Cite Ben Hassine', 'Cite De La Rtt', 'Cite Des Andalous', 'Cite Dhamene', 'Chaouat', 'Cite Dhniba', 'Cite Ecole Primaire', 'Cite El Baraka', 'Cite El Hidaya', 'Cite Ennajet'
            ]
          },
          {
            name: 'Mannouba-Tebourba',
            delegations: [
              'Gueffaya', 'Sidi Abdelbasset', 'Argoub Erroumi', 'Bir Ezzitoun', 'Cite 7 Novembre', 'Cite Chebbi', 'Cite De France', 'Cite De La Palestine', 'Cite El Argoub', 'Cite El Bassatine', 'Cite El Kef Lahmar', 'Cite El Kharrouba', 'Cite El Malja', 'Cite El Malja Hamra', 'Cite El Mandra', 'Cite El Mellassine', 'Cite Ennajah', 'Cite Erraja', 'Cite Errimel', 'Cite Essaada', 'Cite Essaada 2', 'Cite Khelif Jalled', 'Cite Laroussia', 'Cite Populaire', 'Cite Route Chouigui', 'Cite Souyah', 'El Aroussia', 'Goumriane', 'Tebourba', 'Borj Ettoumi', 'El Mahfoura', 'Toungar', 'Ain El Karma', 'Chouigui', 'Ain Zammit', 'Eddikhila', 'El Ansarine', 'El Haouaria', 'El Mellaha', 'Gosset El Bey'
            ]
          },
          {
            name: 'Mannouba-Mornaguia',
            delegations: [
              'Bou Regba', 'Cite Bir Ben Njima', 'Cite De La Rtt 3', 'Cite Toumia', 'El Hamayem', 'Ghedaouna', 'Mornaguia', 'Sidi Ali El Hattab', 'Mornaguia 20 Mars', 'El Fejja', 'Bou Hanech', 'El Bassatine'
            ]
          },
          {
            name: 'Mannouba-Borj El Amri',
            delegations: [
              'Aouilia (Km 37)', 'Bir Ettouil', 'Borj Ennour', 'El Messaidine', 'Borj El Amri', 'Cite El Hafsia', 'Cite El Intilaka', 'Cite El Manai', 'Cite Ennouzha 1', 'Cite Ennouzha 2', 'Cite Taoufik', 'Drijette', 'Enfaiedh', 'Ksar Hadid', 'Menzel Habib', 'Teboltech'
            ]
          },
          {
            name: 'Mannouba-El Battan',
            delegations: [
              'Cite 7 Novembre', 'Cite Ben Ghenia', 'Cite Brik', 'Cite El Houda', 'Cite Ennasr', 'Cite Ennour', 'Cite Ennouzha', 'El Battan', 'El Mansoura 2', 'El Mehrine', 'Ezzouitina', 'Ferme N°7', 'Saint Joseph'
            ]
          },
          
          {
            name: 'Mannouba-Oued Ellil',
            delegations: [
              'Cite 7 Novembre', 'Cite Afh', 'Cite Bejoua 2', 'Cite Ben Arfa', 'Cite Ben Jemaa', 'Cite Bennour Bejoua 1', 'Cite Bouzid', 'Cite Dhamene 2', 'Cite El Boustene 1', 'Cite El Boustene 2', 'Cite El Izdihar Bejoua', 'Cite El Moezzia', 'Cite El Moultaka 1', 'Cite Ettourjmene', 'Cite Marseille', 'Cite Militaire', 'Oued Ellil', 'Cite Ennacim', 'Cite Erriadh Zone C', 'Cite Erriadh Zone F', 'Essaida'
            ]
          },
          {
            name: 'Mannouba',
            delegations: [
              'La Mannouba ville', 'Denden'
            ]
          },
          
          
          {
            name: 'Tunis',
            delegations: [
              'Bab Bhar', 'Bab El Jazira', 'Sidi Bahri', 'Republique', 'Hedi Chaker', 'Habib Thameur', 'Montplaisir'
            ]
          },
          {
            name: 'Tunis-Jebel Jelloud',
            delegations: [
              'Jebel Jelloud', 'Cite Thameur', 'El Afrane', 'Sidi Fathallah', 'Ali Bach-Hamba', 'El Garjouma', 'El Sebkha'
            ]
          },
          {
            name: 'Tunis-Carthage',
            delegations: [
              'Cite De La Steg', 'Cite Mohamed Ali', 'Amilcar', 'Salambo', 'El Yasmina', 'Cartage Byrsa', 'Cartage Plage', 'Carthage Presidence'
            ]
          },
          {
            name: 'Tunis-El Hrairia ',
            delegations: [
              'Cite El Mechtel', 'Cite Hwas', 'El Hrairia', 'Cite Bougatfa 1', 'Cite Errachidia', 'Cite Essalama', 'Cite Essoltani', 'Cite Jlas', 'Cite Medjerda', 'Ezzahrouni', 'Cite Bouzaiene', 'Ezzouhour 5'
            ]
          },
          {
            name: 'Tunis-Berge Du Lac ',
            delegations: [
              'Berge Du Lac 1', 'Berge Du Lac 2', 'Berge Du Lac 3', 'Berge Du Lac 0'
            ]
          },
          {
            name: 'Tunis-La Goulette',
            delegations: [
              'Casino La Goulette', 'Cite Ennacim La Brise', 'El Battah', 'Kheireddine', 'La Goulette', 'La Goulette Nouvelle', 'La Goulette Port', 'Cite El Mechtel'
            ]
          },
          {
            name: 'Tunis-Le Bardo',
            delegations: [
              'Cite Des Enseignants', 'Cite Star', 'Khaznadar', 'Ras Tabia', 'Bouchoucha', 'Cite Alten', 'Cite Du Stade', 'Le Bardo centre', 'Bortal Hayder', 'Ksar Said'
            ]
          },
          {
            name: 'Tunis-La Medina',
            delegations: [
              'El Medina', 'El Sabaghine', 'El Hafsia', 'Sidi Boumendil', 'El Taoufik', 'Souk Nel Nhes', 'Bab Djedid', 'Bab Menara', 'El Zraria', 'Sidi Ali Azouz', 'Tourbet El Bey'
            ]
          },
          {
            name: 'Tunis-El Kram',
            delegations: [
              'Bir El Hlou', 'Le Kram Ouest', 'Sidi Amor', 'Le Kram centre', 'Le Kram Est', 'Zone Industrielle / Lac3'
            ]
          },
          {
            name: 'Tunis-El Omrane Superieur',
            delegations: [
              'Cite El Intilaka', 'El Nassim', 'Rommana', 'Cite Ibn Khaldoun I', 'Cite Ibn Khaldoun Vi', 'El Omrane Superieur'
            ]
          },
          {
            name: 'Tunis-Cite El Khadra',
            delegations: [
              'Cite Jardins', 'Kheireddine Pacha', 'Cite El Khadra', 'Cite El Wafa', 'Cite Olympeade', 'Cite Oplympique', 'Cite Star'
            ]
          },
          {
            name: 'Tunis-Tunis Belvedere',
            delegations: [
              'Tunis Belvedere'
            ]
          },
          {
            name: 'Tunis-El Kabbaria',
            delegations: [
              'El Mourouj 2', 'Cite Bou Hjar', 'El Kabbaria 1', 'El Kabbaria 2', 'El Kabbaria 3', 'El Kabbaria 4', 'El Ouerdia 4', 'Ibn Sina'
            ]
          },
          {
            name: 'Tunis-Bab Souika ',
            delegations: [
              'Bab El Khadra', 'Bab El Akoues', 'Bab El Alouj', 'Bab El Assel', 'Bab Sidi Abdessalem', 'Bab Souika', 'Borj Zouara', 'El Halfaouine', 'Hammam El Remimi', 'Sidi Djebeli', 'Tronja', 'Zaouiet El Bakria'
            ]
          },
          {
            name: 'Tunis-El Menzah',
            delegations: [
              'Cite Caravelles', 'Cite El Mahrajene', 'Cite Snit'
            ]
          },
          {
            name: 'Tunis-El Omrane',
            delegations: [
              'Bir Atig', 'Cite Centrale', 'Cite Des Oliviers', 'Cite El Habib', 'Cite Militaire', 'Djbal El Ahmar', 'El Omrane', 'Oued El Sebai'
            ]
          },
          {
            name: 'Tunis-Ezzouhour',
            delegations: [
              'Cite Des Officiers', 'Cite Essaada', 'Cite Essomrane', 'Cite Ezzouhour', 'Ezzouhour 4', 'Zahrouni'
            ]
          },
          {
            name: 'Tunis-Sidi El Bechir',
            delegations: [
              'Abou El Kacem Chebbi', 'Bab Alioua', 'El Gourjani', 'Essaida Mannoubia', 'Monfleury', 'Saida Manoubia', 'Sidi El Bechir', 'Sidi Mansour', 'Maakel EzzaÃ‚Â¤M', 'Bab El Falla'
            ]
          },
          {
            name: 'Tunis-Sidi Hassine',
            delegations: [
              'Birine', 'Borj Chakir', 'Cite 25 Juillet', 'Cite 7 Novembre', 'Cite El Bettoumi', 'Cite El Gaafouri', 'Cite El Mestiri', 'Cite Mohamed Ali', 'Cite Mrad 1', 'Cite Mrad 2', 'Jayara', 'Sidi Hassine centre'
            ]
          },
          {
            name: 'Tunis-Essijoumi',
            delegations: [
              'Cite Helal', 'El Mellassine'
            ]
          },
          {
            name: 'Tunis-Ettahrir',
            delegations: [
              'Cite Du Jardin', 'Cite El Ferdaous', 'Cite Essadik', 'Cite Ettahrir Sup.', 'Ettahrir 1', 'Ettahrir 2', 'Ettahrir 3'
            ]
          },
          {
            name: 'Tunis-El Manar',
            delegations: [
              'El Manar 1', 'El Manar 2'
            ]
          },
          {
            name: 'Tunis-Omran supperieur',
            delegations: [
              'Omran supperieur'
            ]
          },
          {
            name: 'Tunis-El Ouerdia',
            delegations: [
              'Bellevue', 'Borj Ali Errais', 'Cite El Izdihar', 'Cite Mohamed Ali', 'Dibouzville', 'El Ouerdia', 'Lakagnia', 'Les Martyrs', 'Cite Bhar Lazreg', 'Mathul De Ville', 'Monhomme'
            ]
          },
          {
            name: 'Tunis-Jardin de Carthage',
            delegations: [
              'Jardin de Carthage', 'Cite Aziza'
            ]
          },
          {
            name: 'Tunis-Sidi Bousaid',
            delegations: [
              'Sidi Bousaid', 'Marina'
            ]
          },{
            name: 'Tunis-La Marsa',
            delegations: [
              'Carrefour / Monji Slim', 'Cite Erriadh', 'Bhar Lazreg', 'Cite Ennasim', 'Cube', 'Corniche', 'Sidi Abdlaziz', 'Cite Tabak', 'Esswes', 'GP9 Route La Marsa', 'Cite les pins', 'Cite El khalil', 'Cite sidi Daoued', 'Marsa ville'
            ]
          },
          {
            name: 'Tunis-Bab Bnet',
            delegations: [
              'Bab Bnet'
            ]
          },
          {
            name: 'Tunis-Bab Saadoun Gare',
            delegations: [
              'Bab Saadoun Gare'
            ]
          },
          {
            name: 'Tunis-Laouina',
            delegations: [
              'Jardin Laouina', 'Cite el WAHAT', 'Cite el SALAMA', 'cite Tayeb Mhiri', 'Cite Zayatin', 'Cite Militaire'
            ]
          },
          {
            name: 'Tunis-Avenue Mohamed 5',
            delegations: [
              'Avenue Mohamed 5'
            ]
          },
          {
            name: 'Tunis-Avenue Habib Bourguiba',
            delegations: [
              'Avenue Habib Bourguiba'
            ]
          },
          {
            name: 'Tunis-Place Barcelone',
            delegations: [
              'Place Barcelone'
            ]
          },
          {
            name: 'Tunis-Passage',
            delegations: [
              'Passage'
            ]
          },
          {
            name: 'Tunis-Lafayette',
            delegations: [
              'Lafayette'
            ]
          },
          {
            name: 'Tunis-Bab El Khadra',
            delegations: [
              'Bab El Khadra'
            ]
          },
          {
            name: 'Tunis-Centre Urbain Nord',
            delegations: [
              'Centre Urbain Nord'
            ]
          },
       
          {
            name: 'Tunis-Gammarth',
            delegations: [
              'Gamarth Village', 'Zone touristique', 'Route Du relais', 'Gammarth supperieur'
            ]
          },
          {
            name: 'Tunis-MONTFLEURY',
            delegations: [
              'MONTFLEURY'
            ]
          },
          
          {
            name: 'Kef-Le Sers',
            delegations: [
              'Cite El Bassatine', 'Cite Ennacim', 'Cite Ennour', 'Cite Ennour 2', 'Cite Ennouzha', 'Cite Erriadh', 'Cite Essanaouber', 'Cite Ezzouhour', 'El Argoub', 'El Mellaha', 'Elles', 'Labar', 'Le Sers', 'Le Vieux Sers', 'Lorbous', 'Lorbous Gare', 'Sidi Nasser', 'Tricha', 'Bir Heddi', 'Bou Sliaa', 'Cite Du Pont'
            ]
          },
          {
            name: 'Kef-Nebeur',
            delegations: [
              'Cite Hadj Ahmed', 'Cite Nouvelle', 'Nebeur', 'Zone Industrielle', 'Oued Mellegue', 'Sidi Khiar', 'Tell Ghouzlane', 'Ain El Henchir', 'Cite Ennasr'
            ]
          },
          {
            name: 'Kef-Sakiet Sidi Yousse',
            delegations: [
              'Cite Ennour 1', 'Cite Ennour 2', 'Cite Ettahrir', 'Cite Moniments', 'Cite Sakmo', 'Cite Sprols', 'Essakia', 'Farchen', 'Jeradou', 'La Mine', 'Place De La Republique', 'Sidi Rabeh', 'Tabia', 'Ain El Karma', 'Cite 8 Fevrier 1', 'Cite 8 Fevrier 2', 'Cite 8 Fevrier 3', 'Cite Des Ouvriers', 'Cite El Intilaka', 'Cite El Kerfeh'
            ]
          },
          {
            name: 'Kef-Tajerouine',
            delegations: [
              'Garn El Halfaya', 'La Gare', 'Sidi Abdelbasset', 'Tajerouine', 'Tajerouine Gare', 'Menzel Salem', 'Jazz', 'Mahjouba', 'Sidi Mtir', 'Cite 2 Mars', 'Cite 7 Novembre', 'Cite Afh', 'Cite Ain El Bar', 'Cite Bourguiba 1', 'Cite Bourguiba 2', 'Cite Bourguiba 3', 'Cite Ciok', 'Cite De La Palestine', 'Cite Des Martyrs', 'Cite Du Souk', 'Cite Du Stade', 'Cite El Ain', 'Cite El Bassatine', 'Cite El Besma 1', 'Cite El Besma 2', 'Cite El Fouroussia', 'Cite Errahba', 'Cite Essakia', 'Cite Essanaouber', 'Cite Ezzitouna', 'Cite Habib Thameur', 'Cite Hopital', 'Cite Jebel', 'Cite Taieb Mhiri', 'El Hodh'
            ]
          },
          {
            name: 'Kef-Touiref',
            delegations: [
              'Bir Ben Cherifa', 'Cite El Izdihar', 'Cite Ennour', 'Cite Essaada', 'Gargour', 'Ladhieb', 'Mellala', 'Ouljet Essedra', 'Touiref'
            ]
          },
          {
            name: 'Kef-El Ksour',
            delegations: [
              'Ain El Fedhil', 'Ain Ksiba', 'Banou', 'Cite Ali El Bahloul', 'Cite Attouche', 'Cite Centre Ville', 'Cite El Borj', 'Cite El Hella', 'Cite Essaada', 'Cite Mohamed Ali', 'Cite Safia 1', 'Cite Safia 2', 'Cite Sidi Ali Mahjoub', 'Cite Sidi Mansour', 'Echaabna', 'Eddhila', 'El Afset', 'El Hmarna', 'El Houilat', 'El Ksour', 'Kirata', 'Louata', 'Ouled Bouraoui', 'Ouled Ghana', 'Ouled Zid', 'Ezzitouna'
            ]
          },
          {
            name: 'Kef-Est',
            delegations: [
              'Borj El Aifa', 'Barnoussa', 'Bou Meftah', 'Cite 1Er Mai', 'Cite 2 Mars', 'Oued Souani', 'Cite 3 Aout', 'Cite 7 Novembre', 'Cite Ain Mnekh', 'Cite Ammar Ayari', 'Cite Bahri Barbouche', 'Cite Bayedh', 'Cite Bir Thelj', 'Cite Bourayou', 'Cite Cherichi', 'Cite De La Gare', 'Cite De La Liberte', 'Cite Des Enseignants', 'Cite Eddyr', 'Cite El Bassatine', 'Cite El Farah', 'Cite El Hana', 'Zaafrane', 'Cite El Izdihar', 'Cite El Ouns', 'Cite Ennacim', 'Cite Ennouhoudh 1', 'Cite Ennouhoudh 2', 'Cite Erriadh', 'Cite Essaada 1', 'Cite Essaada 2', 'Cite Essakhra', 'Cite Ezzitouna', 'Cite Fourati', 'Cite Hached', 'Cite Harrouch', 'Cite Ibn Abi Dhiaf', 'Cite Ibn Khaldoun', 'Cite Souani Laaneb 1', 'Cite Souani Laaneb 2', 'Cite Taieb Mhiri', 'Cite Tounsi Arnous', 'Ennaima', 'Le Kef', 'Oued Erraml', 'Bahra', 'Oued Tessa', 'Salah El Bahri', 'Semana', 'Sidi Mansour', 'Zone Industrielle'
            ]
          },
          {
            name: 'Kef-Kalaa El Khasba',
            delegations: [
              'Cite El Mellessine', 'Sidi Ahmed Essalah', 'Cite Errouka', 'Cite Essouitir', 'Kalaa El Khasba'
            ]
          },
          {
            name: 'Kef-Jerissa',
            delegations: [
              'Cite Bourguiba', 'Cite Du Jardin', 'Cite Du Stade', 'Cite El Bassatine', 'Cite Ennacim', 'Cite Ennour', 'Cite Essanabel', 'Cite Essanaouber Bou Arara', 'Cite Ezzouhour Bou Arara', 'Cite Mongi Slim', 'Cite Yougharta', 'El Felta', 'Errebiba', 'Kalaat Sinane', 'Safsaf', 'Ain Sinan', 'Bou Jabeur'
            ]
          },
          
          {
            name: 'Kef-Jerissa',
            delegations: [
              'Ain El Garsa', 'Cite Ahmed Tlili', 'Cite Ali Ben Ghedhahom', 'Cite Ali Ben Khlifa', 'Cite Bourguiba', 'Cite Chahid El Aid 1', 'Cite Chahid El Aid 2', 'Cite De La Gare', 'Cite Des Ingenieurs', 'Cite Des Instituteurs', 'Cite Des Martyrs', 'Cite Des Ouvriers', 'Cite Du Souk', 'Cite Echahid Belgacem', 'Cite Echahid Sadok', 'Cite Errouki', 'Cite Essaada', 'Cite Essanaouber Ancienne', 'Cite Essanaouber Nouvelle', 'Cite Essarouel', 'Cite Ettahrir', 'Cite Ezzouhour', 'Cite Hached Nord', 'Cite Hached Sud', 'Cite Mohamed Ali', 'Cite Mohamed Ali Nlle', 'Cite Sidi Yahya', 'Cite Taieb Mhiri', 'El Gorraia', 'El Hayadra', 'Ennaiem', 'Esbiaat', 'Fej Ettamr', 'Henchir Sarrat', 'Jerissa', 'Jerissa Gare', 'Oum El Kelil'
            ]
          },
          {
            name: 'Kef-Dahmani',
            delegations: [
              'Abida', 'Adissi', 'Ain Meskhia', 'Charket Essaboun', 'Cite 1 Mai 1', 'Cite 1 Mai 2', 'Cite 2 Mars 1', 'Cite 2 Mars 2', 'Cite Ben Ammar 1', 'Cite Ben Ammar 2', 'Cite Ben Hafdhallah', 'Cite El Azima', 'Cite El Intilak', 'Cite Ennasr', 'Cite Ennour', 'Cite Erriadh', 'Cite Essanabel', 'Cite Essanaouber 1', 'Cite Essanaouber 2', 'Cite Ettenmia', 'Cite Hopital', 'Cite Ibn Khaldoun', 'Cite Mohamed Ali', 'Cite Sidi Dahmani', 'Dahmani', 'Sidi Asker', 'Sidi Baraket Nord', 'Sidi Baraket Sud', 'Zouarine'
            ]
          },
          {
            name: 'Kef-Ouest',
            delegations: [
              'Kef Ouest'
            ]
          },
          
          {
            name: 'Zaghouan',
            delegations: [
              'Jimla', 'Kantra El Kahla', 'Merkez Ali Besseghaier', 'Mhiris', 'Oued Sbaihia', 'Sidi Mraieh', 'Zaghouan', 'Ain Essaboune', 'Ain Lansarine', 'Ain Ledhieb', 'Ain Safsaf', 'Bni Darraj', 'Bni Mar', 'Cite 20 Mars', 'Cite Administrative', 'El Magren', 'Cite Bourguiba', 'Cite De La Municipalite', 'Cite De La Sonede', 'Cite Des Nymphes', 'Cite Du Lycee', 'Cite El Bassatine', 'Cite El Menzah', 'Bou Slim', 'Cite Ennouzha', 'Hammam Jedidi', 'Cite Equipement', 'Oued Ezzit', 'Cite Ezzouhour', 'Cite Industrielle 1', 'Cite Industrielle 2', 'Cite Nesrine', 'El Mhedhba', 'Bir Halima', 'Hemiane', 'Sidi Mediene'
            ]
          },
          {
            name: 'Zaghouan-Ennadhour',
            delegations: [
              'Ben Halaoua', 'Chaalil Nord', 'Chaalil Sud', 'Dhorbania', 'Ennadhour', 'Sidi Bannour', 'Sidi Naji', 'Souar', 'Zbidine', 'Zouagha', 'Bir Chaouch', 'Chebaana', 'Kef Agueb', 'Meidher', 'Mrigueb', 'Ain El Battoum', 'Bou Araara', 'El Hnainia', 'Erragba', 'Ouled Jaballah', 'Soughas Nord', 'Soughas Sud'
            ]
          },
          {
            name: 'Zaghouan-El Fahs',
            delegations: [
              'Bir Moukra', 'Borj Abdeljalil', 'Bou Garnine', 'Chenanfa', 'Cite 20 Mars', 'Cite Bou Hmida', 'Cite Des Enseignants', 'Cite El Amel', 'Cite Ennasr', 'Bent Saidane', 'Cite Ennisma', 'Cite Erriadh', 'Cite Essaada 1', 'Cite Essaada 2', 'Cite Essalem', 'Cite Industrille', 'Cite Snit Nouvelle', 'El Amaiem', 'Dhraa Ben Jouder', 'El Fahs', 'El Knaziz', 'Gherifat', 'Glib Jemal', 'Henchir Brouta', 'Henchir Dhomda', 'Jabbes', 'Jebel Mansour', 'Jougar', 'Kef Ezzroug', 'Khemayssia', 'Koudouat Chair', 'Oued El Khadhra', 'Oum El Abouab', 'Rehahla', 'Sed Oued El Kebir', 'Sidi Amara', 'Sidi Naoui', 'Tebika', 'Tlil Essalhi', 'Sidi Aouidette'
            ]
          },
          {
            name: 'Zaghouan-Bir Mcherga',
            delegations: [
              'Jebel El Oust', 'Bir Mcherga Gare', 'Bir Mcherga', 'Boucha', 'Delaiel El Arouss', 'Ouled Helel', 'Ain El Asker', 'Sminja'
            ]
          },
          {
            name: 'Zaghouan-Hammam Zriba',
            delegations: [
              'Jeradou', 'Ain Batria', 'Bou Achir', 'Cite Du Lycee', 'Oued El Kenz', 'Cite El Houda', 'Zriba', 'Hammam Zriba'
            ]
          },
          {
            name: 'Zaghouan-Saouef',
            delegations: [
              'Charchara', 'Dghafla', 'Douar El Haj Amor', 'El Hmira Sud', 'El Khadhra', 'Halg Ennab', 'Henchir El Hamira', 'Oued Touil', 'Saouef', 'Sidi Dghim', 'Sidi Farjallah', 'Sidi Mansour', 'Zagtoun', 'Zeguidane'
            ]
          }
          
            ];

    // State pour les informations de livraison
    const [deliveryInfo, setDeliveryInfo] = useState({
        email: '',
        firstName: '',
        lastName: '',
        street: '',
        country: 'Tunisia',
        postalCode: '',
        city: '',
        delegation: '',
        phone: ''
    });

    const [delegations, setDelegations] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'city') {
            const selectedCity = tunisiaCities.find(city => city.name === value);
            setDelegations(selectedCity ? selectedCity.delegations : []);
            setDeliveryInfo({
                ...deliveryInfo,
                [name]: value,
                delegation: ''
            });
        } else {
            setDeliveryInfo({
                ...deliveryInfo,
                [name]: value
            });
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('Informations de livraison:', deliveryInfo);
    };

    const handleNext = () => {
        if (currentIndex < cartData.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleDeleteProduct = () => {
        const updatedCartData = cartData.filter((_, index) => index !== currentIndex);
        setCartData(updatedCartData);
        if (currentIndex >= updatedCartData.length && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const totalPrice = cartData.reduce((total, item) => {
        return total + (parseFloat(item.product.price) * item.qty);
    }, 0);

    function getFlavourColor(flavour) {
        switch (flavour.toLowerCase()) {
            case 'chocolat':
                return '#5b3c11';
            case 'vanille':
                return '#efef8f';
            case 'fraise':
                return 'pink';
            case 'cookies':
                return '#785e2f';
            case 'fraise_banane':
                return '#f88e55'; // Example of semi-pink and semi-yellow
            case 'caramel':
                return 'wheat';
            default:
                return 'gray'; // Default color for unknown flavours
        }
    }


    const isFormValid = () => {
        return (
            deliveryInfo.email &&
            deliveryInfo.firstName &&
            deliveryInfo.lastName &&
            deliveryInfo.street &&
            deliveryInfo.city &&
            deliveryInfo.delegation &&
            deliveryInfo.phone
        );
    };

    
    const handleValidateOrder = async (event) => {
        event.preventDefault();  // Prevent the form from submitting a GET request
        try {
            const response = await fetch( baseUrl+'validate_order/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: deliveryInfo.email,
                    firstName: deliveryInfo.firstName,
                    lastName: deliveryInfo.lastName,
                    street: deliveryInfo.street,
                    city: deliveryInfo.city,
                    delegation: deliveryInfo.delegation,
                    phone: deliveryInfo.phone,
                    orderDetails: cartData,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi de la commande');
            }
    
            const data = await response.json();
            navigate('/order-success');  // Redirect to the OrderSuccess page
        } catch (error) {
            console.error('Error:', error);
            alert('Une erreur s\'est produite lors de la validation de la commande.');
        }
    };
    
    

        

    return (
<div className="flex-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '140vh' }}>
    <section className='container' style={{ marginTop: '50px' , display: 'flex', width: '80%' }}>
    
    <div className="row" ></div>
   
        {/* Gauche - Formulaire de livraison */}
        <div className="delivery-form" style={{ width: '50%', padding: '20px' }}>
     
            <h2 style={{ textAlign: 'center', color: 'black',fontFamily:'Oswald',textTransform:'uppercase' }}>Informations de Livraison</h2>
            <div style={{ marginTop: '40px' }} />
            <p style={{ textAlign: 'center', color: 'red', fontFamily: 'Roboto', textTransform: 'uppercase', fontSize: '17px' }}>
                <i className="fa-solid fa-circle-exclamation"></i> Veuillez remplir tous les champs du formulaire avant de valider la commande
                </p>

            <form onSubmit={handleFormSubmit}>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="email" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' , }}>Email </label>
                            <input type="email"
                                id="email"
                                name="email"
                                value={deliveryInfo.email}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', margin: '5px 0', fontFamily: 'Roboto' ,border: '2px solid #009F6B', // Green border with color #009F6B
                                    borderRadius: '15px', }}
                                required />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div style={{ width: '48%' }}>
                                <label htmlFor="firstName" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Prénom </label>
                                <input type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={deliveryInfo.firstName}
                                    onChange={handleInputChange}
                                    style={{ width: '100%', padding: '10px' , border: '2px solid #009F6B', // Green border with color #009F6B
                                        borderRadius: '15px',}}
                                    required />
                            </div>
                            <div style={{ width: '48%' }}>
                                <label htmlFor="lastName" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Nom </label>
                                <input type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={deliveryInfo.lastName}
                                    onChange={handleInputChange}
                                    style={{ width: '100%', padding: '10px' ,border: '2px solid #009F6B', // Green border with color #009F6B
                                        borderRadius: '15px',}}
                                    required />
                            </div>
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="street" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Adresse </label>
                            <input type="text"
                                id="street"
                                name="street"
                                value={deliveryInfo.street}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', margin: '5px 0' ,border: '2px solid #009F6B', // Green border with color #009F6B
                                    borderRadius: '15px', }}
                                required />
                        </div>

                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="city" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Ville </label>
                            <select id="city"
                                name="city"
                                value={deliveryInfo.city}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', margin: '5px 0' ,border: '2px solid #009F6B', // Green border with color #009F6B
                                    borderRadius: '15px', }}
                                required>
                                <option value="" style={{ color: 'black', fontFamily: 'Roboto' }}>Sélectionnez une ville</option>
                                {tunisiaCities.map((city, index) => (
                                    <option key={index} value={city.name}>{city.name}</option>
                                ))}
                            </select>
                        </div>
                        {delegations.length > 0 && (
                            <div style={{ marginBottom: '10px' }}>
                                <label htmlFor="delegation" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Délégation </label>
                                <select id="delegation"
                                    name="delegation"
                                    value={deliveryInfo.delegation}
                                    onChange={handleInputChange}
                                    style={{ width: '100%', padding: '10px', margin: '5px 0'  ,border: '2px solid #009F6B', // Green border with color #009F6B
                                        borderRadius: '15px',}}
                                    required>
                                    <option value="" style={{ color: 'black', fontFamily: 'Roboto'  ,border: '2px solid #009F6B', // Green border with color #009F6B
                                    borderRadius: '15px',}}>Sélectionnez une délégation</option>
                                    {delegations.map((delegation, index) => (
                                        <option key={index} value={delegation}>{delegation}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div style={{ marginBottom: '10px' }}>
                            <label htmlFor="phone" style={{ textAlign: 'center', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>Téléphone </label>
                            <input type="text"
                                id="phone"
                                name="phone"
                                value={deliveryInfo.phone}
                                onChange={handleInputChange}
                                style={{ width: '100%', padding: '10px', margin: '5px 0' ,border: '2px solid #009F6B', // Green border with color #009F6B
                                    borderRadius: '15px',}}
                                required />
                        </div>
                        <button type="button" onClick={handleValidateOrder} disabled={!isFormValid()} style={{
                                padding: '10px 20px',
                                backgroundColor: '#009F6B',
                                color: 'white',
                                border: 'none',
                                fontWeight:'bolder',
                                fontFamily:'Roboto',
                                cursor: 'pointer',
                                textTransform:'uppercase',
                                width: '100%',
                                fontSize: '17px'
                            }}>
                            Valider votre commande
                        </button>
                    </form>
                    
        </div>

        {/* Droite - Résumé du panier */}
        <div className="product-container" style={{ width: '50%', marginLeft: '20px', position: 'relative' }}>
      
    <h2 style={{ textAlign: 'center', color: 'black', fontFamily: 'Oswald', textTransform: 'uppercase' }}>Validation de la Commande</h2>
    <div style={{ marginTop: '40px' }} />
    <div className="table-responsive" style={{ width: '100%', margin: '0 auto', textAlign: 'left'  }}>
        {cartData && cartData.length > 0 ? (
            <div style={{
                border: '1px solid black',
                padding: '20px',
                margin: '10px auto',
                backgroundColor: 'white',
                position: 'relative'
                , border: '2px solid #009F6B', // Green border with color #009F6B
                         borderRadius: '15px',
            }}>
                {/* Détails du produit */}
                {/* Supposant qu'un seul produit est affiché à la fois basé sur currentIndex */}
                <div style={{ position: 'relative' }}>
                    <button onClick={handleDeleteProduct} style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                    }}>
                        <i className="fa-solid fa-trash-can" style={{ color: 'red', fontSize: '20px' }} title="Supprimer le produit"></i>
                    </button>
                </div>
               

                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <img src={cartData[currentIndex].product.image}
                        alt={cartData[currentIndex].product.title}
                        style={{
                            width: '170px',
                            height: '190px',
                            objectFit: 'contain',
                            borderRadius: '8px'
                            
                        }} />
                </div>
                <div style={{ marginBottom: '15px' }}></div>
                <p style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', fontWeight: 'bold', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                    Nom du Produit : <span>{cartData[currentIndex].product.title}</span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', fontWeight: 'bold', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                    Prix : <span>{(parseFloat(cartData[currentIndex].product.price) * cartData[currentIndex].qty).toFixed(3)} Dt</span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', fontWeight: 'bold', color: 'black', fontFamily: 'Roboto', textTransform: 'uppercase' }}>
                    Quantité : <span>{cartData[currentIndex].qty}</span>
                </p>

                {/* Display Flavour, Size, and Color */}
                <div>
    {cartData[currentIndex].selectedSize && (
        <p style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            margin: '10px 0', 
            fontWeight: 'bold', 
            color: 'black', 
            fontFamily: 'Roboto', 
            textTransform: 'uppercase'
        }}>
            <span>Taille :</span>
            <span>{cartData[currentIndex].selectedSize}</span>
        </p>
    )}
    {cartData[currentIndex].selectedColor && (
        <p style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            margin: '10px 0', 
            fontWeight: 'bold', 
            color: 'black', 
            fontFamily: 'Roboto', 
            textTransform: 'uppercase'
        }}>
            <span>Couleur :</span>
            <span>{cartData[currentIndex].selectedColor}</span>
        </p>
    )}
   
</div>


{/* Conditionally show the Saveur section */}
{!(cartData[currentIndex].selectedSize && cartData[currentIndex].selectedColor) &&
  !/^offre/i.test(cartData[currentIndex].product.title) && ( // Check if title starts with 'offre' or 'Offre'
    <p style={{
      display: 'flex',
      justifyContent: 'space-between',
      margin: '10px 0',
      fontWeight: 'bold',
      color: 'black',
      fontFamily: 'Roboto',
      textTransform: 'uppercase'
    }}>
      Saveur :
      <span>
        {cartData[currentIndex].selectedFlavour && cartData[currentIndex].selectedFlavour.length > 0 ? (
          cartData[currentIndex].selectedFlavour.split(',').map((flavour, idx) => (
            <div key={idx} style={{
              display: 'inline-block',
              margin: '2px',
              position: 'relative',
            }}>
              <div style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: getFlavourColor(flavour.trim()),
                display: 'inline-block',
                marginRight: '5px'
              }} />
              {flavour.trim()}
            </div>
          ))
        ) : (
          <span>Aucune saveur sélectionnée</span>
        )}
      </span>
    </p>
  )
}
                <div style={{ position: 'absolute', bottom: '130px', left: '0', right: '0', display: 'flex', justifyContent: 'center', gap: '20px'}}>
                    <button onClick={handlePrevious}
                        disabled={currentIndex === 0}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '24px'
                        }}>
                        <i className="fa-solid fa-circle-arrow-left" style={{ color: currentIndex === 0 ? ' black' : ' #009F6B' }}></i>
                    </button>
                    <button onClick={handleNext}
                        disabled={currentIndex === cartData.length - 1}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '24px'

                        }}>
                        <i className="fa-solid fa-circle-arrow-right" style={{ color: currentIndex === cartData.length - 1 ? 'black' : ' #009F6B' ,
                         }}></i>
                    </button>
                </div>

                <div style={{ marginTop: '130px', height: '2px', backgroundColor: 'black'  }} />

                <p style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginTop: '15px', color: '#009F6B', fontSize: '20px', textTransform: 'uppercase' }}>
                    Total à Payer : <span style={{ color: 'black' }}>{totalPrice.toFixed(3)} Dt</span>
                </p>

            </div>
        ) : (
            <p>Aucun produit dans le panier.</p>
        )}
    </div>

</div>

     
<div style={{ marginTop: '150px' }} />
    </section>
    
</div>
    );
}

export default OrderValidation;