import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './CategoryProduct.css'; // Import your CSS file for styling
import MovingBanner from './MovingBanner';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant

function CategoryProducts(props) {
    const baseUrl = CONSTURL; // Use the imported CONSTURL
    const [subcategories, setSubcategories] = useState([]);
    const { Category_id } = useParams();

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 8; // 8 products per page (4 per row, 2 rows)

    useEffect(() => {
        fetchSubcategories(baseUrl + `Category/${Category_id}/Subcategories/`);
    }, [Category_id]);

    function fetchSubcategories(url) {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setSubcategories(data.results);
            });
    }

    // Calculate the indices for pagination
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentSubcategories = subcategories.slice(indexOfFirstProduct, indexOfLastProduct);

    // Calculate total pages
    const totalPages = Math.ceil(subcategories.length / productsPerPage);

    // Handle pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
        <div style={{display: 'flex', justifyContent:'center', height:'100%', width:'100%'}}>
             <section className="container category-product-container" style={{marginTop:'260px', height:'100%', width:'100%' }}>
            <MovingBanner></MovingBanner>
            <div style={{display: 'flex', flexDirection:'column' , justifyContent:'center', alignItems:'center'}}>
                
            
            <div className='row mb-2' style={{width:'100%', height:'100%',display:'flex', justifyContent:'center', flexWrap: 'wrap'}}>
                <div style={{ marginBottom: '33px'}}></div>
                {currentSubcategories.map((subcategory) => (
                    <div key={subcategory.id} className="col-md-3 mb-4" style={{height:'500px' }}>
                        <Link to={`/Subcategory/${subcategory.id}/products`} style={{textDecoration:'none'}}>
                         <div className="card card-hover"    style={{   border: '3px solid #009F6B', // Green border with color #009F6B
                                    borderRadius: '15px', 
                                }}>
                            <img 
                                src={subcategory.image} 
                                className="card-img-top" 
                                alt={subcategory.title} 
                                style={{ 
                                    maxWidth: '80%', 
                                    height: '230px',   
                                    margin: '25px auto 0', 
                                    display: 'block', 
                                    objectFit: 'contain', 
                                    objectPosition: 'center',
                                }} 
                            />
                            <div className="card-body">
                                <h5 className="card-title" style={{ fontSize: '2rem',textAlign:'center' }}>{subcategory.title}</h5>
                                <p className="card-text" style={{ fontSize: '20px', fontFamily: 'Oswald', color: 'black',marginLeft:'1rem' }}>{subcategory.detail}</p>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                     <Link 
                                    to={`/Subcategory/${subcategory.id}/products`} 
                                    className="button-30" 
                                    style={{ fontFamily: 'Oswald' , borderRadius: '5px', padding: '6px 12px', fontSize: '17px',color:'black' }}
                                >
                                    Voir les produits
                                </Link>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </div>
                ))}
            </div>
            
            {/* Pagination */}
            <div className="pagination" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
    {Array.from({ length: totalPages }, (_, index) => (
        <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={`page-link ${index + 1 === currentPage ? 'active' : ''}`}
            style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                border: '2px solid #000', // Adjust the border color as needed
                backgroundColor: index + 1 === currentPage ? '#000' : '#fff',
                color: index + 1 === currentPage ? '#fff' : '#000',
                display: 'flex',
                fontFamily:'Oswald',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.2rem',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                border: '2px solid #009F6B', // Green border with color #009F6B
                borderRadius: '15px', 
            }}
        >
            {index + 1}
        </button>
    ))}
</div>


            </div>
        </section>
        </div>
        </>
    );
}

export default CategoryProducts;
