import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from './Context';

function Ordersuccess() {
    const { setCartData } = useContext(CartContext);

    useEffect(() => {
        setCartData([]); 
        localStorage.removeItem('cartData');
    }, [setCartData]);

    return (
        <section 

            className="d-flex justify-content-center align-items-center" 
            style={{ minHeight: '100vh', backgroundColor: '#f8f9fa' }}
        >
            <div style={{ marginTop: '-150px' }} />
            <div className="col-lg-6 col-md-8 col-sm-10">
                <div 
                    className="card shadow-lg" 
                    style={{ borderRadius: '15px', overflow: 'hidden' }}
                >
                    <div className="card-body text-center p-5">
                        <p className="mb-4">
                            <i className="fa fa-check-circle text-success fa-4x"></i>
                        </p>
                        <h3 className="text-success mb-3" style={{ fontSize: '26px', fontWeight: 'bold' }}>
                            Votre commande a été validée ! 
                        </h3>
                        <p className="mb-4" style={{ fontSize: '18px', color: '#6c757d' }}>
                            Un email de confirmation a été envoyé.
                        </p>
                        <Link 
                            to="/" 
                            className="btn btn-dark btn-lg me-2 mb-2" 
                            style={{ borderRadius: '5px', padding: '10px 30px' }}
                        >
                            Accueil
                        </Link>
                        <Link 
                            to="/Stores/1/" 
                            className="btn btn-outline-secondary btn-lg mb-2" 
                            style={{ borderRadius: '5px', padding: '10px 30px' }}
                        >
                            Continuer vos achats
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Ordersuccess;
