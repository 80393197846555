import React, { useState, useEffect, useContext } from 'react';
import './ProductDetail.css';
import { Link, useParams, useLocation } from 'react-router-dom';
import OneRelatedProduct from './OneRelatedProduct';
import { UserContext, CartContext } from './Context';
import axios from 'axios';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant


function ProductDetail() {
    const baseUrl = CONSTURL; // Use the imported CONSTURL
    const [productData, setProductData] = useState({});
    const [productImgs, setProductImgs] = useState([]);
    const [productTags, setProductTags] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const { Product_id } = useParams();
    const [cartButtonClickStatus, setCartButtonClickStatus] = useState(false);
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const { cartData, setCartData } = useContext(CartContext);
    const userContext = useContext(UserContext);
    const [wishlistMessage, setWishlistMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        fetchData(baseUrl + 'Product/' + Product_id + '/');
        fetchRelatedData(baseUrl + 'Related-Products/' + Product_id + '/');
        checkProductInCart(parseInt(Product_id));
    }, [Product_id]);

    useEffect(() => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart);
        if (cartJson != null) {
            const isInCart = cartJson.some(cart => cart.product.id === parseInt(Product_id));
            setCartButtonClickStatus(isInCart);
        }
    }, [Product_id]);

    function checkProductInCart(product_id) {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart);
        if (cartJson != null) {
            cartJson.forEach((cart) => {
                if (cart != null && cart.product.id === product_id) {
                    setCartButtonClickStatus(true);
                }
            });
        }
    }

    async function fetchData(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Product Data:', data); // Debug log
            setProductData(data);
            setProductImgs(data.product_imgs || []);
            setProductTags(data.tag_list || []);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }

    async function fetchRelatedData(url) {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Related Products:', data); // Debug log
            setRelatedProducts(data.results || []);
        } catch (error) {
            console.error('Error fetching related products:', error);
        }
    }

    const tagsLinks = productTags.map((tag, index) => (
        <Link key={index} className='badge bg-secondary text-white me-1' to={`/products/${tag.trim()}`}>
            {tag.trim()}
        </Link>
    ));

    const cartAddButtonHandler = () => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const cartData = {
            product: {
                id: productData.id,
                title: productData.title,
                price: productData.price,
                image: productData.image,
            },
            quantity: selectedQuantity,
            user: {
                id: 1, // Assuming user ID is hardcoded or fetched from context
            },
        };
        const updatedCartJson = [...cartJson, cartData];
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
        setCartButtonClickStatus(true);
    };

    const cartRemoveButtonHandler = () => {
        const previousCart = localStorage.getItem('cartData');
        const cartJson = JSON.parse(previousCart) || [];
        const updatedCartJson = cartJson.filter(cart => cart.product.id !== productData.id);
        localStorage.setItem('cartData', JSON.stringify(updatedCartJson));
        setCartData(updatedCartJson);
        setCartButtonClickStatus(false);
    };

    function saveInWishList() {
        const buyerId = localStorage.getItem('buyer_id'); // Adjust according to your actual key

        if (!buyerId) {
            console.error('Buyer ID not found in localStorage');
            return;
        }

        const formData = new FormData();
        formData.append('Buyer', buyerId);
        formData.append('product', productData.id);

        axios
            .post(baseUrl + 'Wishlist/', formData)
            .then(function (response) {
                setWishlistMessage(
                    <div style={{ fontFamily: 'Roboto', fontWeight: 'bold', textAlign: 'center', maxWidth: '300px', margin: '0 auto' }}>
                        Product added to wishlist successfully.
                    </div>
                );
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const { discount } = productData;

    const openDemoPage = () => {
        if (productData.demo_url) {
            window.open(productData.demo_url, '_blank');
        } else {
            alert('No demo link available for this product.');
        }
    };

    const [isZoomedProduct, setIsZoomedProduct] = useState(false);
    const [zoomPositionProduct, setZoomPositionProduct] = useState({ x: 50, y: 50 });
    
    // State for the description image zoom
    const [isZoomedDescription, setIsZoomedDescription] = useState(false);
    const [zoomPositionDescription, setZoomPositionDescription] = useState({ x: 50, y: 50 });
    
    // Mouse move handler for product image
    const handleMouseMoveProduct = (e, index) => {
        const rect = e.target.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setZoomPositionProduct({ x, y });
        setIsZoomedProduct(true);
    };
    
    // Mouse leave handler for product image
    const handleMouseLeaveProduct = () => {
        setIsZoomedProduct(false);
    };
    
    // Mouse move handler for description image
    const handleMouseMoveDescription = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setZoomPositionDescription({ x, y });
        setIsZoomedDescription(true);
    };
    
    // Mouse leave handler for description image
    const handleMouseLeaveDescription = () => {
        setIsZoomedDescription(false);
    };



    const getFlavorColor = (flavor) => {
        switch (flavor) {
            case 'chocolat':
                return '#8B4513'; // Brown
            case 'vanille':
                return '#FFFFFF'; // White
            case 'fraise':
                return '#FFC0CB'; // Pink
            case 'cookies':
                return '#F5F5DC'; // Beige
            case 'fraise_banane':
                return '#f7c177'; // Semi pink semi yellow
            case 'caramel':
                return '#D2B48C'; // Wheat
            default:
                return '#000'; // Default color (black)
        }
    };

    const flavors = productData.flavour ? productData.flavour.split(',') : [];
    
    return (
        <section className="container mt-4" style={{ width: '75%', margin: '0 auto' }}>
            <div className="row" style={{ marginTop: '300px', justifyContent: 'center' }}>
            
                {/* Product Thumbnail Carousel */}
                <div className="col-12 col-md-4 d-flex justify-content-center mb-4">
                    <div
                        id="productThumbnailSlider"
                        className="carousel carousel-dark slide carousel-fade"
                        data-bs-ride="true"
                        style={{ maxWidth: '100%', width: '400px' }}
                    >
                        <div className="carousel-inner">
                            {productImgs.map((img, index) => (
                                <div
                                    key={index}
                                    className={index === 0 ? "carousel-item active" : "carousel-item"}
                                    style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <div
                                        className="img-container"
                                        onMouseMove={(e) => handleMouseMoveProduct(e, index)}
                                        onMouseLeave={handleMouseLeaveProduct}
                                        style={{ position: 'relative' }}
                                    >
                                        <img
                                            src={img.image}
                                            className="img-thumbnail mb-5"
                                            alt={`Slide ${index + 1}`}
                                            style={{ maxWidth: "110%", height: "auto", border: '3px solid #009F6B', borderRadius: '15px' }}
                                        />
                                        {isZoomedProduct && (
                                            <div
                                                className="zoom-overlay"
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundImage: `url(${img.image})`,
                                                    backgroundPosition: `${zoomPositionProduct.x}% ${zoomPositionProduct.y}%`,
                                                    backgroundSize: '300% 300%',
                                                    zIndex: 100,
                                                    pointerEvents: 'none',
                                                    borderRadius: '10px',
                                                    border: '1px solid #fff',
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#productThumbnailSlider"
                            data-bs-slide="prev"
                            style={{ width: '260px', height: '430px' }}
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#productThumbnailSlider"
                            data-bs-slide="next"
                            style={{ width: '180px', height: '430px' }}
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
    
                {/* Product Information */}
                <div className="col-12 col-md-6 d-flex justify-content-end flex-column ">
    <div style={{ padding: '0 40px' }}>
        <h3
            style={{
                fontFamily: 'Oswald',
                fontSize: '20px',
                color: 'black',
                letterSpacing: '1.5px',
                fontWeight: 'bolder',
                textTransform: 'uppercase',
                textAlign: 'left', // Align title to the right
            }}
        >
            {productData.title}
        </h3>
        <div style={{ marginBottom: '40px' }}></div>
        <p
          style={{ fontFamily: 'Oswald', fontSize: 'clamp(16px, 2vw, 10px)', fontWeight: 'bold', color: '#000000FF' }}
        >
            {productData.detail}
        </p>
        <div style={{ marginBottom: '30px' }}></div>

        <h3
            style={{
                fontFamily: 'Oswald',
                fontSize: '33px',
                color: '#26619C',
                letterSpacing: '1.5px',
                fontWeight: 'bolder',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                 // Align price to the right
                textAlign: 'right', // Align text to the right
            }}
        >
            Prix:
            {discount > 0 ? (
                <>
                    <span
                        style={{
                            textDecoration: 'line-through',
                            marginRight: '10px',
                            display: 'block'
                        }}
                    >
                        {Number(productData.price).toFixed(3)}DT
                    </span>
                    <span style={{ color: '#cf000f', display: 'block' }}>
                        {(productData.price - productData.price * discount / 100).toFixed(3)}DT
                    </span>
                </>
            ) : (
                <span style={{ display: 'block' }}>{Number(productData.price).toFixed(3)}DT</span>
            )}
        </h3>

        <div className="d-flex flex-column flex-md-row align-items-end gap-3 mt-4 ">
            <button
                className="btn"
                style={{
                    fontFamily: 'Oswald',
                    fontSize: '20px',
                    padding: '15px 35px',
                    fontWeight: '500',
                    borderRadius: '5px',
                    backgroundColor: '#009F6B',
                    textTransform: 'uppercase',
                    textAlign: 'right',
                }}
                onClick={cartAddButtonHandler}
            >
                <i className="fa-solid fa-cart-plus" style={{ color: 'white' }}></i>{' '}
                <span style={{ color: 'white' , textAlign: 'left',}}>Ajouter au panier</span>
            </button>
            {cartButtonClickStatus && (
                <button
                    className="btn"
                    style={{
                        fontFamily: 'Oswald',
                        fontSize: '20px',
                        padding: '15px 35px',
                        borderRadius: '5px',
                        backgroundColor: '#DC343B'

                    }}
                    onClick={cartRemoveButtonHandler}
                >
                    <i className="fa-solid fa-circle-minus" style={{ color: 'white' }}></i>
                    <span style={{ color: 'white' }}> Supprimer du panier </span>
                </button>
            )}
        </div>

        <div className="d-flex flex-column flex-md-row mt-3 gap-3 ">
            <p
                style={{
                    border: '3px solid #009F6B',
                    borderRadius: '15px',
                    padding: '10px 15px',
                    fontSize: '16px',
                    fontFamily: 'Oswald',
                    fontWeight: 'normal',
                    textAlign: 'right', // Align to the right
                }}
            >
                Quantité: {productData.quantity}
            </p>
            <p
                style={{
                    border: '3px solid #009F6B',
                    borderRadius: '15px',
                    padding: '10px 15px',
                    fontSize: '16px',
                    fontFamily: 'Oswald',
                    fontWeight: 'normal',
                    textAlign: 'right', // Align to the right
                }}
            >
                <i className="fa fa-calendar" aria-hidden="true"></i> Date d'expiration: {new Date(productData.expiration_date).toLocaleDateString('en-GB')}
            </p>
        </div>

        {flavors.length > 0 && (
            <p
                style={{
                    border: '3px solid #009F6B',
                    borderRadius: '15px',
                    padding: '10px 15px',
                    fontSize: '16px',
                    fontFamily: 'Oswald',
                    fontWeight: 'normal',
                    textAlign: 'left', // Align to the right
                }}
            >
                Saveurs:
                {flavors.map((flavor, index) => (
                    <span
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '10px',
                            textAlign: 'right', // Align to the right
                        }}
                    >
                        <span
                            style={{
                                width: '15px',
                                height: '15px',
                                borderRadius: '50%',
                                backgroundColor: getFlavorColor(flavor.trim()),
                                marginRight: '5px'
                            }}
                        ></span>
                        {flavor.trim()}
                    </span>
                ))}
            </p>
        )}

        <div
            style={{
                marginTop: '30px',
                border: '3px solid #009F6B',
                borderRadius: '15px',
                padding: '9px',
                fontSize: '25px',
                fontFamily: 'Oswald',
                fontWeight: 'normal',
                textAlign: 'left', // Align to the right
            }}
        >
            {productData.description && (
                <div style={{ textAlign: 'left' }}>
                    <h2>Description:</h2>
                    <p
                       style={{ fontFamily: 'Oswald', fontSize: 'clamp(15px, 4vw, 13px)', fontWeight: 'bold', color: '#000000FF' }}
                    >
                        {productData.description}
                    </p>
                </div>
            )}

            {productData.description_image_1 && (
                <div className="d-flex justify-content-center mt-4">
                    <img
                        src={productData.description_image_1}
                        className="img-thumbnail"
                        alt="Description 1"
                        style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
                        onMouseMove={handleMouseMoveDescription}
                        onMouseLeave={handleMouseLeaveDescription}
                    />
                    {isZoomedDescription && (
                        <div
                            className="zoom-overlay"
                            style={{
                                position: 'absolute',
                                top: '155%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '400px',
                                height: '400px',
                                backgroundImage: `url(${productData.description_image_1})`,
                                backgroundPosition: `${zoomPositionDescription.x}% ${zoomPositionDescription.y}%`,
                                backgroundSize: '300% 300%',
                                zIndex: 100,
                                pointerEvents: 'none',
                                borderRadius:'15px'
                            }}
                        ></div>
                    )}
                </div>
            )}
        </div>
    </div>
</div>

            </div>
            {/* Carousel Inner */}
            <div className="carousel-inner" style={{ marginTop: '110px' }}>
    <h1 className="text-center text-black display-4" style={{
        textTransform: 'uppercase',
        fontFamily: 'Oswald',
        fontSize: '3rem',
        fontWeight: 'bolder',
        marginBottom: '90px'
    }}>
        Produits Similaires
    </h1>
    {relatedProducts.reduce((acc, product, index) => {
        const discount = product.discount || 0;
        const originalPrice = product.price;
        const discountedPrice = discount > 0 ? originalPrice - (originalPrice * (discount / 100)) : originalPrice;

        if (index % 3 === 0) {
            acc.push([]);
        }
        acc[acc.length - 1].push(
            <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-4" key={index}>
                <div style={{ width: '300px', height: '550px' }}>
                    <div className="cards text-center" style={{
                        height: '90%',
                        position: 'relative',
                        border: '3px solid #009F6B',
                        borderRadius: '15px'
                    }}>
                        {discount > 0 && (
                            <div style={{
                                position: 'absolute',
                                top: '10px',
                                left: '10px',
                                backgroundColor: '#cf000f',
                                color: '#fff',
                                padding: '5px 10px',
                                borderRadius: '5px',
                                fontSize: '16px',
                                fontWeight: 'bold'
                            }}>
                                {discount}%
                            </div>
                        )}
                        <Link to={`/product/${product.title}/${product.id}`}>
                            <img src={product.image} alt={product.title} className="card-img-top img-fluid " style={{ width: "250px", height: "250px" }}  />
                        </Link>
                        <div className="card-body"  >
                            <h5 className="cards-title mt-3">
                                <Link className="text-dark text-decoration-none" to={`/product/${product.title}/${product.id}`}  >
                                    {product.title}
                                </Link>
                            </h5>
                            <h5 className="text" style={{ color: '#26619C' }}>
                                Prix:
                                {discount > 0 ? (
                                    <>
                                        <span style={{
                                            textDecoration: 'line-through',
                                            marginRight: '10px',
                                            color: '#26619C'
                                        }}>
                                            {Number(originalPrice).toFixed(3)} DT
                                        </span>
                                        <div style={{ color: '#cf000f', marginTop: '5px' }}>
                                            {discountedPrice.toFixed(3)} DT
                                        </div>
                                    </>
                                ) : (
                                    <span>{Number(originalPrice).toFixed(3)} DT</span>
                                )}
                            </h5>
                        </div>
                        <div className="card-footer d-flex justify-content-center">
                            <Link className='button-31' to={`/product/${product.title}/${product.id}`}>
                                Voir les détails
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
        return acc;
    }, []).map((productGroup, groupIndex) => (
        <div className={groupIndex === 0 ? "carousel-item active" : "carousel-item"} key={groupIndex}>
            <div className="row justify-content-center">
                {productGroup}
            </div>
        </div>
    ))}
</div>


{/* Carousel Controls */}
<button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide="prev"
    style={{ marginTop: '-50px', color: 'black' }}
>
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
</button>
<button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide="next"
    style={{ marginTop: '-50px', color: 'black' }}
>
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
</button>

        </section>
    );
    
}

export default ProductDetail;
